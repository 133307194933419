@import "imports/reset";
@import "imports/extends";
@import "imports/variables";
@import "imports/tools";
@import "imports/mixins";
@import "imports/media-queries";
@import "imports/pagination";
@import "custom.css";

body, html {
    color: $colorText;
    font-family: 'Roboto', sans-serif;
    background: $bgBody;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
    min-width: 320px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
}
body {overflow-x: hidden;}
.popup-active {
    overflow: hidden;
    position: fixed;
    .active-cover {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(000, 000, 000, 0.5);
        width: 100%;
        height: 100%;
        z-index: 998;
    }
}
.city-need {
    overflow: hidden;
    position: fixed;
}
a {
    text-decoration: none;
    color: $colorLink;
    outline: none;
    @include anim;
    @include anim-param(color, background, border-color);
}
input, textarea, select, button {
    font-family: 'Roboto', sans-serif;
    vertical-align: middle;
}
textarea {
    overflow: auto;
    vertical-align: top;
    resize: none;
}
::-moz-placeholder {
    color: $colorPlaceholder;
    opacity: 1;
}
:-ms-input-placeholder {
    color: $colorPlaceholder;
}

::-webkit-input-placeholder {
    color: $colorPlaceholder;
}
.main {
    width: $optimalWidth;
    flex: 1 0 auto;
    margin: 0 auto;
    background: #fff;
    display: flex;
    justify-content: space-between;
    .content {
        width: 73.5%;
        height: 100%;
        background: #fff;
        @media screen and (max-width: 1200px) {
            width: 100%;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 100%;
    }
}
.wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    overflow-x: hidden;
}
header, footer {
    flex: none;
}

// ASIDE #PROMOTION START
// ASIDE #PROMOTION START
.promotion {
    width: 25.5%;
    height: 100%;
    .txt-holder {
        padding: 39px 0 32px 10px;
        span {
            font-size: 12px;
            line-height: 16px;
            font-weight: 300;
            color: #616161;
            display: block;
        }
    }
    .apps {
        margin-bottom: 47px;
        text-align: center;
        .frame {
            margin-bottom: 13px;
            a {
                display: block;
            }
        }
    }
    .for-partners {
        width: 100%;
        span {
            color: #607d8b;
            display: block;
            font-size: 16px;
            line-height: 49px;
            height: 50px;
            padding-left: 62px;
            background: #e6f7ff;
            border-bottom: 1px solid #d6e6ed;
        }
        .service {
            padding: 22px 0 22px 62px;
            a {
                display: block;
                text-align: left;
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 20px;
                font-weight: 300;
                color: #000;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .banner {
        width: 100%;
        height: 850px;
        background: #f0f0f0;
    }
    @media screen and (max-width: 1200px) {
        display: none;
    }
}
// ASIDE #PROMOTION END
// ASIDE #PROMOTION END

// START #HEADER
// START #HEADER
.header {
    background: #212121;
    color: #fff;
    width: 100%;
    .header-holder {
        padding: 10px 10px 0 10px;
        max-width: $optimalWidth;
        margin: 0 auto;
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        .burger {
            display: none;
            width: 30px;
            height: 30px;
            z-index: 20;
            position: relative;
            span {
                display: block;
                width: 100%;
                height: 4px;
                background: #fff;
            }
            &:before, &:after {
                content: '';
                position: absolute;
                bottom: 13px;
                left: 0;
                display: block;
                width: 100%;
                height: 4px;
                background: #fff;
            }
            &:after {
                left: 0;
                bottom: 0;
            }
        }
        .mobile-header {
            transition: 0.4s;
            width: 250px;
            padding: 20px 20px 20px 20px;
            height: 100vh;
            background: #000;
            position: absolute;
            left: -100%;
            top: 0;
            visibility: hidden;
            z-index: -1;
            opacity: 0;
            .close-link {
                position: absolute;
                top: 15px;
                right: 15px;
                width: 20px;
                height: 20px;
                &:before, &:after {
                    content: '';
                    width: 2px;
                    height: 20px;
                    background: #fff;
                    position: absolute;
                    top: 0;
                    left: 9px;
                    transform: rotate(-45deg);
                }
                &:after {
                    transform: rotate(45deg);
                }
            }
            .mobile-logo {
                height: 52px;
                margin-bottom: 50px;
            }
            .mobile-nav {
                @extend %list-reset;
                text-align: left;
                margin-bottom: 30px;
                li {
                    display: block;
                    a {
                        display: block;
                        padding: 20px 20px 20px 10px;
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 18px;
                        border-left: 4px solid transparent;
                        &:hover, &.active {
                            background: #fff;
                            color: #000;
                            border-color: #40c4ff;
                        }
                    }
                }
            }
            .mobile-lang {
                text-align: left;
                padding-left: 14px;
                a {
                    display: inline-block;
                    width: 50px;
                    text-align: center;
                    font-size: 16px;
                    padding: 10px 0;
                    line-height: 18px;
                    border-left: 2px solid transparent;
                    &:hover, &.active {
                        background: #fff;
                        color: #000;
                        border-color: #40c4ff;
                    }
                }
            }
            &.open {
                left: 0;
                opacity: 1;
                visibility: visible;
                z-index: 999;
            }
        }
        .select-cities-holder {
            display: none;
        }
        .title-page {
            display: none;
        }
        .frame {
            padding-right: 20px;
            flex-grow: 1;
            & > a {
                display: block;
                padding-bottom: 10px;
            }
        }
        .nav {
            @extend %list-reset;
            width: 75%;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-grow: 1;
            li {
                display: block;
                flex-grow: 1;
                a {
                    font-size: 16px;
                    line-height: 18px;
                    text-transform: uppercase;
                    height: 100%;
                    padding: 20px 0 30px 0;
                    display: block;
                    font-weight: 400;
                    border-top: 4px solid transparent;
                    &:hover, &.active {
                        background: #fff;
                        border-color: #40c4ff;
                        color: #000;
                    }
                }
            }
        }
        .lang {
            padding-bottom: 10px;
            flex-grow: 10;
            text-align: right;
            position: relative;
            font-size: 16px;
            font-weight: 400;
            display: flex;
            justify-content: flex-end;
            .link-drop {
                display: block;
                width: 60px;
                text-align: center;
                overflow: hidden;
                position: relative;
                &:before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    display: block;
                    border: 5px solid transparent;
                    border-top-color: #fff;
                    position: absolute;
                    right: 0;
                    top: 8px;
                }
            }
            .drop-down {
                position: absolute;
                right: -10px;
                top: -10px;
                height: 98px;
                display: block;
                width: 68px;
                text-align: center;
                background: #fff;
                z-index: -1;
                visibility: hidden;
                opacity: 0;
                padding: 10px 0;
                border-radius: 5px;
                @include anim($dur: .2s, $param: opacity);
                box-shadow: 0 0 8px rgba(0, 0, 0, .5);
                a {
                    display: block;
                    color: #000;
                    padding: 0 10px 0 0;
                    margin-bottom: 17px;
                    position: relative;
                    font-size: 16px;
                    font-weight: 400;
                    &:first-child {
                        &:before {
                            content: '';
                            width: 10px;
                            height: 10px;
                            display: block;
                            border: 5px solid transparent;
                            border-bottom-color: #000;
                            position: absolute;
                            right: 8px;
                            top: 2px;
                        }
                    }
                    &:last-child {
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                    &.active-lang {
                        color: #40c4ff;
                    }
                }
                &.active {
                    z-index: 15;
                    visibility: visible;
                    opacity: 1;
                    height: auto;
                }
            }
        }
        .search-holder {
            display: none;
            width: 6%;
            height: 32px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            text-align: right;
            z-index: 100;
            &.open {
                width: 100%;
            }
            .search-image {
                display: inline-block;
                width: 30px;
                height: 30px;
                &.active {display: none;}
                img {height: 30px;}
            }
            .search-input {
                position: absolute;
                right: -5px;
                top: 0;
                transition: 0.3s;
                height: 100%;
                width: 1%;
                visibility: hidden;
                z-index: -1;
                opacity: 0;
                padding-left: 31px;
                input[type="text"] {
                    height: 100%;
                    width: 100%;
                    display: block;
                    background: #f0f0f0;
                    border: 0;
                    padding: 0 35px 0 10px;
                }
                .close-search {
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    width: 20px;
                    height: 20px;
                    z-index: 9595959;
                    &:before, &:after {
                        content: '';
                        width: 2px;
                        height: 20px;
                        background: #000;
                        position: absolute;
                        top: 0;
                        left: 9px;
                        transform: rotate(-45deg);
                    }
                    &:after {
                        transform: rotate(45deg);
                    }
                }
                &.active {
                    width: 100%;
                    visibility: visible;
                    z-index: 888;
                    opacity: 1;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .header-holder {
            padding: 10px 20px 0 20px;
        }
    }
    @media screen and (max-width: 1023px) {
        .header-holder {
            .nav {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
            .lang {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 980px) {
        .header-holder {
            padding: 20px;
            .burger {
                display: block;
            }
            .select-cities-holder {
                display: block;
                margin: 0 auto;
                width: 246px;
                .list-cities {
                    .active-city {
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 500;
                        vertical-align: top;
                        span {
                            position: relative;
                            display: inline;
                            color: #fff;
                            text-transform: uppercase;
                            &:before {
                                content: 'Такси в';
                                display: block;
                                position: absolute;
                                left: -70px;
                                text-transform: uppercase;
                                top: 0;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                display: block;
                                width: 10px;
                                height: 10px;
                                background: transparent;
                                border: 5px solid transparent;
                                border-top-color: #fff;
                                right: -18px;
                                top: 7px;
                            }
                        }
                        &.active {
                            span {
                                &:after {
                                    border-top-color: transparent;
                                    border-bottom-color: #fff;
                                    top: 1px;
                                }
                            }
                        }
                    }
                    .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools.mCS-minimal-dark {
                        opacity: 1;
                        filter: "alpha(opacity=100)";
                        -ms-filter: "alpha(opacity=100)";
                    }
                    ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        visibility: hidden;
                        z-index: -1;
                        opacity: 0;
                        position: absolute !important;
                        top: 70px;
                        left: 0;
                        width: 100%;
                        background: #fff;
                        height: 350px;
                        box-shadow: 0 3px 5px rgba(000, 000, 000, 0.4);
                        @include anim($dur: .2s);
                        li {
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            a {
                                color: #000;
                                font-size: 18px;
                                position: relative;
                                padding-left: 16px;
                                font-weight: 500;
                                margin-left: -13px;
                                &.active {
                                    color: #40c4ff;
                                    &:before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 1px;
                                        height: 16px;
                                        width: 10px;
                                        background: url(#{$imgurl}/icon41.png) no-repeat;
                                    }
                                }
                            }
                        }
                        &.cities-list-active {
                            opacity: 1;
                            z-index: 100;
                            visibility: visible;
                        }
                    }
                }
            }
            .title-page {
                display: block;
                width: auto;
                margin: 0 auto;
                text-align: center;
                span {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 20px;
                    color: #fff;
                    line-height: 22px;
                    padding-right: 15px;
                }
            }
            .frame {
                display: none;
            }
            .nav {
                display: none;
            }
            .lang {
                display: none;
            }
            .search-holder {
                display: block;
            }
        }
    }
    @media screen and (max-width: 736px) and (min-width: 320px) {
        .header-holder {
            .mobile-header {
                overflow-y: scroll;
                height: 100vh;
                padding: 20px 20px 150px 20px;
                .mobile-nav {
                    li {
                        a {
                            &:hover {
                                background: transparent;
                                color: #fff;
                                border-color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .header-holder {
            .mobile-header {
                .mobile-logo {
                    margin-bottom: 20px;
                }
                .mobile-nav {
                    margin-bottom: 15px;
                    li {
                        a {
                            padding: 15px 15px 15px 10px;
                            font-size: 14px;
                        }
                    }
                }
            }
            .select-cities-holder {
                padding-left: 20px;
                .list-cities {
                    .active-city {
                        font-size: 12px;
                        span {
                            &:before {
                                left: -54px;
                                text-transform: uppercase;
                                top: 0;
                                font-size: 12px;
                            }
                            &:after {
                                width: 8px;
                                height: 8px;
                                border: 4px solid transparent;
                                border-top-color: #fff;
                                right: -15px;
                                top: 5px;
                            }
                            &.active {
                                &:before {
                                    top: 0;
                                }
                            }
                        }
                    }
                    ul {
                        li {
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .title-page {
                span {
                    font-size: 14px;
                }
            }
        }
    }
}
// END HEADER
// END HEADER

// START #FOOTER
// START #FOOTER
.footer {
    width: 100%;
    background: #616161;
    overflow: hidden;
    .footer-holder {
        max-width: $optimalWidth;
        margin: 0 auto;
        padding: 33px 0 24px 0;
        display: flex;
        justify-content: space-between;
        .column {
            width: 29%;
            height: 100%;
            h2 {
                font-size: 16px;
                font-weight: 500;
                padding: 0 30px 5px 10px;
                width: 130px;
                color: #fff;
                text-transform: uppercase;
                margin: 0 0 20px;
            }
            .row {
                border-bottom: 1px dashed #717171;
                margin-bottom: 4px;
                padding-left: 10px;
                height: 53px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &:last-child {
                    border-bottom: none;
                }
                .newsone {
                    display: block;
                    font-size: 12px;
                    font-weight: 300;
                    margin-bottom: 4px;
                    line-height: 16px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .data {
                    display: block;
                    font-size: 9px;
                    font-weight: 400;
                    line-height: 12px;
                    padding-bottom: 5px;
                    color: #9c9c9c;
                    .time {
                        padding-left: 10px;
                    }
                }
            }
        }
        .reviews .row .newsone {
            color: #fff;
            &:hover {
                text-decoration: none;
            }
        }
        .news h2 {
            border-bottom: 3px solid #80d8ff
        }
        .reviews h2 {
            border-bottom: 3px solid #ff8a80
        }
        .contacts h2 {
            border-bottom: 3px solid #ffd180
        }
        .contacts {
            .title {
                font-size: 14px;
                line-height: 16px;
                color: #ffd180;
                font-weight: 400;
                display: inline-block;
                vertical-align: middle;
            }
            .phones, .email, .soc-net {
                padding-left: 15px;
            }
            .phones {
                margin-bottom: 18px;
                .title {
                    margin-bottom: 10px;
                }
                a {
                    display: block;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 16px;
                    margin-bottom: 1px;
                    color: #fff;
                    span {
                        padding-left: 20px;
                        font-size: 14px;
                        line-height: 16px;
                        color: #9c9c9c;
                    }
                }
            }
            .email {
                margin-bottom: 18px;
                a {
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 16px;
                }
            }
            .soc-net {
                .title {
                    margin-bottom: 10px;
                    display: block;
                }
                .fb, .gp, .vk, .tw {
                    display: inline-block;
                    vertical-align: middle;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    text-align: center;
                    &:hover {
                        transform: scale(1.1)
                    }
                }
                .fb {
                    background: #4e4e4e url(#{$imgurl}/icon02.svg) no-repeat 50% 50%;
                }
                .gp {
                    background: #4e4e4e url(#{$imgurl}/icon03.svg) no-repeat 50% 50%;
                }
                .vk {
                    background: #4e4e4e url(#{$imgurl}/icon04.svg) no-repeat 50% 50%;
                }
                .tw {
                    background: #4e4e4e url(#{$imgurl}/icon044.svg) no-repeat 50% 50%;
                }
            }
        }
    }
    .add-nav {
        width: 100%;
        background: #212121;
        .add-nav-holder {
            max-width: $optimalWidth;
            margin: 0 auto;
            height: 100%;
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .copyright {
                flex-grow: 1;
                color: #fff;
                font-size: 14px;
                font-weight: 300;
                line-height: 16px;
            }
            .apps {
                flex-grow: 1;
                &:before {
                    content: '';
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }
                .title {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .ios, .android {
                    display: inline-block;
                    vertical-align: middle;
                    width: 47px;
                    height: 47px;
                    border-radius: 8px;
                    margin-left: 10px;
                    &.ios {
                        background: url(#{$imgurl}/appstore.svg) no-repeat 50% 50%;
                    }
                    &.android {
                        background: url(#{$imgurl}/gp.svg) no-repeat 50% 50%;
                    }
                }
                .ios-mobile, .android-mobile {
                    display: none;
                    width: 300px;
                    height: 60px;
                    margin: 0 auto 15px auto;
                    text-align: center;
                    background: rgba(000, 000, 000, 0.9);
                    border-radius: 13px;
                    vertical-align: middle;
                    &:hover {
                        background: rgba(000, 000, 000, 0.5);
                    }
                    &:before {
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        height: 100%;
                    }
                    .frame {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .txt {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 16px;
                        vertical-align: middle;
                        .taxi {
                            color: #80d8ff;
                        }
                    }
                }
            }
            .nav {
                flex-grow: 2;
                ul {
                    @extend %list-reset;
                    li {
                        display: inline-block;
                        a {
                            font-size: 14px;
                            line-height: 16px;
                            font-weight: 300;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    li + li {
                        margin-left: 4%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .footer-holder {
            padding: 30px 20px;
        }
    }
    @media screen and (max-width: 1140px) {
        .add-nav {
            .add-nav-holder {
                .copyright {
                    font-size: 13px;
                }
                .apps {
                    .title {
                        font-size: 13px;
                    }
                    a {
                        width: 44px;
                        height: 44px;
                        margin-left: 6px;
                    }
                }
                .nav {
                    ul {
                        li {
                            a {
                                font-size: 13px;
                            }
                        }
                        li + li {
                            margin-left: 1%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 980px) {
        .footer-holder {
            display: none;
        }
        .add-nav {
            background: rgba(000, 000, 000, 0.3);
            .add-nav-holder {
                padding: 0;
                .copyright {
                    display: none;
                }
                .apps {
                    .title, .ios, .android {
                        display: none;
                    }
                    .ios-mobile, .android-mobile {
                        display: block;
                    }
                }
                .nav {
                    display: none;
                }
            }
        }
    }
}

// END FOOTER
// END FOOTER

// START #MAIN PAGE
// START #MAIN PAGE
.taxi-city {
    .search-holder {
        padding: 39px 0 40px 10px;
        display: flex;
        justify-content: space-between;
        .title-box {
            position: relative;
            z-index: 100;
            h1 {
                font-size: 28px;
                font-weight: 300;
                margin: 0;
                color: #000;
                display: inline-block;
                position: relative;
                z-index: 101;
                a {
                    display: inline-block;
                    color: #40c4ff;
                    font-weight: 400;
                    border-bottom: 1px dashed #40c4ff;
                    position: relative;
                    &:before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        display: block;
                        border: 5px solid transparent;
                        border-top-color: #616161;
                        position: absolute;
                        right: -20px;
                        top: 16px;
                    }
                }
            }
            &.open-cities {
                h1 {
                    a {
                        &:before {
                            top: 11px;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid #616161;
                        }
                    }
                }
            }
            .dropdown-cities {
                position: absolute;
                left: -23px;
                top: -16px;
                width: 700px;
                height: auto;
                visibility: hidden;
                opacity: 0;
                z-index: -1;
                background: #fff;
                transition: 0.3s;
                padding: 100px 0 40px 42px;
                box-shadow: 0 0 8px rgba(0, 0, 0, .5);
                border-radius: 5px;
                &:before {
                    content: '';
                    position: absolute;
                    top: 65px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 93%;
                    height: 1px;
                    border-bottom: 1px dashed #e0e0e0;
                }
                ul {
                    @extend %list-reset;
                    columns: 3;
                    li {
                        padding: 8px 0;
                        vertical-align: top;
                        display: inline-block;
                        width: 100%;
                        a {
                            color: #000;
                            font-size: 16px;
                            line-height: 18px;
                            font-weight: 400;
                            padding-left: 25px;
                            position: relative;
                            &:before {
                                content: '';
                                position: absolute;
                                display: block;
                                width: 10px;
                                height: 16px;
                                left: 0;
                                top: 1px;
                            }
                            &:hover, &.active {
                                color: #40c4ff;
                                &:before {
                                    background: url(#{$imgurl}/icon41.png) no-repeat;
                                }
                            }
                        }
                    }
                }
                &.open {
                    visibility: visible;
                    opacity: 1;
                    z-index: 100;
                }
            }
        }
        .search-input {
            width: 35%;
            height: 40px;
            position: relative;
            .close-search {
                display: none;
                width: 25px;
                height: 25px;
                background: transparent;
                position: absolute;
                top: 7px;
                right: 15px;
                z-index: 150;
                &:before, &:after {
                    content: '';
                    display: block;
                    left: 12px;
                    top: 8px;
                    position: absolute;
                    height: 10px;
                    width: 2px;
                    transform: rotate(-45deg);
                    background: #000;
                    z-index: 15
                }
                &:after {
                    transform: rotate(45deg)
                }
            }
            input[type="text"] {
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 30px;
                padding: 0 35px 0 50px;
                color: #616161;
                font-size: 14px;
                background: #f0f0f0 url(#{$imgurl}/icon07.svg) no-repeat 5% 50%;
                z-index: 10;
                position: relative;
            }
        }
    }
    .choose-taxi {
        display: flex;
        margin-bottom: 35px;
        width: 100%;
        .column {
            width: 25%;
            padding-top: 19px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
                display: block;
                margin-bottom: 20px;
                font-size: 20px;
                line-height: 22px;
                font-weight: 400;
                color: #fff;
                padding: 0 2vh;
            }
            .contacts {
                .name {
                    display: block;
                    font-size: 14px;
                    line-height: 16px;
                    color: #fff;
                    font-weight: 400;
                    margin-bottom: 5px;
                    padding: 0 2vh;
                }
                .phone, .callback {
                    font-size: 14px;
                    line-height: 20px;
                    color: #fff;
                    font-weight: 300;
                    display: block;
                    padding: 0 2vh;
                }
                .callback {
                    margin-bottom: 15px;
                }
            }
            .more-info {
                display: block;
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 500;
                color: #000;
                padding-left: 2vh;
                background: rgba(255, 255, 255, 0.5);
                position: relative;
                bottom: 0;
                &:before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 10px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    background: url(#{$imgurl}/icon08.png) no-repeat 5% 50%;
                }
                &:hover {
                    background: rgba(255, 255, 255, 0.8);
                }
            }
            &:first-child {
                background: #ffab40;
            }
            &:nth-child(2) {
                background: #ff5252;
            }
            &:nth-child(3) {
                background: #40c4ff;
            }
            &:last-child {
                background: #889da8;
            }
        }
    }
    .filter {
        margin-bottom: 24px;
        vertical-align: top;
        display: flex;
        padding-left: 10px;
        justify-content: space-between;
        .filter-icons {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            .title {
                display: inline-block;
                font-size: 16px;
                color: #000;
                line-height: 18px;
                margin: 0 35px 13px 0;
                font-weight: 300;
                flex-grow: 1;
            }
            .clear {
                display: inline-block;
                flex-grow: 1;
                font-size: 12px;
                line-height: 14px;
                color: #40c4ff;
                font-weight: 400;
                padding-left: 10px;
                position: relative;
                &:before, &:after {
                    display: block;
                    content: '';
                    height: 10px;
                    width: 2px;
                    background: #40c4ff;
                    position: absolute;
                    left: 0;
                    top: 2px;
                    transform: rotate(-45deg);
                }
                &:after {
                    transform: rotate(45deg);
                }
            }
            ul {
                @extend %list-reset;
                li {
                    display: inline-block;
                    a {
                        display: block;
                        @include anim($dur: 0s);
                        width: 38px;
                        height: 38px;
                        position: relative;
                        border-radius: 50%;
                        background: #f0f0f0 url(#{$imgurl}/sprite.png) 50% 50%;
                        &:hover, &.active {
                            background: #80d8ff url(#{$imgurl}/sprite.png);
                        }
                        &.evacuator {
                            background-position: -2px -5px;
                            &:hover, &.active {
                                background-position: -2px 42px;
                            }
                        }
                        &.truck {
                            background-position: -40px -5px;
                            &:hover, &.active {
                                background-position: -40px 43px;
                            }
                        }
                        &.car {
                            background-position: -82px -5px;
                            &:hover, &.active {
                                background-position: -83px 42px;
                            }
                        }
                        &.forhour {
                            background-position: -127px -5px;
                            &:hover, &.active {
                                background-position: -127px 43px;
                            }
                        }
                        &.transfer {
                            background-position: -171px -6px;
                            &:hover, &.active {
                                background-position: -171px 42px;
                            }
                        }
                        &.nanny {
                            background-position: -215px -6px;
                            &:hover, &.active {
                                background-position: -215px 41px;
                            }
                        }
                        &.no-smoke-car {
                            background-position: -261px -4px;
                            &:hover, &.active {
                                background-position: -261px 42px;
                            }
                        }
                        &.smoke-car {
                            background-position: -308px -4px;
                            &:hover, &.active {
                                background-position: -308px 45px;
                            }
                        }
                        &.cond {
                            background-position: -356px -4px;
                            &:hover, &.active {
                                background-position: -356px 43px;
                            }
                        }
                        &.zoo {
                            background-position: -400px -4px;
                            &:hover, &.active {
                                background-position: -401px -37px;
                            }
                        }
                        &.curier {
                            background-position: -446px -4px;
                            &:hover, &.active {
                                background-position: -446px 42px;
                            }
                        }
                        &.no-alco {
                            background-position: -490px -4px;
                            &:hover, &.active {
                                background-position: -490px 42px;
                            }
                        }
                        &.celebrate {
                            background: #f0f0f0 url(#{$imgurl}/icon09.png) no-repeat 50% 50%;
                            &:hover, &.active {
                                background: #80d8ff url(#{$imgurl}/icon10.png) no-repeat 50% 50%;
                            }
                        }
                        &.woman {
                            background-position: -536px -2px;
                            &:hover, &.active {
                                background-position: -536px -35px;
                            }
                        }
                        &.english-man {
                            background-position: 40px -4px;
                            &:hover, &.active {
                                background-position: 40px -38px;
                            }
                        }
                        .service-title {
                            opacity: 0;
                            z-index: -1;
                            visibility: hidden;
                            position: absolute;
                            top: 55px;
                            left: 50%;
                            transform: translateX(-50%);
                            padding: 10px 20px;
                            height: 40px;
                            background: #212121;
                            display: flex;
                            color: #fff;
                            font-size: 14px;
                            line-height: 16px;
                            justify-content: center;
                            align-items: center;
                            font-weight: 300;
                            border-radius: 5px;
                            box-shadow: 0 0 8px rgba(0, 0, 0, .5);
                            @include anim($param: opacity, $dur: .3s);
                            &:before {
                                content: '';
                                position: absolute;
                                top: -20px;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 20px;
                                height: 20px;
                                border: 10px solid transparent;
                                border-bottom-color: #212121;
                            }
                            & > span {
                                font-size: 14px;
                                color: #80d8ff;
                                padding-left: 5px;
                                font-weight: 300;
                            }
                            &.ie-service-title {min-width: 260px;}
                        }
                    }
                    a:hover {
                        white-space: nowrap;
                        .service-title {
                            opacity: 1;
                            z-index: 150;
                            visibility: visible;
                        }
                    }
                }
                // li + li {margin-left: 4px;}
            }
        }
        .service-holder {
            display: none;
        }
        .sort {
            vertical-align: middle;
            display: inline-block;
            width: 25%;
            .sort-title {
                display: block;
                margin-bottom: 13px;
                padding-left: 25px;
                font-size: 16px;
                color: #000;
                line-height: 18px;
                font-weight: 300;
                text-align: left;
            }
            .sort-list {
                position: relative;
                .active-list {
                    display: block;
                    height: 40px;
                    padding-left: 20px;
                    width: 100%;
                    background: #f0f0f0;
                    border-radius: 50px;
                    &:before {
                        content: '';
                        position: absolute;
                        right: 24px;
                        top: 17px;
                        height: 10px;
                        width: 10px;
                        background: transparent;
                        border: 5px solid transparent;
                        border-top-color: #444;
                    }
                    span {
                        line-height: 40px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #444;
                    }
                }
                ul {
                    @extend %list-reset;
                    position: absolute;
                    top: -5px;
                    left: 0;
                    width: 100%;
                    background: #fff;
                    height: auto;
                    border-radius: 20px;
                    padding: 5px 0;
                    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
                    z-index: -1;
                    visibility: hidden;
                    opacity: 0;
                    @include anim($dur: .1s);
                    &:before {
                        content: '';
                        position: absolute;
                        right: 24px;
                        top: 16px;
                        height: 10px;
                        width: 10px;
                        background: transparent;
                        border: 5px solid transparent;
                        border-bottom-color: #444;
                    }
                    li {
                        padding-left: 20px;
                        a {
                            color: #444;
                            font-size: 14px;
                            line-height: 40px;
                            font-weight: 500;
                            &:hover, &.active {
                                color: #40c4ff;
                            }
                        }
                    }
                    &.sort-list-active {
                        visibility: visible;
                        z-index: 50;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .list-taxi {
        width: 100%;
        .titles-box {
            width: 100%;
            height: 49px;
            border-bottom: 1px solid #d6e6ed;
            background: #e6f7ff;
            padding-left: 10px;
            vertical-align: middle;
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }
            .name, .rate, .services, .rating {
                display: inline-block;
                vertical-align: middle;
                font-size: 13px;
                font-weight: 300;
                line-height: 16px;
                color: #607d8b;
                &.name {width: 36%;}
                &.rate {width: 20%;}
                &.services {width: 29%;}
                &.rating {width: 8%;}
            }
        }
        .not-found {
            width: 100%;
            text-align: center;
            padding: 30px 0;
            span {
                display: block;
                font-size: 16px;
                line-height: 18px;
                color: #000;
                margin-bottom: 10px;
            }
            span:nth-child(2) {margin-bottom: 30px;}
            .btn {
                display: inline-block;
                padding: 0 20px;
                height: 40px;
                line-height: 40px;
                color: #fff;
                font-size: 16px;
                background: #40c4ff;
                text-transform: uppercase;
                border-radius: 10px;
                margin: 0 auto;
                @include anim($dur: .3s);
                &:hover {opacity: 0.8;}
            }
        }
        .list {
            margin-bottom: 25px;
            .row {
                padding: 16px 0 16px 0;
                border-bottom: 1px dashed #e0e0e0;
                vertical-align: middle;
                &:before {
                    content: '';
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }
                .name {
                    display: inline-block;
                    vertical-align: middle;
                    width: 36%;
                    padding-left: 8px;
                    position: relative;
                    .title {
                        display: block;
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 500;
                        color: #000;
                    }
                    .single-phone {
                        display: inline-block;
                        margin-right: 3px;
                        color: #616161;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 16px;
                    }
                    @media screen and (min-width: 980px) {
                        .additional-mobile-phones {display: none;}
                    }

                    .more-phone {
                        display: inline-block;
                        font-weight: 700;
                        color: #9c9c9c;
                        vertical-align: middle;
                        font-size: 24px;
                        letter-spacing: -1.5px;
                        line-height: 20px;
                        position: relative;
                        bottom: 2px;
                        margin-right: 50%;
                        cursor: pointer;
                    }
                    .dropdown-phones {
                        background: #fff;
                        transition: 0.3s;
                        position: absolute;
                        left: -50px;
                        top: 35px;
                        padding: 20px;
                        width: 300px;
                        height: auto;
                        visibility: hidden;
                        z-index: -1;
                        opacity: 0;
                        border-radius: 8px;
                        box-shadow: 0 0 8px rgba(000, 000, 000, 0.5);
                        a {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            font-size: 14px;
                            line-height: 33px;
                            color: #000;
                            font-weight: 300;
                            .usually {
                                font-weight: 400;
                                color: #616161;
                                margin-right: 25px;
                            }
                            .operator {
                                width: 40%;
                                font-weight: 400;
                                color: #616161;
                                font-size: 14px;
                                margin-left: 25px;
                                padding-left: 30px;
                                &.kievstar {background: url(#{$imgurl}/icon42.svg) no-repeat 0 50%;}
                                &.mts {background: url(#{$imgurl}/icon43.svg) no-repeat 0 50%;}
                                &.life {background: url(#{$imgurl}/icon44.svg) no-repeat 0 50%;}
                                &.city {background: url(#{$imgurl}/icon45.svg) no-repeat 0 26%;}
                            }
                        }
                        &.open {
                            visibility: visible;
                            z-index: 100;
                            opacity: 1;
                        }
                    }
                }
                .rate {
                    display: inline-block;
                    vertical-align: middle;
                    width: 19.8%;
                    .string {
                        font-size: 14px;
                        line-height: 24px;
                        color: #616161;
                        font-weight: 400;
                        .value {
                            font-size: 16px;
                            font-weight: 500;
                            color: #000;
                        }
                        .need-mg {
                            display: block;
                        }
                    }
                }
                .services {
                    display: inline-block;
                    vertical-align: middle;
                    width: 26%;
                    ul {
                        display: inline-block;
                        margin-right: 3px;
                        @extend %list-reset;
                        vertical-align: middle;
                        li {
                            display: inline-block;
                            span {
                                display: block;
                                @include anim($dur: 0s);
                                width: 28px;
                                height: 28px;
                                vertical-align: middle;
                                border-radius: 50%;
                                background: #f0f0f0 url(#{$imgurl}/sprite-small.png) repeat-x 50% 50%;
                                &.evacuator {background-position: -8px 4px;}
                                &.truck {background-position: -37px 4px;}
                                &.car {background-position: -70px 3px;}
                                &.forhour {background-position: -104px 3px;}
                                &.transfer {background-position: -138px 3px;}
                                &.nanny {background-position: -171px 3px;}
                                &.no-smoke-car {background-position: -205px 5px;}
                                &.smoke-car {background-position: -240px 4px;}
                                &.cond {background-position: -278px 4px;}
                                &.zoo {background-position: -312px 4px;}
                                &.curier {background-position: -347px 4px;}
                                &.no-alco {background-position: -871px 4px;}
                                &.celebrate {
                                    background: #f0f0f0 url(#{$imgurl}/icon09.png) no-repeat 56% 45%;
                                    background-size: 60%;
                                }
                                &.woman {background-position: 76px 5px;}
                                &.english-man {background-position: 37px 3px;}
                            }
                        }
                    }
                    .more-icons {
                        display: inline-block;
                        font-weight: 700;
                        color: #9c9c9c;
                        vertical-align: middle;
                        font-size: 24px;
                        letter-spacing: -1.5px;
                        cursor: pointer;
                        position: relative;
                        .dropdown-icons {
                            position: absolute;
                            transition: 0.3s;
                            content: '';
                            display: block;
                            width: 186px;
                            padding: 16px 8px 12px 26px;
                            height: auto;
                            top: 40px;
                            left: -82px;
                            background: #fff;
                            visibility: hidden;
                            opacity: 0;
                            z-index: -1;
                            border-radius: 8px;
                            box-shadow: 0 0 8px rgba(000, 000, 000, 0.5);
                            &:before {
                                content: '';
                                position: absolute;
                                top: -19px;
                                left: 52%;
                                transform: translate(-50%);
                                background: transparent;
                                width: 50px;
                                height: 20px;
                                background: url(/dependencies/images/icon46.png) no-repeat 10% 50%;
                            }
                            &.open {
                                visibility: visible;
                                opacity: 1;
                                z-index: 100;
                            }
                        }
                    }

                }
                .rating {
                    display: inline-block;
                    width: 16%;
                    padding-left: 22px;
                    direction: ltr;
                    text-align: left;
                    vertical-align: middle;
                    .star-input {display: none;}
                    .star-rating-wrapper {
                        display: inline-block;
                        direction: ltr;
                        span {
                            width: 12px !important;
                            height: 12px !important;
                            margin-right: -2px;
                        }
                    }
                    .starlabel:before {
                        display: inline-block;
                        vertical-align: middle;
                        content: '';
                        width: 10px;
                        height: 10px;
                        background: url(#{$imgurl}/star.svg) no-repeat;
                    }
                    .starlabel:hover:before,
                    .starlabel:hover ~ .starlabel:before {
                        background: url(#{$imgurl}/star-fill.svg) no-repeat;
                    }
                    .starlabel:hover:before,
                    .starlabel:hover ~ .starlabel:before,
                    .star-input:checked ~ .starlabel:before {
                        background: url(#{$imgurl}/star-fill.svg) no-repeat;
                    }
                    .frame {
                        display: inline-block;
                        padding-left: 8px;
                        direction: ltr;
                        vertical-align: middle;
                        img {
                            vertical-align: middle;
                            width: 10px;
                            height: 10px;
                        }
                        span {
                            font-size: 10px;
                            color: #9c9c9c;
                        }
                    }
                }
                .more-information {display: none;}
            }
        }
    }
    .txt-holder {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
        font-weight: 300;
        color: #000;
        p {
            margin: 0;
            a {color: #40c4ff;}
        }
    }
    @media screen and (max-width: 1200px) {
        .search-holder {padding: 4vh 10px 4vh 10px;}
        .list-taxi, .txt-holder {padding: 0 10px;}
        .filter {padding: 0 10px;}
    }
    @media screen and (max-width: 980px) {
        padding: 0;
        .search-holder {display: none;}
        .choose-taxi {
            margin: 0;
            align-content: stretch;
            flex-wrap: wrap;
            .column {width: 50%;}
        }
        .filter {
            padding: 0;
            margin: 0;
            flex-direction: column;
            .sort-title {display: none;}
            .filter-icons {display: none;}
            .service-holder {
                display: block;
                background: #f0f0f0;
                height: 50px;
                position: relative;
                border-bottom: 1px solid #9c9c9c;
                z-index: 10;
                .filter-active {
                    display: block;
                    line-height: 50px;
                    color: #444;
                    font-size: 14px;
                    padding-left: 18px;
                    font-weight: 500;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 20px;
                        right: 24px;
                        height: 10px;
                        width: 10px;
                        background: transparent;
                        border: 5px solid transparent;
                        border-top-color: #000;
                    }
                }
                .change-list {
                    background: #fff;
                    &:before {
                        top: 17px;
                        border-top-color: transparent;
                        border-bottom-color: #000;
                    }
                }
                .clear-filter {
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: transparent;
                    position: absolute;
                    right: 50px;
                    top: 14px;
                    opacity: 0;
                    z-index: -1;
                    &:before, &:after {
                        content: '';
                        display: block;
                        height: 20px;
                        width: 2px;
                        top: 0;
                        left: 0;
                        background: #40c4ff;
                        transform: translateX(9px) rotate(-45deg);
                    }
                    &:after {transform: translate(9px, -20px) rotate(45deg);}
                    &.active {
                        z-index: 20;
                        opacity: 1;
                    }
                }
                .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools.mCS-minimal-dark {
                    opacity: 1;
                    filter: "alpha(opacity=100)";
                    -ms-filter: "alpha(opacity=100)";
                }
                ul {
                    margin: 0;
                    list-style: none;
                    padding: 0 20px 0 5px;
                    position: absolute !important;
                    display: block;
                    width: 100%;
                    background: #fff;
                    top: 49px;
                    left: 0;
                    box-shadow: 0 3px 5px rgba(000, 000, 000, 0.5);
                    z-index: -1;
                    opacity: 0;
                    visibility: hidden;
                    height: 260px;
                    @include anim($dur: .1s);
                    li {
                        &:first-child {
                            margin-top: 15px;
                        }
                        &:last-child {
                            margin-bottom: 15px;
                        }
                        a {
                            padding-left: 10px;
                            line-height: 30px;
                            color: #000;
                            font-size: 14px;
                            font-weight: 500;
                            vertical-align: middle;
                            &.active {
                                color: #40c4ff;
                            }
                            &:before {
                                content: '';
                                display: inline-block;
                                width: 38px;
                                height: 38px;
                                border-radius: 50%;
                                background: #f0f0f0 url(#{$imgurl}/sprite.png) 50% 50%;
                                vertical-align: middle;
                                margin-right: 10px;
                            }
                            &.active {
                                &:before {
                                    background: #80d8ff url(#{$imgurl}/sprite.png) 50% 50%;
                                }
                            }
                            &.evacuator {
                                &:before {background-position: -2px -5px;}
                                &.active:before {background-position: -2px 42px;}
                            }
                            &.truck {
                                &:before {background-position: -39px -5px;}
                                &.active:before {background-position: -39px 43px;}
                            }
                            &.car {
                                &:before {background-position: -82px -5px;}
                                &.active:before {background-position: -83px 42px;}
                            }
                            &.forhour {
                                &:before {background-position: -127px -5px;}
                                &.active:before {background-position: -127px 43px;}
                            }
                            &.transfer {
                                &:before {background-position: -171px -6px;}
                                &.active:before {background-position: -171px 42px;}
                            }
                            &.nanny {
                                &:before {background-position: -215px -6px;}
                                &.active:before {background-position: -215px 41px;}
                            }
                            &.no-smoke-car {
                                &:before {background-position: -261px -4px;}
                                &.active:before {background-position: -261px 42px;}
                            }
                            &.smoke-car {
                                &:before {background-position: -308px -4px;}
                                &.active:before {background-position: -308px 45px;}
                            }
                            &.cond {
                                &:before {background-position: -356px -4px;}
                                &.active:before {background-position: -356px 43px;}
                            }
                            &.zoo {
                                &:before {background-position: -400px -4px;}
                                &.active:before {background-position: -401px -37px;}
                            }
                            &.curier {
                                &:before {background-position: -446px -4px;}
                                &.active:before {background-position: -446px 42px;}
                            }
                            &.no-alco {
                                &:before {background-position: -490px -4px;}
                                &.active:before {background-position: -490px 42px;}
                            }
                            &.celebrate {
                                &:before {background: #f0f0f0 url(#{$imgurl}/icon09.png) no-repeat 9px 6px;}
                                &.active:before {background: #80d8ff url(#{$imgurl}/icon10.png) no-repeat 9px 7px;}
                            }
                            &.woman {
                                &:before {background-position: -536px -2px;}
                                &.active:before {background-position: -536px -36px;}
                            }
                            &.english-man {
                                &:before {background-position: 40px -5px;}
                                &.active:before {background-position: 40px -38px;}
                            }
                        }
                    }
                    li + li {margin-top: 10px;}
                    &.filter-list-active {
                        z-index: 60;
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            .sort {
                z-index: 5;
                .sort-title {display: none;}
                display: block;
                width: 100%;
                height: 50px;
                background: #f0f0f0;
                border-bottom: 1px solid #9c9c9c;
                position: relative;
                .sort-list {
                    .active-list {
                        line-height: 51px;
                        height: 49px;
                        padding-left: 103px;
                        background: transparent;
                        border-radius: 0;
                        span {
                            color: #000;
                            line-height: 14px;
                            font-size: 14px;
                        }
                        &:before {
                            top: 22px;
                            border-top-color: #000;
                        }
                        &:after {
                            content: "Сортировка:";
                            display: block;
                            font-size: 14px;
                            font-weight: 500;
                            position: absolute;
                            left: 19px;
                            top: 1px;
                            color: #444;
                        }
                    }
                    ul {
                        border-radius: 0 0 0 0;
                        box-shadow: 0 3px 5px rgba(000, 000, 000, 0.5);
                        top: 49px;
                        &:before {
                            top: 17px;
                            display: none;
                        }
                        li {
                            a {
                                display: block;
                                line-height: 43px;
                            }
                        }
                    }
                    &.open-sort {
                        background: #fff;
                        .active-list {
                            background: #fff;
                            border-radius: 0;
                            &:before {
                                top: 17px;
                                border-top-color: #fff;
                                border-bottom-color: #000;
                            }
                        }
                    }
                }
            }
        }
        .list-taxi {
            padding: 0;
            .titles-box {display: none;}
            .list {
                .row {
                    padding: 8px 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: wrap;
                    position: relative;
                    .name {
                        width: 50%;
                        margin-bottom: 20px;
                        padding-left: 0;
                        .single-phone {
                            display: block;
                            line-height: 22px;
                        }
                        .more-phone {display: none;}
                        .title {
                            line-height: 20px;
                            margin-bottom: 3px;
                        }
                    }
                    .rate {
                        width: 49%;
                        text-align: right;
                        padding-right: 0;
                        .string {
                            padding-top: 15px;
                            display: block;
                        }
                    }
                    .services {
                        width: 100%;
                        height: 31px;
                        .more-icons {
                            display: none;
                        }
                    }
                    .rating {
                        width: 96px;
                        padding: 0;
                        position: absolute;
                        top: 12px;
                        right: 11px;
                        .frame {display: none;}
                        .star-rating-wrapper {
                            span {
                                height: 15px !important;
                                width: 15px !important;
                                margin: 0 !important;
                            }
                        }
                    }
                    .more-information {
                        position: absolute;
                        display: block;
                        right: 17px;
                        bottom: 16px;
                        width: 20px;
                        height: 20px;
                        &:after, &:before {
                            content: '';
                            position: absolute;
                            height: 15px;
                            width: 2px;
                            background: #616161;
                            right: 5px;
                            bottom: -2px;
                            transform: rotate(50deg);
                        }
                        &:before {
                            content: '';
                            right: 5px;
                            bottom: 7px;
                            transform: rotate(-50deg);
                        }
                    }
                }
            }
        }
        .txt-holder {display: none;}
    }
    @media screen and (max-width: 768px) {
        .choose-taxi {
            .column {
                .title {
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                .contacts {
                    .name {
                        font-size: 11px;
                        margin: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .filter {
            .sort {
                .sort-title-mobile {display: none;}
            }
        }
    }
}
// END MAIN PAGE
// END MAIN PAGE

// START #TAXI PAGE
// START #TAXI PAGE
.taxi-page {
    .breadcrumbs {
        padding: 39px 0 40px 0;
        a {
            font-size: 14px;
            line-height: 16px;
            display: inline-block;
            font-weight: 400;
            margin: 0 10px 0 10px;
            color: #616161;
        }
        .frame {
            display: inline-block;
            vertical-align: bottom;
            img {vertical-align: middle;}
        }
    }
    .title-box {
        vertical-align: top;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .back-link {display: none;}
        .frame-taxi {
            display: inline-block;
            width: 21.3%
        }
        .rating {
            display: inline-block;
            width: 65%;
            direction: ltr;
            text-align: left;
            vertical-align: middle;
            .name {
                display: block;
                font-size: 28px;
                font-weight: 400;
                margin-bottom: 10px;
                line-height: 30px;
            }
            .star-input {display: none;}
            .star-rating-wrapper {
                display: inline-block;
                direction: ltr;
                margin-right: 15px;
                & > span {
                    width: 20px !important;
                    height: 20px !important;
                    margin-right: 7px;
                }
            }
            .starlabel:before {
                display: inline-block;
                vertical-align: middle;
                content: '';
                width: 20px;
                height: 20px;
                background: url(#{$imgurl}/star.svg) no-repeat;
            }
            .starlabel:hover:before,
            .starlabel:hover ~ .starlabel:before {
                background: url(#{$imgurl}/star-fill.svg) no-repeat;
            }
            .starlabel:hover:before,
            .starlabel:hover ~ .starlabel:before,
            .star-input:checked ~ .starlabel:before {
                background: url(#{$imgurl}/star-fill.svg) no-repeat;
            }
            .frame {
                display: inline-block;
                span {
                    font-size: 14px;
                    color: #9c9c9c;
                    line-height: 14px;
                    vertical-align: middle;
                }
                img {
                    width: 20px;
                    height: 16px;
                    vertical-align: middle;
                }
            }
        }
        .soc-net {
            display: inline-block;
            width: 25%;
            text-align: right;
            span {
                display: block;
                font-weight: 300;
                margin-bottom: 26px;
                line-height: 0;
            }
            .frame {display: inline-block;}
        }
    }
    .phones {
        .block {
            border-bottom: 1px dashed #e0e0e0;
            padding-bottom: 3px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h2 {
                display: inline-block;
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                text-transform: uppercase;
                margin: 0;
                padding: 0 32px 5px 10px;
                border-bottom: 3px solid #607d8b;
            }
            .info-date {
                font-size: 14px;
                font-weight: 300;
                color: #616161;
                display: inline-block;
                text-align: right;
                .data {
                    color: #000;
                    font-size: 16px;
                }
            }
        }
        .contact {
            padding: 45px 0;
            .row {
                padding: 0 0 15px 0;
                .wr-phone {
                    width: 365px;
                    display: flex;
                    padding-left: 10px;
                    justify-content: space-between;
                    .phones {
                        display: inline-block;
                        font-size: 16px;
                        line-height: 18px;
                        color: #000;
                        margin-right: 65px;
                        font-weight: 300;
                        &.city {margin-right: 100px;}
                    }
                    .frame {
                        width: 40%;
                        display: inline-block;
                        .operator {
                            color: #9c9c9c;
                            font-size: 15px;
                            line-height: 18px;
                            font-weight: 400;
                            padding-left: 36px;
                            vertical-align: bottom;
                            background: url(/dependencies/images/icon42.svg) no-repeat 0 50%;
                            &.kievstar {background: url(/dependencies/images/icon42.svg) no-repeat 0 50%;}
                            &.life {background: url(/dependencies/images/icon44.svg) no-repeat 0 50%;}
                            &.city {background: url(/dependencies/images/icon45.svg) no-repeat 0 50%;}
                            &.mts {background: url(/dependencies/images/icon43.svg) no-repeat 0 50%}
                        }
                    }
                }
            }
        }
    }
    .rate {
        .block {
            border-bottom: 1px dashed #e0e0e0;
            padding-bottom: 5px;
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            justify-content: space-between;
            h2 {
                display: inline-block;
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                text-transform: uppercase;
                margin: 0;
                padding: 0 50px 5px 10px;
                border-bottom: 3px solid #607d8b;
            }
        }
        .time {
            height: 49px;
            line-height: 46px;
            width: 100%;
            background: #e6f7ff;
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 35px;
            .title {
                width: 45%;
                display: inline-block;
            }
            .day, .night {
                width: 24%;
                display: inline-block;
                vertical-align: middle;
                span {
                    color: #607d8b;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 400;
                }
            }
        }
        .row {
            margin-bottom: 15px;
            padding: 20px 0 10px 0;
            .title {
                position: relative;
                margin-bottom: 20px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: transparent;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 10;
                }
                .frame {
                    vertical-align: middle;
                    background: #fff;
                    width: 16%;
                    z-index: 15;
                    left: 10px;
                    position: relative;
                    img {vertical-align: middle;}
                    span {
                        vertical-align: middle;
                        font-size: 14px;
                        line-height: 18px;
                        text-transform: uppercase;
                        color: #ff8a80;
                        margin-left: 5px;
                    }
                }
            }
            .descr {
                margin-bottom: 25px;
                .info-row {
                    margin-bottom: 15px;
                    display: flex;
                    padding-left: 10px;
                    justify-content: space-between;
                    .name {
                        display: inline-block;
                        width: 49%;
                        span {
                            display: block;
                            font-size: 16px;
                            line-height: 18px;
                            font-weight: 400;
                            color: #000;
                        }
                    }
                    .day {
                        display: flex;
                        justify-content: center;
                        width: 49%;
                        span {
                            display: flex;
                            justify-content: space-between;
                            width: 70px;
                            font-size: 16px;
                            line-height: 18px;
                            font-weight: 400;
                            color: #000;
                            b {
                                text-align: right;
                                display: block;
                                width: 40px;
                            }
                        }
                    }
                    .night {
                        display: inline-block;
                        width: 24%;
                        span {
                            display: flex;
                            justify-content: space-between;
                            width: 70px;
                            font-size: 16px;
                            line-height: 18px;
                            font-weight: 400;
                            color: #000;
                            b {
                                text-align: right;
                                display: block;
                                width: 40px;
                            }
                        }
                    }
                }
            }
            &.standart > .title > .frame > span {color: #40c4ff;}
        }
    }
    .services {
        .block {
            border-bottom: 1px dashed #e0e0e0;
            padding-bottom: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h2 {
                display: inline-block;
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                text-transform: uppercase;
                margin: 0;
                padding: 0 50px 5px 10px;
                border-bottom: 3px solid #607d8b;
            }
        }
        .service-icons {
            padding: 25px 9px;
            ul {
                @extend %list-reset;
                columns: 4;
                li {
                    display: inline-block;
                    margin: 0 0 10px 0;
                    width: 100%;
                    .icon {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .frame {
                            display: block;
                            width: 38px;
                            height: 38px;
                            border-radius: 50%;
                            vertical-align: middle;
                            text-align: center;
                            margin-right: 8px;
                            background: #f0f0f0 url(#{$imgurl}/sprite.png) 50% 50%;
                            &.evacuator {background-position: -2px -5px;}
                            &.truck {background-position: -40px -5px;}
                            &.car {background-position: -82px -5px;}
                            &.forhour {background-position: -128px -5px;}
                            &.transfer {background-position: -171px -6px;}
                            &.nanny {background-position: -215px -6px;}
                            &.no-smoke-car {background-position: -261px -4px;}
                            &.smoke-car {background-position: -308px -4px;}
                            &.cond {background-position: -356px -4px;}
                            &.zoo {background-position: -400px -4px;}
                            &.curier {background-position: -446px -4px;}
                            &.no-alco {background-position: -489px -4px;}
                            &.celebrate {background: #f0f0f0 url(#{$imgurl}/icon09.png) no-repeat 50% 50%;}
                            &.woman {background-position: -536px -2px;}
                            &.english-man {background-position: 40px -4px;}
                        }
                        .name {
                            display: block;
                            flex: 1;
                            font-size: 14px;
                            line-height: 14px;
                            color: #000;
                            font-weight: 400;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
    .reviews {
        padding-bottom: 30px;
        .block {
            border-bottom: 1px dashed #e0e0e0;
            padding-bottom: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 36px;
            h2 {
                display: inline-block;
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                text-transform: uppercase;
                margin: 0;
                padding: 0 50px 5px 10px;
                border-bottom: 3px solid #607d8b;
            }
        }
        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            padding-left: 10px;
            span:first-child {
                font-size: 16px;
                line-height: 18px;
                color: #000;
                font-weight: 400;
            }
            span:last-child {
                font-size: 14px;
                line-height: 14px;
                padding-right: 10px;
                color: #9c9c9c;
                b {
                    color: #000;
                    font-size: 16px;
                }
            }
        }
        textarea {
            width: 100%;
            background: #f0f0f0;
            border: none;
            border-radius: 20px;
            height: 120px;
            max-height: 215px;
            padding: 15px;
            max-width: 100%;
            margin-bottom: 20px;
        }
        .soc-net {
            text-align: center;
            margin-bottom: 20px;
            span {
                display: block;
                width: 100%;
                margin-bottom: 15px;
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
            }
            ul {
                @extend %list-reset;
                li {
                    display: inline-block;
                }
            }
        }
        .rating-com {
            display: inline-block;
            width: 100%;
            margin-bottom: 15px;
            padding-left: 10px;
            .star-rating-wrapper {
                display: inline-block;
                direction: rtl;
                .star-input {
                    display: none;
                }
                .starlabel {
                    cursor: pointer;
                }
                .starlabel:before {
                    display: inline-block;
                    vertical-align: middle;
                    content: '';
                    width: 20px;
                    height: 20px;
                    background: url(#{$imgurl}/star.svg) no-repeat;
                }
                .starlabel:hover:before,
                .starlabel:hover ~ .starlabel:before,
                .star-input:checked ~ .starlabel:before {
                    background: url(#{$imgurl}/star-fill.svg) no-repeat;
                }
            }
        }
        .comments {
            display: block;
            width: 100%;
            & > div:first-child > .row > .comment {
                border-top: 1px dashed #c9c9c9;
                @media screen and (max-width: 980px) {
                    border-top: none;
                }
            }
            .row {
                display: flex;
                justify-content: space-between;
                .frame {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    vertical-align: top;
                    margin-top: 10px;
                }
                .comment {
                    display: inline-block;
                    border-bottom: 1px dashed #c9c9c9;
                    width: 94%;
                    padding: 17px 22px 25px 1px;
                    .top {
                        display: flex;
                        justify-content: space-between;
                        .name {
                            display: inline-block;
                            font-size: 16px;
                            line-height: 18px;
                            color: #000;
                            margin-bottom: 10px;
                            font-weight: 700;
                        }
                        .date {
                            display: inline-block;
                            font-size: 12px;
                            line-height: 14px;
                            margin-bottom: 10px;
                            color: #616161;
                            margin-right: 10px;
                        }
                        .rate {
                            text-align: right;
                            .rating {
                                display: inline-block;
                                width: 68px;
                                margin-bottom: 3px;
                                direction: ltr;
                                vertical-align: middle;
                                .star-input {
                                    display: none;
                                }
                                .star-rating-wrapper {
                                    display: inline-block;
                                    direction: ltr;
                                    .star-rating-wrapper {
                                        span {
                                            height: 10px !important;
                                            width: 10px !important;
                                            margin-right: -1px;
                                        }
                                    }
                                }
                                .starlabel:before {
                                    display: inline-block;
                                    vertical-align: middle;
                                    content: '';
                                    width: 10px;
                                    height: 10px;
                                    background: url(#{$imgurl}/star.svg) no-repeat;
                                }
                                .starlabel:hover:before,
                                .starlabel:hover ~ .starlabel:before {
                                    background: url(#{$imgurl}/star-fill.svg) no-repeat;
                                }
                                .starlabel:hover:before,
                                .starlabel:hover ~ .starlabel:before,
                                .star-input:checked ~ .starlabel:before {
                                    background: url(#{$imgurl}/star-fill.svg) no-repeat;
                                }
                                .frame {
                                    display: inline-block;
                                    padding-left: 10px;
                                    direction: ltr;
                                    vertical-align: middle;
                                    img {vertical-align: middle;}
                                    span {
                                        font-size: 10px;
                                        color: #9c9c9c;
                                    }
                                }
                            }
                        }
                    }
                    .txt-holder {
                        font-size: 14px;
                        line-height: 16px;
                        color: #616161;
                        font-weight: 300;
                        p {margin: 0;}
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 0 10px;
    }
    @media screen and (max-width: 980px) {
        padding: 0;
        .breadcrumbs {display: none;}
        .title-box {
            padding-top: 30px;
            justify-content: flex-start;
            .back-link {
                display: inline-block;
                width: 30px;
                height: 60px;
                margin-right: 40px;
                position: relative;
                transition: 0.3s;
                &:before, &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 2px;
                    height: 14px;
                    top: 20px;
                    left: 40px;
                    transform: rotate(45deg);
                    background: #616161;
                    transition: 0.3s;
                }
                &:after {
                    transform: rotate(-45deg);
                    top: 29px;
                }
            }
            .frame-taxi {
                width: auto;
                margin-right: 30px;
            }
            .rating {
                width: auto;
            }
            .soc-net {
                display: none;
            }
        }
        .phones {
            .block {
                h2 {
                    border: none;
                    padding-bottom: 0;
                    color: #607d8b;
                }
                .info-date {
                    display: none;
                }
            }
            .contact {
                .row {
                    .wr-phone {
                        width: 350px;
                    }
                }
            }
        }
        .rate {
            .block {
                // padding-bottom: 0;
                margin: 0;
                border-bottom: 1px dashed #c9c9c9;
                h2 {
                    border: none;
                    padding-bottom: 0;
                    margin: 0;
                    color: #607d8b;
                }
            }
            .time {
                line-height: 16px;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                .day, .night {
                    span {
                        font-size: 14px;
                        b {
                            display: block;
                        }
                    }
                }
            }
            .row {
                .title {
                    margin-bottom: 15px;
                    .frame {
                        width: 150px;
                        margin-bottom: 10px;
                    }
                }
                .descr {
                    .info-row {
                        display: flex;
                        align-items: flex-start;
                        .name, .day, .night {
                            span {
                            }
                        }
                    }
                }
            }
        }
        .services {
            .block {
                padding-bottom: 0;
                margin: 0;
                border-bottom: 1px dashed #c9c9c9;
                h2 {
                    border: none;
                    padding-bottom: 0;
                    margin: 0;
                    color: #607d8b;
                }
            }
            .service-icons {
                padding: 20px 10px;
                ul {columns: 2;}
            }
        }
        .reviews {
            padding-bottom: 16px;
            .block {
                margin: 0;
                border-bottom: 1px dashed #c9c9c9;
                h2 {
                    border: none;
                    padding-bottom: 0;
                    margin: 0;
                    color: #607d8b;
                }
            }
            .title {display: none;}
            textarea {display: none;}
            .soc-net {display: none;}
            .comments {
                margin-bottom: 15px;
                .row {
                    .comment {
                        border-top: none;
                        width: 95%;
                        padding: 15px 0 15px 10px;
                        .top {
                            display: block;
                            .name {
                                width: 100%;
                                display: block;
                                margin-bottom: 0;
                            }
                            .rate {text-align: left;}
                        }
                    }
                }
            }
            .rating-com {
                display: none;
            }
        }
        .btn-review {
            display: block;
            width: 300px;
            height: 60px;
            line-height: 60px;
            text-transform: uppercase;
            background: #40c4ff;
            text-align: center;
            color: #fff;
            margin: 0 auto;
            border-radius: 10px;
            @include anim($dur: .3s);
            &:hover {opacity: 0.8;}
        }
        .list-pagination {display: none;}
    }
    @media screen and (max-width: 480px) {
        .title-box {
            .back-link {
                margin-right: 5px;
                &:before, &:after {left: 15px;}
            }
            .frame-taxi {
                width: 100px;
                margin-right: 10px;
            }
            .rating {
                .name {
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 700;
                    margin-bottom: 5px;
                }
                margin-right: 0;
                .starlabel:before {
                    width: 15px;
                    height: 15px;
                }
                .star-rating-wrapper {
                    margin-right: 5px;
                    & > span {
                        width: 18px !important;
                        height: 18px !important;
                        margin-right: 2px;
                        &:last-child {margin-right: 0;}
                    }
                }
            }
        }
        .phones {
            .block {
                h2 {padding-left: 20px;}
                .info-date {display: none;}
            }
            .contact {
                padding: 20px;
                .row {
                    .wr-phone {
                        width: 100%;
                        padding-left: 0;
                        .phones {
                            font-size: 14px;
                            margin-right: 30px;
                            &.city {margin-right: 55px;}
                        }
                        .frame {
                            span {font-size: 14px;}
                        }
                    }
                    &:last-child {padding-bottom: 0;}
                }
            }
        }
        .rate {
            .block {
                h2 {
                    padding-left: 20px;
                }
            }
            .time {
                .title {
                    width: 40%;
                }
                .day {
                    width: 30%;
                    text-align: center;
                    span {
                        font-size: 12px;
                        b {
                            font-size: 13px;
                        }
                    }
                }
                .night {
                    width: 30%;
                    text-align: center;
                    span {
                        font-size: 12px;
                        b {
                            font-size: 13px;
                        }
                    }
                }
            }
            .row {
                padding: 20px 20px;
                margin: 0;
                .title {
                    .frame {
                        width: 135px;
                        left: 0;
                    }
                }
                .descr {
                    margin: 0;
                    .info-row {
                        padding-left: 0;
                        .name {
                            width: 70%;
                            span {
                                font-size: 14px;
                            }
                        }
                        .day {
                            width: 30%;
                            span {
                                font-size: 14px;
                                b {
                                    width: 45px;
                                }
                            }
                        }
                        .night {
                            width: 30%;
                            span {
                                font-size: 14px;
                                b {
                                    width: 45px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .services {
            .block {
                h2 {
                    padding-left: 20px;
                }
            }
            .service-icons {
                padding: 20px 20px;
                ul {
                    columns: 1;
                }
            }
        }
        .reviews {
            .block {
                h2 {
                    padding-left: 20px;
                }
            }
            .comments {
                .row {
                    border-bottom: 1px dashed #c9c9c9;
                    .frame {
                        margin-left: 20px;
                    }
                    .comment {
                        border: none;
                        padding-right: 20px;
                        .top {
                            .name {
                                margin-bottom: 3px;
                            }
                            .rate {
                                text-align: left;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                .date {
                                    margin-bottom: 0;
                                }
                                .rating {
                                    margin-bottom: 5px;
                                    width: 82px;
                                    .star-rating-wrapper {
                                        .star-rating-wrapper {
                                            span {
                                                width: 12px !important;
                                                height: 12px !important;
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// END TAXI PAGE
// END TAXI PAGE

// START #CALLBACK PAGE
// START #CALLBACK PAGE
.callback-page {
    width: 93%;
    h1 {
        padding-left: 20px;
        font-size: 28px;
        line-height: 30px;
        font-weight: 300;
        color: #000;
        margin: 0 0 40px 0;
        padding-top: 39px;
    }
    .row {
        padding-left: 20px;
        margin-bottom: 20px;
        .frame {
            display: none;
            img {
                vertical-align: middle;
            }
            span {
                font-size: 16px;
                color: #ff5252;
                line-height: 18px;
                font-weight: 300;
            }
        }
        input[type="text"] {
            width: 380px;
            background: #f0f0f0;
            height: 40px;
            margin: 0 20px 5px 0;
            font-size: 16px;
            color: #000;
            font-weight: 400;
            border: none;
            border-radius: 50px;
            padding: 0 15px;
            &.invalid {
                border: 1px solid #ff8a80;
            }
            &.invalid + .frame {
                display: inline-block;
            }
        }
        label {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 18px;
            color: #000;
            font-weight: 400;
            .need {
                font-size: 12px;
                color: #ff8a80;
                line-height: 14px;
                font-weight: 300;
            }
            .value {
                font-size: 16px;
                color: #ff8a80;
                line-height: 18px;
                font-weight: 500;
            }
        }
        .theme-list {
            display: block;
            width: 380px;
            position: relative;
            .active-theme {
                display: block;
                background: #f0f0f0;
                border-radius: 50px;
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                font-weight: 400;
                padding: 0 15px;
                color: #000;
                &:before {
                    content: '';
                    position: absolute;
                    right: 24px;
                    top: 18px;
                    width: 10px;
                    height: 10px;
                    background: transparent;
                    border: 5px solid transparent;
                    border-top-color: #000;
                }
            }
            ul {
                @extend %list-reset;
                z-index: -1;
                visibility: visible;
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                background: #fff;
                width: 100%;
                border-radius: 20px;
                padding: 8px 0;
                box-shadow: 0 0 8px rgba(0, 0, 0, .5);
                &:before {
                    content: '';
                    position: absolute;
                    right: 24px;
                    top: 12px;
                    width: 10px;
                    height: 10px;
                    background: transparent;
                    border: 5px solid transparent;
                    border-bottom-color: #000;
                }
                li {
                    display: block;
                    a {
                        display: block;
                        color: #000;
                        line-height: 40px;
                        padding: 0 15px;
                        font-size: 16px;
                        font-weight: 400;
                        &:hover, &.active {
                            color: #40c4ff;
                        }
                    }
                }
                &.theme-list-active {
                    z-index: 50;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        textarea {
            height: 180px;
            max-height: 280px;
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            width: 100%;
            background: #f0f0f0;
            border: none;
            padding: 15px;
            border-radius: 20px;
            resize: none;
        }
    }
    .send-btn {
        display: block;
        margin: 0 20px 30px 20px;
        @include anim($dur: .3s);
        width: 160px;
        height: 40px;
        line-height: 41px;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        background: rgba(64, 196, 255, 1);
        border-radius: 8px;
        &:hover {background: rgba(64, 196, 255, 0.8);}
    }
    .txt-holder {
        padding-left: 20px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 300;
        margin-bottom: 20px;
        p {margin: 0;}
    }
    @media screen and (max-width: 1200px) {
        padding: 0 10px;
        width: 100%;
    }
    @media screen and (max-width: 980px) {
        .row {padding-left: 0;}
        .send-btn {margin-left: 0;}
        .txt-holder {padding: 0;}
    }
    @media screen and (max-width: 768px) {
        h1 {
            padding: 30px 0 0 0;
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 480px) {
        .row {
            padding-left: 0;
            input[type="text"],
            .theme-list,
            textarea {width: 100%;}
        }
        .send-btn {margin-left: 0;}
        .txt-holder {padding-left: 0;}
    }
}
// END CALLBACK PAGE
// END CALLBACK PAGE

// START #REVIEW PAGE
// START #REVIEW PAGE
.review-page {
    .content {
        padding: 0 10px;
        .review {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 18px;
            color: #000;
            font-weight: 500;
            position: relative;
            .back-link {
                display: inline-block;
                width: 30px;
                height: 60px;
                margin-right: 10px;
                position: relative;
                transition: 0.3s;
                top: -1px;
                &:before, &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 2px;
                    height: 14px;
                    top: 20px;
                    left: 15px;
                    transform: rotate(45deg);
                    background: #616161;
                    transition: 0.3s;
                }
                &:after {
                    transform: rotate(-45deg);
                    top: 29px;
                }
                &:hover {
                    &:before, &:after {left: 10px;}
                }
            }
        }
        textarea {
            width: 100%;
            display: block;
            background: #f0f0f0;
            min-height: 200px;
            max-height: 300px;
            border: none;
            margin-bottom: 12px;
            border-radius: 25px;
            padding: 20px;
            font-size: 16px;
        }
        .rating-com {
            display: inline-block;
            width: 100%;
            margin-bottom: 15px;
            .star-rating-wrapper {
                display: inline-block;
                direction: rtl;
                .star-input {display: none;}
                .starlabel {cursor: pointer;}
                .starlabel:before {
                    display: inline-block;
                    vertical-align: middle;
                    content: '';
                    width: 20px;
                    height: 20px;
                    background: url(#{$imgurl}/star.svg) no-repeat;
                }
                .starlabel:hover:before,
                .starlabel:hover ~ .starlabel:before,
                .star-input:checked ~ .starlabel:before {
                    background: url(#{$imgurl}/star-fill.svg) no-repeat;
                }
            }
        }
        .title {
            display: block;
            text-align: center;
            margin-bottom: 12px;
            font-size: 16px;
            line-height: 18px;
            color: #000;
            font-weight: 500;
        }
        .soc-net {
            @extend %list-reset;
            li {
                margin-bottom: 10px;
                vertical-align: middle;
                a {
                    display: block;
                    width: 100%;
                    height: 80px;
                    vertical-align: middle;
                    text-align: center;
                    border-radius: 14px;
                    background: #3b5998;
                    @include anim($dur: .3s);
                    &:hover {opacity: 0.8; }
                    &:before {
                        display: inline-block;
                        content: '';
                        height: 100%;
                        vertical-align: middle;
                    }
                    img {
                        vertical-align: middle !important;
                        width: 15px;
                        height: 30px;
                    }
                    span {
                        font-size: 20px;
                        color: #fff;
                        font-weight: 400;
                        line-height: 22px;
                        text-transform: uppercase;
                        vertical-align: middle;
                        padding-left: 45px;
                        position: relative;
                    }
                    &.fb {
                        span:before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            content: '';
                            width: 31px;
                            display: inline-block;
                            height: 36px;
                            background: url(/dependencies/images/icon62.svg) no-repeat 50% 71%;
                            background-size: 50%;
                            vertical-align: middle;
                            margin-right: 10px;
                            margin-bottom: 4px;
                        }
                    }
                    &.gp {
                        span:before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            content: '';
                            width: 30px;
                            display: inline-block;
                            height: 35px;
                            background: url(/dependencies/images/icon63.svg) no-repeat 50% 71%;
                            background-size: 100%;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                    &.vk {
                        span:before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            content: '';
                            display: inline-block;
                            width: 30px;
                            height: 19px;
                            background: url(/dependencies/images/icon64.svg) no-repeat 50% 5%;
                            background-size: 100%;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                    &.tw {
                        span:before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            content: '';
                            width: 30px;
                            height: 25px;
                            background: url(/dependencies/images/icon67.svg) no-repeat 50% 5%;
                            background-size: 100%;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                }
                &:nth-child(2) a {background: #dd4b39;}
                &:nth-child(3) a {background: #4f86c0;}
            }
        }
    }
}

// END REVIEW PAGE
// END REVIEW PAGE

// START #ABOUT PAGE
// START #ABOUT PAGE
.about-page {
    .top {
        .frame {padding: 39px 0 45px 10px;}
    }
    .descr {
        .block {
            border-bottom: 1px dashed #e0e0e0;
            padding-bottom: 5px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h2 {
                display: inline-block;
                text-align: left;
                font-size: 28px;
                font-weight: 400;
                margin: 0;
                color: #607d8b;
                padding-left: 10px;
            }
        }
        .title {
            padding-left: 10px;
            margin-bottom: 60px;
            & > span {
                font-size: 16px;
                line-height: 18px;
                color: #000;
                font-weight: 400;
            }
            ul {
                padding-left: 20px;
                list-style-image: url(#{$imgurl}/icon57.png);
                vertical-align: middle;
                li {
                    width: 100%;
                    vertical-align: middle;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 300;
                    margin-bottom: 10px;
                    &:before {
                        display: inline-block;
                        content: '';
                        vertical-align: middle;
                        height: 100%;
                    }
                }
            }
        }
    }
    .cities {
        margin-bottom: 60px;
        .block {
            border-bottom: 1px dashed #e0e0e0;
            padding-bottom: 5px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h2 {
                display: inline-block;
                text-align: left;
                font-size: 28px;
                font-weight: 400;
                margin: 0;
                color: #607d8b;
                padding-left: 10px;
            }
        }
        & > span {
            display: block;
            font-size: 16px;
            line-height: 18px;
            color: #000;
            font-weight: 400;
            margin-bottom: 25px;
            padding-left: 10px;
        }
        ul {
            vertical-align: middle;
            @extend %list-reset;
            padding-left: 10px;
            columns: 4;
            li {
                width: 100%;
                vertical-align: middle;
                font-size: 16px;
                line-height: 18px;
                font-weight: 300;
                margin-bottom: 15px;
                display: inline-block;
                &:before {
                    display: inline-block;
                    content: '';
                    vertical-align: middle;
                    height: 100%;
                }
            }
        }
    }
    .add-option {
        margin-bottom: 60px;
        .block {
            border-bottom: 1px dashed #e0e0e0;
            padding-bottom: 6px;
            margin-bottom: 23px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h2 {
                display: inline-block;
                text-align: left;
                font-size: 28px;
                font-weight: 400;
                margin: 0;
                color: #607d8b;
                padding-left: 10px;
            }
        }
        & > span {
            padding-left: 10px;
            display: block;
            font-size: 19px;
            line-height: 18px;
            color: #000;
            font-weight: 400;
            margin-bottom: 22px;
            letter-spacing: -0.3px;
        }
        ul {
            vertical-align: middle;
            @extend %list-reset;
            padding-left: 10px;
            li {
                width: 100%;
                vertical-align: top;
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                padding-left: 67px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                .frame {
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 4px;
                    width: 38px;
                    margin-right: 15px;
                    vertical-align: top;
                    height: 38px;
                    border-radius: 50%;
                    background: #f2f2f2 url(#{$imgurl}/sprite.png) repeat-x -3px -5px;
                    &.evacuator {background-position: -2px -4px;}
                    &.truck {background-position: -39px -4px;}
                    &.car {background-position: -82px -4px;}
                    &.forhour {background-position: -127px -4px;}
                    &.transfer {background-position: -171px -5px;}
                    &.nanny {background-position: -215px -5px;}
                    &.no-smoke-car {background-position: 365px -5px;}
                    &.smoke-car {background-position: 320px -3px;}
                    &.cond {background-position: 271px -4px;}
                    &.zoo {background-position: 227px -3px;}
                    &.curier {background-position: 181px -4px; }
                    &.no-alco {background-position: 137px -4px;}
                    &.celebrate {background: #f2f2f2 url(#{$imgurl}/icon09.png) no-repeat 56% 44%;}
                    &.woman {background-position: 91px -3px;}
                    &.english-man {background-position: 40px -4px;}
                }
                span {
                    display: inline-block;
                    text-align: left;
                    width: 95%;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 20px;
                    vertical-align: middle;
                    padding: 12px 0 12px 0;
                    &:before {
                        content: '';
                        display: inline-block;
                        height: 100%;
                        vertical-align: middle;
                    }
                    .name-serv {
                        display: inline;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        &:before {display: none !important;}
                    }
                }
            }
        }
    }
    .contact-us {
        margin-bottom: 60px;
        .block {
            border-bottom: 1px dashed #e0e0e0;
            padding-bottom: 5px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h2 {
                display: inline-block;
                text-align: left;
                font-size: 28px;
                font-weight: 400;
                margin: 0;
                color: #607d8b;
                padding-left: 10px;
            }
        }
        & > span {
            display: block;
            font-size: 16px;
            line-height: 18px;
            color: #000;
            font-weight: 300;
            margin-bottom: 25px;
            padding-left: 10px;
            a {
                color: #40c4ff;
                font-size: 16px;
                line-height: 18px;
            }
        }
        .contact {
            padding-left: 10px;
            .title {
                display: block;
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                margin-bottom: 5px;
            }
            .phones {
                @extend %list-reset;
                margin-bottom: 15px;
                li {
                    display: block;
                    .phone {
                        display: inline-block;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 300;
                        color: #000;
                    }
                    .operator {
                        display: inline-block;
                        font-size: 16px;
                        line-height: 18px;
                        font-weight: 300;
                        color: #616161;
                        margin-left: 15px;
                    }
                }
            }
            .mail {
                display: block;
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                margin-bottom: 15px;
                a {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 300;
                    color: #000;
                }
            }
            .soc-net {
                .title {
                    display: block;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 400;
                    margin-bottom: 15px;
                }
                ul {
                    @extend %list-reset;
                    li {display: inline-block;}
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 0 10px;
    }
    @media screen and (max-width: 640px) {
        .add-option, .descr, .cities, .contact-us {
            .block {
                h2 {font-size: 22px;}
            }
        }
        .cities {
            ul {columns: 2;}
        }
        .add-option {
            ul {
                li {
                    padding-left: 56px;
                    margin-bottom: 17px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cities {
            ul {columns: 1;}
        }
    }
}
// END ABOUT PAGE
// END ABOUT PAGE

// START #NEWS PAGE
// START #NEWS PAGE
.news-page {
    padding: 39px 10px;
    h2 {
        font-size: 28px;
        line-height: 30px;
        font-weight: 300;
        margin: 0 0 40px 10px;
    }
    .article-big {
        padding-bottom: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #e0e0e0;
        .frame {
            position: relative;
            vertical-align: middle;
            width: 320px;
            height: 320px;
            margin-left: 10px;
            .type {
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                font-size: 12px;
                color: #fff;
                width: 100%;
                height: 45px;
                font-weight: 300;
                padding-left: 20px;
                text-transform: uppercase;
                background: rgba(000, 000, 000, 0.5);
                line-height: 45px;
            }
            .date {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                right: -15px;
                bottom: 35px;
                width: 85px;
                height: 85px;
                background: #ff5252;
                font-size: 11px;
                color: #fff;
                text-align: center;
                vertical-align: middle;
                &:before {
                    content: '';
                    position: absolute;
                    right: 5px;
                    bottom: -10px;
                    width: 20px;
                    height: 20px;
                    background: transparent;
                    border: 10px solid transparent;
                    border-right-color: #662121;
                    transform: rotate(45deg);
                }
                .value {
                    font-size: 36px;
                    line-height: 54px;
                    color: #fff;
                    font-weight: 500;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
            }
        }
        .txt-holder {
            width: 57%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 8px;
            .title {
                display: block;
                font-size: 20px;
                margin-bottom: 40px;
                line-height: 33px;
                text-transform: uppercase;
                color: #000;
                font-weight: 500;
                &:hover {text-decoration: underline;}
            }
            .descr {
                display: block;
                font-size: 16px;
                line-height: 22px;
                font-weight: 300;
                color: #616161;
                margin-bottom: 20px;
            }
            .more-info {
                display: flex;
                justify-content: space-between;
                .date {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 400;
                    color: #616161;
                    padding-left: 25px;
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        top: -1px;
                        width: 15px;
                        height: 14px;
                        background: url(#{$imgurl}/icon58.svg) no-repeat 50% 50%;
                    }
                }
                .read {
                    display: block;
                    font-size: 16px;
                    line-height: 18px;
                    color: #40c4ff;
                    font-weight: 400;
                    text-transform: uppercase;
                    position: relative;
                    padding-right: 17px;
                    &:hover {text-decoration: underline;}
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 2px;
                        width: 7px;
                        height: 11px;
                        background: url(#{$imgurl}/icon59.png) no-repeat 50% 50%;
                    }
                }
            }
        }
    }
    .article-small {
        padding-bottom: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #e0e0e0;
        .frame {
            position: relative;
            vertical-align: middle;
            width: 160px;
            height: 160px;
            margin-left: 10px;
            .type {
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                font-size: 12px;
                color: #fff;
                width: 100%;
                height: 40px;
                font-weight: 300;
                padding-left: 20px;
                text-transform: uppercase;
                background: rgba(000, 000, 000, 0.5);
                line-height: 40px;
            }
            .date {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                right: -15px;
                bottom: 35px;
                width: 60px;
                height: 35px;
                background: #ff5252;
                color: #fff;
                text-align: center;
                font-size: 16px;
                line-height: 35px;
                &:before {
                    content: '';
                    position: absolute;
                    right: 5px;
                    bottom: -10px;
                    width: 20px;
                    height: 20px;
                    background: transparent;
                    border: 10px solid transparent;
                    border-right-color: #662121;
                    transform: rotate(45deg);
                }
            }
        }
        .txt-holder {
            width: 76%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 8px;
            .title {
                display: block;
                font-size: 20px;
                margin-bottom: 20px;
                line-height: 33px;
                text-transform: uppercase;
                color: #000;
                font-weight: 500;
                &:hover {text-decoration: underline;}
            }
            .descr {
                display: block;
                font-size: 16px;
                line-height: 22px;
                font-weight: 300;
                color: #616161;
                margin-bottom: 20px;
            }
            .more-info {
                display: flex;
                justify-content: space-between;
                .date {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 400;
                    color: #616161;
                    padding-left: 25px;
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        top: -1px;
                        width: 15px;
                        height: 14px;
                        background: url(#{$imgurl}/icon58.svg) no-repeat 50% 50%;
                    }
                }
                .read {
                    display: block;
                    font-size: 16px;
                    line-height: 18px;
                    color: #40c4ff;
                    font-weight: 400;
                    text-transform: uppercase;
                    position: relative;
                    padding-right: 17px;
                    &:hover {text-decoration: underline;}
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 3px;
                        width: 7px;
                        height: 11px;
                        background: url(#{$imgurl}/icon59.png) no-repeat 50% 50%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 30px 20px;
        .article-big {
            .txt-holder {width: 63%;}
        }
        .article-small {
            .txt-holder {width: 79%;}
        }
    }
    @media screen and (max-width: 980px) {
        h2 {margin-left: 0;}
        .article-big {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .frame {
                display: block;
                margin-bottom: 30px;
                height: 320px;
                margin-left: 0;
            }
            .txt-holder {
                display: block;
                width: 100%;
                padding-right: 0;
                .title {
                    text-align: center;
                    margin-bottom: 15px;
                }
            }
        }
        .article-small {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .frame {
                display: block;
                margin-bottom: 30px;
                height: 160px;
                margin-left: 0;
            }
            .txt-holder {
                display: block;
                width: 100%;
                padding-right: 0;
                .title {
                    text-align: center;
                    margin-bottom: 15px;
                }
            }
        }
        .list-pagination {display: none;}
    }
    @media screen and (max-width: 640px) {
        .article-big {
            .frame {
                display: block;
                margin-bottom: 30px;
                width: 280px;
                height: 280px;
            }
            .txt-holder {
                display: block;
                width: 100%;
            }
        }
        .article-small {
            .frame {
                display: block;
                margin-bottom: 30px;
            }
            .txt-holder {
                display: block;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 360px) {
        .article-big {
            .frame {height: 300px;}
        }
    }
}
// END NEWS PAGE
// END NEWS PAGE

// END #NEWSONE PAGE
// END #NEWSONE PAGE
.newsone-page {
    padding: 39px 10px;
    .title {
        margin-bottom: 13px;
        .name {
            width: 100%;
            display: block;
            font-size: 28px;
            color: #000;
            line-height: 39px;
            font-weight: 300;
            margin-bottom: 35px;
            text-transform: uppercase;
        }
        .date {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: #616161;
            padding-left: 25px;
            position: relative;
            margin-right: 60px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 1px;
                width: 15px;
                height: 14px;
                background: url(#{$imgurl}/icon58.svg) no-repeat 50% 50%;
            }
        }
        .tags {
            font-size: 12px;
            line-height: 14px;
            color: #616161;
            text-transform: uppercase;
            .tag {
                color: #40c4ff;
            }
        }
    }
    .banner {margin-bottom: 40px;}
    .txt-holder {
        font-size: 16px;
        font-weight: 300;
        color: #000;
        line-height: 22px;
        strong {font-weight: 400;}
    }
    .source {
        display: block;
        margin-bottom: 20px;
        span {
            font-size: 16px;
            line-height: 18px;
            color: #000;
            font-weight: 400;
            a {color: #40c4ff;}
        }
    }
    .soc-net {
        margin-bottom: 15px;
        margin-top: 15px;
        @extend %list-reset;
        li {display: inline-block;}
    }
    .news-all {
        text-align: right;
        a {
            display: inline-block;
            font-size: 16px;
            line-height: 18px;
            color: #40c4ff;
            text-align: left;
            font-weight: 400;
            text-transform: uppercase;
            position: relative;
            padding-left: 25px;
            &:hover {text-decoration: underline;}
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 15px;
                height: 14px;
                background: url(#{$imgurl}/icon59.png) no-repeat;
                transform: rotate(180deg)
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 30px 20px;
    }
    @media screen and (max-width: 480px) {
        .title {
            .name {
                font-size: 18px;
                line-height: 24px;
            }
            .date {
                display: block;
                margin-bottom: 10px;
            }
            .tags {display: block;}
        }
    }
}
// END NEWSONE PAGE
// END NEWSONE PAGE

// START #PARTNERS PAGE
// START #PARTNERS PAGE
.partners-page {
    width: 94%;
    padding: 39px 0;
    .title {
        font-size: 28px;
        line-height: 30px;
        color: #000;
        font-weight: 300;
        margin: 0 0 40px 0;
        padding-left: 10px;
    }
    .promo {
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 20px;
        .frame {
            span {
                display: block;
                font-size: 16px;
                width: 180px;
                text-align: center;
                color: #607d8b;
                margin: 30px auto;
                text-transform: uppercase;
                font-weight: 400;
            }
        }
    }
    .txt-holder {
        font-size: 16px;
        line-height: 22px;
        color: #000;
        font-weight: 300;
        margin-bottom: 50px;
        padding-left: 10px;
        p {margin: 0;}
    }
    .row {
        padding: 30px 0;
        width: 98%;
        border-bottom: 1px solid #e0e0e0;
        .title {
            display: block;
            font-size: 16px;
            line-height: 22px;
            color: #000;
            margin-bottom: 45px;
            font-weight: 300;
            padding-left: 10px;
        }
        .btn {
            display: block;
            width: 220px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            text-transform: uppercase;
            text-align: center;
            background: rgba(64, 196, 255, 1);
            border-radius: 8px;
            font-weight: 400;
            margin-left: 10px;
            &:hover {background: rgba(64, 196, 255, 0.8);}
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 30px 20px;
        width: 100%;
        .promo {padding: 0;}
        .row {width: 100%;}
    }
    @media screen and (max-width: 640px) {
        .title {
            padding: 0;
            font-size: 22px;
            line-height: 24px;
            margin-bottom: 20px;
        }
        .promo {
            flex-direction: column;
            .frame {width: 100%;}
        }
        .txt-holder {margin-bottom: 20px;}
        .row {
            .btn {margin: 0 auto;}
        }
    }
}

// END #PARTNERS PAGE
// END #PARTNERS PAGE

// START #404
// START #404

.page-404 {
    .wrapper {
        .main {
            .content {
                width: 100%;
                .error-page {
                    width: 100%;
                }
            }
            .promotion {display: none;}
        }
    }
}

.error-page {
    width: 94%;
    padding: 39px 0;
    text-align: center;
    .title {
        display: block;
        font-size: 235px;
        font-weight: 400;
        line-height: 236px;
        color: #000;
    }
    .descr {
        display: block;
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        color: #000;
        margin-bottom: 20px;
        & > span {display: block;}
    }
    .back-link {
        display: block;
        & > span {
            position: relative;
            font-size: 16px;
            line-height: 18px;
            color: #40c4ff;
            font-weight: 400;
            padding-left: 17px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                @include anim($dur: .3s);
                left: 0;
                top: 4px;
                width: 7px;
                transform: rotate(180deg);
                height: 11px;
                background: url(/dependencies/images/icon59.png) 50% 50% no-repeat;
            }
        }
        &:hover {
            span {
                &:before {left: -4px;}
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 30px 20px;
        width: 100%;
    }
    @media screen and (max-width: 480px) {
        .title {
            font-size: 100px;
            line-height: 100px;
        }
    }
}
// END #404
// END #404
