.a-left {text-align: left;}
.a-right {text-align: right;}
.a-center {text-align: center;}
.fleft {float: left;}
.fright {float: right;}
.ovhidden {overflow: hidden;}
.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.clearfix:after {
	content: "";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.button-main {
  display: inline-block;
  padding: 0 40px;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.3);
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  text-transform: uppercase;
  &.green {
    border-color: #3c9902;
    color: #3c9902;
    height: 50px;
    line-height: 50px;
    &.fill {
      background: #42ae00;
      color: #fff;
      border-color: #42ae00;
    }
  }
}
.blurry {
	filter: blur(15px);
}
.view-box {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.85);
    position: absolute;
    left: 0;
    top: 0;
  }
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: url("") no-repeat;
    background-position: 100% 100%;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(15px);
  }
}
