// Custom global colors

// Default global colors
$colorText: #000;
$colorLink: #fff;
$colorPlaceholder: #616161;
$colorFocus: #40c4ff;
$bgBody: #fff;

// Widths
$minWidth: 320px;
$maxWidth: 1920px;
$optimalWidth: 1200px;

//Images
$fonturl : '/dependencies/fonts';
$imgurl : '/dependencies/images';

// {{ asset('/images/logo.png') }}" alt="">
// background: url(#{$imgurl}/icon02.png)
