.list-pagination {
    display: block;
    margin-bottom: 25px;
    text-align: center;
    .page {
        margin: 0 40px 0 0;
        display: inline-block;
        font-weight: 300;
            color: #616161;
            b {color: #000;}
    }
    & > .mobile {display: none;}
    & > .desktop {display: block;}
    ul {
        display: inline-block;
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            display: inline-block;
            vertical-align: middle;
            a {
                display: block;
                padding: 6px 10px;
                border: 1px solid #e0e0e0;
                color: #616161;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                vertical-align: middle;
                font-weight: 500;
                &:hover, &.active {
                    background: #d9f3ff;
                    border-color: #beebff;
                }
                &:visited {
                    background: #fff;
                    color: #616161;
                }
                @media screen and (max-width: 768px) {
                    &:hover {
                        background: #fff;
                        color: #616161;
                        border: 1px solid #e0e0e0;
                    }
                    &.active {
                        background: #d9f3ff;
                        border-color: #beebff;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 980px) {
        .page {display: none;}
        & > .desktop {display: none;}
        & > .mobile {
            display: block;
            width: 300px;
            margin: 0 auto;
            position: relative;
        }
        .prev-page {
            position: absolute;
            left: 0;
            top: 0;
            padding: 27px 24px;
            border-radius: 8px;
            border: 1px solid #e0e0e0;
            color: #616161;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            vertical-align: middle;
            font-weight: 500;
            background: url(#{$imgurl}/icon47.png) no-repeat 50% 50%;
            background-size: 23%;
            &:hover, &.active {
                background: #d9f3ff url(#{$imgurl}/icon47.png) no-repeat 50% 50%;
                border-color: #beebff;
                background-size: 23%;
            }
            &:visited {
                background: #fff url(#{$imgurl}/icon47.png) no-repeat 46% 50%;
                border-color: #e0e0e0;
                background-size: 23%;
            }
            @media screen and (max-width: 768px) {
                &:hover {
                    background: #fff url(#{$imgurl}/icon47.png) no-repeat 50% 50%;
                    border: 1px solid #e0e0e0;
                    background-size: 23%;
                }
                &.active {
                    background: #d9f3ff url(#{$imgurl}/icon47.png) no-repeat 50% 50%;
                    border-color: #beebff;
                    background-size: 23%;
                }
            }
        }
        .next-page {
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            padding: 27px 24px;
            border: 1px solid #e0e0e0;
            color: #616161;
            border-radius: 8px;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            vertical-align: middle;
            font-weight: 500;
            background: url(#{$imgurl}/icon48.png) no-repeat 50% 50%;
            background-size: 23%;
            &:hover, &.active {
                background: #d9f3ff url(#{$imgurl}/icon48.png) no-repeat 50% 50%;
                border-color: #beebff;
                background-size: 23%;
            }
            &:visited {
                background: #fff url(#{$imgurl}/icon48.png) no-repeat 50% 50%;
                border-color: #e0e0e0;
                background-size: 23%;
            }
            @media screen and (max-width: 768px) {
                &:hover {
                    background: #fff url(#{$imgurl}/icon48.png) no-repeat 50% 50%;
                    border: 1px solid #e0e0e0;
                    background-size: 23%;
                }
                &.active {
                   background: #d9f3ff url(#{$imgurl}/icon48.png) no-repeat 50% 50%;
                   border-color: #beebff;
                   background-size: 23%;
               }
            }
        }
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                margin: 0 0 0 -5px;
                a {
                    padding: 18px 19px;
                    font-size: 17px;
                    line-height: 18px;
                }
                &:first-child a {border-radius: 8px 0 0 8px;}
                &:nth-child(3) a {border-radius: 0 8px 8px 0;}
            }
            .next {display: none;}
            .prev {display: none;}
            .all {display: none;}
        }
        .pagination-counter-1 {
            li {
                 a:first-child {border-radius: 8px;}
            }
        }
        .pagination-counter-2 {
            li {
                 &:first-child a {border-radius: 8px 0 0 8px;}
                 &:last-child a {border-radius: 0 8px 8px 0;}
            }
        }
    }
}

// .list-pagination {
//     display: block;
//     margin-bottom: 25px;
//     text-align: center;
//     .page {
//         margin: 0 40px 0 0;
//         display: inline-block;
//             color: #616161;
//             font-weight: 300;
//             b {color: #000;}
//     }
//     ul {
//         display: inline-block;
//         @extend %list-reset;
//         li {
//             display: inline-block;
//             vertical-align: middle;
//             a {
//                 display: block;
//                 padding: 6px 10px;
//                 border: 1px solid #e0e0e0;
//                 color: #616161;
//                 font-size: 12px;
//                 line-height: 14px;
//                 text-align: center;
//                 vertical-align: middle;
//                 font-weight: 500;
//                 &:hover, &.active {
//                     background: #d9f3ff;
//                     border-color: #beebff;
//                 }
//             }
//         }
//     }
// }
// .list-pagination {
//     display: block;
//     text-align: center;
//     .page {
//         margin: 0 40px 0 0;
//         display: inline-block;
//             color: #616161;
//             font-weight: 300;
//             b {color: #000;}
//     }
//     ul {
//         display: inline-block;
//         @extend %list-reset;
//         li {
//             display: inline-block;
//             vertical-align: middle;
//             a {
//                 display: block;
//                 padding: 6px 10px;
//                 border: 1px solid #e0e0e0;
//                 color: #616161;
//                 font-size: 12px;
//                 line-height: 14px;
//                 text-align: center;
//                 vertical-align: middle;
//                 font-weight: 500;
//                 &:hover, &.active {
//                     background: #d9f3ff;
//                     border-color: #beebff;
//                 }
//             }
//         }
//     }
// }
