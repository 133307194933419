%layout:after {
	content: "";
	display: table;
	width: 100%;
	clear: both;
}
%list-reset {
	padding: 0;
	margin: 0;
	list-style: none;
}