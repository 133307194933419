@charset "UTF-8";
@import url(custom.css);
* {
  max-height: 100000px;
  box-sizing: border-box; }

*:before, *:after {
  box-sizing: border-box; }

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
  border: 0;
  -ms-interpolation-mode: bicubic; }

form, fieldset {
  margin: 0;
  padding: 0;
  border-style: none; }

input[type=text]::-ms-clear {
  display: none; }

input[type="search"]::-webkit-search-decoration {
  display: none; }

input[type="search"] {
  -webkit-appearance: none; }

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

textarea,
input {
  border-radius: 0; }

input[type=image] {
  padding: 0;
  border: none; }

*:focus {
  outline: 0; }

input:focus, textarea:focus {
  outline: none; }

input[type="email"]:invalid,
input[type="number"]:invalid {
  box-shadow: none; }

input:required {
  box-shadow: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
  outline: 0; }

main, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

mark {
  background: none; }

q {
  quotes: none; }

q:before,
q:after {
  content: '';
  content: none; }

p, dl, menu, ol, ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

menu, ol, ul {
  padding: 0 0 0 40px; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
/*svg { overflow: hidden;}*/
.header .header-holder .mobile-header .mobile-nav, .header .header-holder .nav, .footer .add-nav .add-nav-holder .nav ul, .taxi-city .search-holder .title-box .dropdown-cities ul, .taxi-city .filter .filter-icons ul, .taxi-city .filter .sort .sort-list ul, .taxi-city .list-taxi .list .row .services ul, .taxi-page .services .service-icons ul, .taxi-page .reviews .soc-net ul, .callback-page .row .theme-list ul, .review-page .content .soc-net, .about-page .cities ul, .about-page .add-option ul, .about-page .contact-us .contact .phones, .about-page .contact-us .contact .soc-net ul, .newsone-page .soc-net {
  padding: 0;
  margin: 0;
  list-style: none; }

.a-left {
  text-align: left; }

.a-right {
  text-align: right; }

.a-center {
  text-align: center; }

.fleft {
  float: left; }

.fright {
  float: right; }

.ovhidden {
  overflow: hidden; }

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.button-main {
  display: inline-block;
  padding: 0 40px;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.3);
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  text-transform: uppercase; }
  .button-main.green {
    border-color: #3c9902;
    color: #3c9902;
    height: 50px;
    line-height: 50px; }
    .button-main.green.fill {
      background: #42ae00;
      color: #fff;
      border-color: #42ae00; }

.blurry {
  filter: blur(15px); }

.view-box {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden; }
  .view-box:after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
    position: absolute;
    left: 0;
    top: 0; }
  .view-box:before {
    content: "";
    width: 100%;
    height: 100%;
    background: url("") no-repeat;
    background-position: 100% 100%;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(15px); }

.list-pagination {
  display: block;
  margin-bottom: 25px;
  text-align: center; }
  .list-pagination .page {
    margin: 0 40px 0 0;
    display: inline-block;
    font-weight: 300;
    color: #616161; }
    .list-pagination .page b {
      color: #000; }
  .list-pagination > .mobile {
    display: none; }
  .list-pagination > .desktop {
    display: block; }
  .list-pagination ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style: none; }
    .list-pagination ul li {
      display: inline-block;
      vertical-align: middle; }
      .list-pagination ul li a {
        display: block;
        padding: 6px 10px;
        border: 1px solid #e0e0e0;
        color: #616161;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        vertical-align: middle;
        font-weight: 500; }
        .list-pagination ul li a:hover, .list-pagination ul li a.active {
          background: #d9f3ff;
          border-color: #beebff; }
        .list-pagination ul li a:visited {
          background: #fff;
          color: #616161; }
        @media screen and (max-width: 768px) {
          .list-pagination ul li a:hover {
            background: #fff;
            color: #616161;
            border: 1px solid #e0e0e0; }
          .list-pagination ul li a.active {
            background: #d9f3ff;
            border-color: #beebff; } }
  @media screen and (max-width: 980px) {
    .list-pagination .page {
      display: none; }
    .list-pagination > .desktop {
      display: none; }
    .list-pagination > .mobile {
      display: block;
      width: 300px;
      margin: 0 auto;
      position: relative; }
    .list-pagination .prev-page {
      position: absolute;
      left: 0;
      top: 0;
      padding: 27px 24px;
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      color: #616161;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      vertical-align: middle;
      font-weight: 500;
      background: url(/dependencies/images/icon47.png) no-repeat 50% 50%;
      background-size: 23%; }
      .list-pagination .prev-page:hover, .list-pagination .prev-page.active {
        background: #d9f3ff url(/dependencies/images/icon47.png) no-repeat 50% 50%;
        border-color: #beebff;
        background-size: 23%; }
      .list-pagination .prev-page:visited {
        background: #fff url(/dependencies/images/icon47.png) no-repeat 46% 50%;
        border-color: #e0e0e0;
        background-size: 23%; } }
  @media screen and (max-width: 980px) and (max-width: 768px) {
    .list-pagination .prev-page:hover {
      background: #fff url(/dependencies/images/icon47.png) no-repeat 50% 50%;
      border: 1px solid #e0e0e0;
      background-size: 23%; }
    .list-pagination .prev-page.active {
      background: #d9f3ff url(/dependencies/images/icon47.png) no-repeat 50% 50%;
      border-color: #beebff;
      background-size: 23%; } }
  @media screen and (max-width: 980px) {
    .list-pagination .next-page {
      position: absolute;
      right: 0;
      top: 0;
      display: inline-block;
      padding: 27px 24px;
      border: 1px solid #e0e0e0;
      color: #616161;
      border-radius: 8px;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      vertical-align: middle;
      font-weight: 500;
      background: url(/dependencies/images/icon48.png) no-repeat 50% 50%;
      background-size: 23%; }
      .list-pagination .next-page:hover, .list-pagination .next-page.active {
        background: #d9f3ff url(/dependencies/images/icon48.png) no-repeat 50% 50%;
        border-color: #beebff;
        background-size: 23%; }
      .list-pagination .next-page:visited {
        background: #fff url(/dependencies/images/icon48.png) no-repeat 50% 50%;
        border-color: #e0e0e0;
        background-size: 23%; } }
  @media screen and (max-width: 980px) and (max-width: 768px) {
    .list-pagination .next-page:hover {
      background: #fff url(/dependencies/images/icon48.png) no-repeat 50% 50%;
      border: 1px solid #e0e0e0;
      background-size: 23%; }
    .list-pagination .next-page.active {
      background: #d9f3ff url(/dependencies/images/icon48.png) no-repeat 50% 50%;
      border-color: #beebff;
      background-size: 23%; } }
  @media screen and (max-width: 980px) {
    .list-pagination ul {
      padding: 0;
      margin: 0;
      list-style: none; }
      .list-pagination ul li {
        margin: 0 0 0 -5px; }
        .list-pagination ul li a {
          padding: 18px 19px;
          font-size: 17px;
          line-height: 18px; }
        .list-pagination ul li:first-child a {
          border-radius: 8px 0 0 8px; }
        .list-pagination ul li:nth-child(3) a {
          border-radius: 0 8px 8px 0; }
      .list-pagination ul .next {
        display: none; }
      .list-pagination ul .prev {
        display: none; }
      .list-pagination ul .all {
        display: none; }
    .list-pagination .pagination-counter-1 li a:first-child {
      border-radius: 8px; }
    .list-pagination .pagination-counter-2 li:first-child a {
      border-radius: 8px 0 0 8px; }
    .list-pagination .pagination-counter-2 li:last-child a {
      border-radius: 0 8px 8px 0; } }

body, html {
  color: #000;
  font-family: 'Roboto', sans-serif;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative; }

body {
  overflow-x: hidden; }

.popup-active {
  overflow: hidden;
  position: fixed; }
  .popup-active .active-cover {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 998; }

.city-need {
  overflow: hidden;
  position: fixed; }

a {
  text-decoration: none;
  color: #fff;
  outline: none;
  transition: all 0.15s ease-in-out 0s;
  transition-property: color, background, border-color; }

input, textarea, select, button {
  font-family: 'Roboto', sans-serif;
  vertical-align: middle; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none; }

::-moz-placeholder {
  color: #616161;
  opacity: 1; }

:-ms-input-placeholder {
  color: #616161; }

::-webkit-input-placeholder {
  color: #616161; }

.main {
  width: 1200px;
  flex: 1 0 auto;
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: space-between; }
  .main .content {
    width: 73.5%;
    height: 100%;
    background: #fff; }
    @media screen and (max-width: 1200px) {
      .main .content {
        width: 100%; } }
  @media screen and (max-width: 1200px) {
    .main {
      width: 100%; } }

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden; }

header, footer {
  flex: none; }

.promotion {
  width: 25.5%;
  height: 100%; }
  .promotion .txt-holder {
    padding: 39px 0 32px 10px; }
    .promotion .txt-holder span {
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
      color: #616161;
      display: block; }
  .promotion .apps {
    margin-bottom: 47px;
    text-align: center; }
    .promotion .apps .frame {
      margin-bottom: 13px; }
      .promotion .apps .frame a {
        display: block; }
  .promotion .for-partners {
    width: 100%; }
    .promotion .for-partners span {
      color: #607d8b;
      display: block;
      font-size: 16px;
      line-height: 49px;
      height: 50px;
      padding-left: 62px;
      background: #e6f7ff;
      border-bottom: 1px solid #d6e6ed; }
    .promotion .for-partners .service {
      padding: 22px 0 22px 62px; }
      .promotion .for-partners .service a {
        display: block;
        text-align: left;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 20px;
        font-weight: 300;
        color: #000; }
        .promotion .for-partners .service a:hover {
          text-decoration: underline; }
  .promotion .banner {
    width: 100%;
    height: 850px;
    background: #f0f0f0; }
  @media screen and (max-width: 1200px) {
    .promotion {
      display: none; } }

.header {
  background: #212121;
  color: #fff;
  width: 100%; }
  .header .header-holder {
    padding: 10px 10px 0 10px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    position: relative; }
    .header .header-holder .burger {
      display: none;
      width: 30px;
      height: 30px;
      z-index: 20;
      position: relative; }
      .header .header-holder .burger span {
        display: block;
        width: 100%;
        height: 4px;
        background: #fff; }
      .header .header-holder .burger:before, .header .header-holder .burger:after {
        content: '';
        position: absolute;
        bottom: 13px;
        left: 0;
        display: block;
        width: 100%;
        height: 4px;
        background: #fff; }
      .header .header-holder .burger:after {
        left: 0;
        bottom: 0; }
    .header .header-holder .mobile-header {
      transition: 0.4s;
      width: 250px;
      padding: 20px 20px 20px 20px;
      height: 100vh;
      background: #000;
      position: absolute;
      left: -100%;
      top: 0;
      visibility: hidden;
      z-index: -1;
      opacity: 0; }
      .header .header-holder .mobile-header .close-link {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 20px;
        height: 20px; }
        .header .header-holder .mobile-header .close-link:before, .header .header-holder .mobile-header .close-link:after {
          content: '';
          width: 2px;
          height: 20px;
          background: #fff;
          position: absolute;
          top: 0;
          left: 9px;
          transform: rotate(-45deg); }
        .header .header-holder .mobile-header .close-link:after {
          transform: rotate(45deg); }
      .header .header-holder .mobile-header .mobile-logo {
        height: 52px;
        margin-bottom: 50px; }
      .header .header-holder .mobile-header .mobile-nav {
        text-align: left;
        margin-bottom: 30px; }
        .header .header-holder .mobile-header .mobile-nav li {
          display: block; }
          .header .header-holder .mobile-header .mobile-nav li a {
            display: block;
            padding: 20px 20px 20px 10px;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 18px;
            border-left: 4px solid transparent; }
            .header .header-holder .mobile-header .mobile-nav li a:hover, .header .header-holder .mobile-header .mobile-nav li a.active {
              background: #fff;
              color: #000;
              border-color: #40c4ff; }
      .header .header-holder .mobile-header .mobile-lang {
        text-align: left;
        padding-left: 14px; }
        .header .header-holder .mobile-header .mobile-lang a {
          display: inline-block;
          width: 50px;
          text-align: center;
          font-size: 16px;
          padding: 10px 0;
          line-height: 18px;
          border-left: 2px solid transparent; }
          .header .header-holder .mobile-header .mobile-lang a:hover, .header .header-holder .mobile-header .mobile-lang a.active {
            background: #fff;
            color: #000;
            border-color: #40c4ff; }
      .header .header-holder .mobile-header.open {
        left: 0;
        opacity: 1;
        visibility: visible;
        z-index: 999; }
    .header .header-holder .select-cities-holder {
      display: none; }
    .header .header-holder .title-page {
      display: none; }
    .header .header-holder .frame {
      padding-right: 20px;
      flex-grow: 1; }
      .header .header-holder .frame > a {
        display: block;
        padding-bottom: 10px; }
    .header .header-holder .nav {
      width: 75%;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-grow: 1; }
      .header .header-holder .nav li {
        display: block;
        flex-grow: 1; }
        .header .header-holder .nav li a {
          font-size: 16px;
          line-height: 18px;
          text-transform: uppercase;
          height: 100%;
          padding: 20px 0 30px 0;
          display: block;
          font-weight: 400;
          border-top: 4px solid transparent; }
          .header .header-holder .nav li a:hover, .header .header-holder .nav li a.active {
            background: #fff;
            border-color: #40c4ff;
            color: #000; }
    .header .header-holder .lang {
      padding-bottom: 10px;
      flex-grow: 10;
      text-align: right;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      display: flex;
      justify-content: flex-end; }
      .header .header-holder .lang .link-drop {
        display: block;
        width: 60px;
        text-align: center;
        overflow: hidden;
        position: relative; }
        .header .header-holder .lang .link-drop:before {
          content: '';
          width: 10px;
          height: 10px;
          display: block;
          border: 5px solid transparent;
          border-top-color: #fff;
          position: absolute;
          right: 0;
          top: 8px; }
      .header .header-holder .lang .drop-down {
        position: absolute;
        right: -10px;
        top: -10px;
        height: 98px;
        display: block;
        width: 68px;
        text-align: center;
        background: #fff;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        padding: 10px 0;
        border-radius: 5px;
        transition: opacity 0.2s ease-in-out 0s;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); }
        .header .header-holder .lang .drop-down a {
          display: block;
          color: #000;
          padding: 0 10px 0 0;
          margin-bottom: 17px;
          position: relative;
          font-size: 16px;
          font-weight: 400; }
          .header .header-holder .lang .drop-down a:first-child:before {
            content: '';
            width: 10px;
            height: 10px;
            display: block;
            border: 5px solid transparent;
            border-bottom-color: #000;
            position: absolute;
            right: 8px;
            top: 2px; }
          .header .header-holder .lang .drop-down a:last-child {
            padding-bottom: 0;
            margin-bottom: 0; }
          .header .header-holder .lang .drop-down a.active-lang {
            color: #40c4ff; }
        .header .header-holder .lang .drop-down.active {
          z-index: 15;
          visibility: visible;
          opacity: 1;
          height: auto; }
    .header .header-holder .search-holder {
      display: none;
      width: 6%;
      height: 32px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      text-align: right;
      z-index: 100; }
      .header .header-holder .search-holder.open {
        width: 100%; }
      .header .header-holder .search-holder .search-image {
        display: inline-block;
        width: 30px;
        height: 30px; }
        .header .header-holder .search-holder .search-image.active {
          display: none; }
        .header .header-holder .search-holder .search-image img {
          height: 30px; }
      .header .header-holder .search-holder .search-input {
        position: absolute;
        right: -5px;
        top: 0;
        transition: 0.3s;
        height: 100%;
        width: 1%;
        visibility: hidden;
        z-index: -1;
        opacity: 0;
        padding-left: 31px; }
        .header .header-holder .search-holder .search-input input[type="text"] {
          height: 100%;
          width: 100%;
          display: block;
          background: #f0f0f0;
          border: 0;
          padding: 0 35px 0 10px; }
        .header .header-holder .search-holder .search-input .close-search {
          position: absolute;
          top: 5px;
          right: 10px;
          width: 20px;
          height: 20px;
          z-index: 9595959; }
          .header .header-holder .search-holder .search-input .close-search:before, .header .header-holder .search-holder .search-input .close-search:after {
            content: '';
            width: 2px;
            height: 20px;
            background: #000;
            position: absolute;
            top: 0;
            left: 9px;
            transform: rotate(-45deg); }
          .header .header-holder .search-holder .search-input .close-search:after {
            transform: rotate(45deg); }
        .header .header-holder .search-holder .search-input.active {
          width: 100%;
          visibility: visible;
          z-index: 888;
          opacity: 1; }
  @media screen and (max-width: 1200px) {
    .header .header-holder {
      padding: 10px 20px 0 20px; } }
  @media screen and (max-width: 1023px) {
    .header .header-holder .nav li a {
      font-size: 14px; }
    .header .header-holder .lang {
      font-size: 14px; } }
  @media screen and (max-width: 980px) {
    .header .header-holder {
      padding: 20px; }
      .header .header-holder .burger {
        display: block; }
      .header .header-holder .select-cities-holder {
        display: block;
        margin: 0 auto;
        width: 246px; }
        .header .header-holder .select-cities-holder .list-cities .active-city {
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          vertical-align: top; }
          .header .header-holder .select-cities-holder .list-cities .active-city span {
            position: relative;
            display: inline;
            color: #fff;
            text-transform: uppercase; }
            .header .header-holder .select-cities-holder .list-cities .active-city span:before {
              content: 'Такси в';
              display: block;
              position: absolute;
              left: -70px;
              text-transform: uppercase;
              top: 0; }
            .header .header-holder .select-cities-holder .list-cities .active-city span:after {
              content: '';
              position: absolute;
              display: block;
              width: 10px;
              height: 10px;
              background: transparent;
              border: 5px solid transparent;
              border-top-color: #fff;
              right: -18px;
              top: 7px; }
          .header .header-holder .select-cities-holder .list-cities .active-city.active span:after {
            border-top-color: transparent;
            border-bottom-color: #fff;
            top: 1px; }
        .header .header-holder .select-cities-holder .list-cities .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools.mCS-minimal-dark {
          opacity: 1;
          filter: "alpha(opacity=100)";
          -ms-filter: "alpha(opacity=100)"; }
        .header .header-holder .select-cities-holder .list-cities ul {
          padding: 0;
          margin: 0;
          list-style: none;
          visibility: hidden;
          z-index: -1;
          opacity: 0;
          position: absolute !important;
          top: 70px;
          left: 0;
          width: 100%;
          background: #fff;
          height: 350px;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
          transition: all 0.2s ease-in-out 0s; }
          .header .header-holder .select-cities-holder .list-cities ul li {
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .header .header-holder .select-cities-holder .list-cities ul li a {
              color: #000;
              font-size: 18px;
              position: relative;
              padding-left: 16px;
              font-weight: 500;
              margin-left: -13px; }
              .header .header-holder .select-cities-holder .list-cities ul li a.active {
                color: #40c4ff; }
                .header .header-holder .select-cities-holder .list-cities ul li a.active:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 1px;
                  height: 16px;
                  width: 10px;
                  background: url(/dependencies/images/icon41.png) no-repeat; }
          .header .header-holder .select-cities-holder .list-cities ul.cities-list-active {
            opacity: 1;
            z-index: 100;
            visibility: visible; }
      .header .header-holder .title-page {
        display: block;
        width: auto;
        margin: 0 auto;
        text-align: center; }
        .header .header-holder .title-page span {
          text-align: center;
          text-transform: uppercase;
          font-size: 20px;
          color: #fff;
          line-height: 22px;
          padding-right: 15px; }
      .header .header-holder .frame {
        display: none; }
      .header .header-holder .nav {
        display: none; }
      .header .header-holder .lang {
        display: none; }
      .header .header-holder .search-holder {
        display: block; } }
  @media screen and (max-width: 736px) and (min-width: 320px) {
    .header .header-holder .mobile-header {
      overflow-y: scroll;
      height: 100vh;
      padding: 20px 20px 150px 20px; }
      .header .header-holder .mobile-header .mobile-nav li a:hover {
        background: transparent;
        color: #fff;
        border-color: #000; } }
  @media screen and (max-width: 480px) {
    .header .header-holder .mobile-header .mobile-logo {
      margin-bottom: 20px; }
    .header .header-holder .mobile-header .mobile-nav {
      margin-bottom: 15px; }
      .header .header-holder .mobile-header .mobile-nav li a {
        padding: 15px 15px 15px 10px;
        font-size: 14px; }
    .header .header-holder .select-cities-holder {
      padding-left: 20px; }
      .header .header-holder .select-cities-holder .list-cities .active-city {
        font-size: 12px; }
        .header .header-holder .select-cities-holder .list-cities .active-city span:before {
          left: -54px;
          text-transform: uppercase;
          top: 0;
          font-size: 12px; }
        .header .header-holder .select-cities-holder .list-cities .active-city span:after {
          width: 8px;
          height: 8px;
          border: 4px solid transparent;
          border-top-color: #fff;
          right: -15px;
          top: 5px; }
        .header .header-holder .select-cities-holder .list-cities .active-city span.active:before {
          top: 0; }
      .header .header-holder .select-cities-holder .list-cities ul li a {
        font-size: 16px; }
    .header .header-holder .title-page span {
      font-size: 14px; } }

.footer {
  width: 100%;
  background: #616161;
  overflow: hidden; }
  .footer .footer-holder {
    max-width: 1200px;
    margin: 0 auto;
    padding: 33px 0 24px 0;
    display: flex;
    justify-content: space-between; }
    .footer .footer-holder .column {
      width: 29%;
      height: 100%; }
      .footer .footer-holder .column h2 {
        font-size: 16px;
        font-weight: 500;
        padding: 0 30px 5px 10px;
        width: 130px;
        color: #fff;
        text-transform: uppercase;
        margin: 0 0 20px; }
      .footer .footer-holder .column .row {
        border-bottom: 1px dashed #717171;
        margin-bottom: 4px;
        padding-left: 10px;
        height: 53px;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .footer .footer-holder .column .row:last-child {
          border-bottom: none; }
        .footer .footer-holder .column .row .newsone {
          display: block;
          font-size: 12px;
          font-weight: 300;
          margin-bottom: 4px;
          line-height: 16px; }
          .footer .footer-holder .column .row .newsone:hover {
            text-decoration: underline; }
        .footer .footer-holder .column .row .data {
          display: block;
          font-size: 9px;
          font-weight: 400;
          line-height: 12px;
          padding-bottom: 5px;
          color: #9c9c9c; }
          .footer .footer-holder .column .row .data .time {
            padding-left: 10px; }
    .footer .footer-holder .reviews .row .newsone {
      color: #fff; }
      .footer .footer-holder .reviews .row .newsone:hover {
        text-decoration: none; }
    .footer .footer-holder .news h2 {
      border-bottom: 3px solid #80d8ff; }
    .footer .footer-holder .reviews h2 {
      border-bottom: 3px solid #ff8a80; }
    .footer .footer-holder .contacts h2 {
      border-bottom: 3px solid #ffd180; }
    .footer .footer-holder .contacts .title {
      font-size: 14px;
      line-height: 16px;
      color: #ffd180;
      font-weight: 400;
      display: inline-block;
      vertical-align: middle; }
    .footer .footer-holder .contacts .phones, .footer .footer-holder .contacts .email, .footer .footer-holder .contacts .soc-net {
      padding-left: 15px; }
    .footer .footer-holder .contacts .phones {
      margin-bottom: 18px; }
      .footer .footer-holder .contacts .phones .title {
        margin-bottom: 10px; }
      .footer .footer-holder .contacts .phones a {
        display: block;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        margin-bottom: 1px;
        color: #fff; }
        .footer .footer-holder .contacts .phones a span {
          padding-left: 20px;
          font-size: 14px;
          line-height: 16px;
          color: #9c9c9c; }
    .footer .footer-holder .contacts .email {
      margin-bottom: 18px; }
      .footer .footer-holder .contacts .email a {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px; }
    .footer .footer-holder .contacts .soc-net .title {
      margin-bottom: 10px;
      display: block; }
    .footer .footer-holder .contacts .soc-net .fb, .footer .footer-holder .contacts .soc-net .gp, .footer .footer-holder .contacts .soc-net .vk, .footer .footer-holder .contacts .soc-net .tw {
      display: inline-block;
      vertical-align: middle;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      text-align: center; }
      .footer .footer-holder .contacts .soc-net .fb:hover, .footer .footer-holder .contacts .soc-net .gp:hover, .footer .footer-holder .contacts .soc-net .vk:hover, .footer .footer-holder .contacts .soc-net .tw:hover {
        transform: scale(1.1); }
    .footer .footer-holder .contacts .soc-net .fb {
      background: #4e4e4e url(/dependencies/images/icon02.svg) no-repeat 50% 50%; }
    .footer .footer-holder .contacts .soc-net .gp {
      background: #4e4e4e url(/dependencies/images/icon03.svg) no-repeat 50% 50%; }
    .footer .footer-holder .contacts .soc-net .vk {
      background: #4e4e4e url(/dependencies/images/icon04.svg) no-repeat 50% 50%; }
    .footer .footer-holder .contacts .soc-net .tw {
      background: #4e4e4e url(/dependencies/images/icon044.svg) no-repeat 50% 50%; }
  .footer .add-nav {
    width: 100%;
    background: #212121; }
    .footer .add-nav .add-nav-holder {
      max-width: 1200px;
      margin: 0 auto;
      height: 100%;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .footer .add-nav .add-nav-holder .copyright {
        flex-grow: 1;
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px; }
      .footer .add-nav .add-nav-holder .apps {
        flex-grow: 1; }
        .footer .add-nav .add-nav-holder .apps:before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle; }
        .footer .add-nav .add-nav-holder .apps .title {
          color: #fff;
          font-size: 14px;
          font-weight: 300;
          line-height: 16px;
          display: inline-block;
          vertical-align: middle; }
        .footer .add-nav .add-nav-holder .apps .ios, .footer .add-nav .add-nav-holder .apps .android {
          display: inline-block;
          vertical-align: middle;
          width: 47px;
          height: 47px;
          border-radius: 8px;
          margin-left: 10px; }
          .footer .add-nav .add-nav-holder .apps .ios.ios, .footer .add-nav .add-nav-holder .apps .android.ios {
            background: url(/dependencies/images/appstore.svg) no-repeat 50% 50%; }
          .footer .add-nav .add-nav-holder .apps .ios.android, .footer .add-nav .add-nav-holder .apps .android.android {
            background: url(/dependencies/images/gp.svg) no-repeat 50% 50%; }
        .footer .add-nav .add-nav-holder .apps .ios-mobile, .footer .add-nav .add-nav-holder .apps .android-mobile {
          display: none;
          width: 300px;
          height: 60px;
          margin: 0 auto 15px auto;
          text-align: center;
          background: rgba(0, 0, 0, 0.9);
          border-radius: 13px;
          vertical-align: middle; }
          .footer .add-nav .add-nav-holder .apps .ios-mobile:hover, .footer .add-nav .add-nav-holder .apps .android-mobile:hover {
            background: rgba(0, 0, 0, 0.5); }
          .footer .add-nav .add-nav-holder .apps .ios-mobile:before, .footer .add-nav .add-nav-holder .apps .android-mobile:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: 100%; }
          .footer .add-nav .add-nav-holder .apps .ios-mobile .frame, .footer .add-nav .add-nav-holder .apps .android-mobile .frame {
            display: inline-block;
            vertical-align: middle; }
          .footer .add-nav .add-nav-holder .apps .ios-mobile .txt, .footer .add-nav .add-nav-holder .apps .android-mobile .txt {
            font-size: 14px;
            font-weight: 300;
            line-height: 16px;
            vertical-align: middle; }
            .footer .add-nav .add-nav-holder .apps .ios-mobile .txt .taxi, .footer .add-nav .add-nav-holder .apps .android-mobile .txt .taxi {
              color: #80d8ff; }
      .footer .add-nav .add-nav-holder .nav {
        flex-grow: 2; }
        .footer .add-nav .add-nav-holder .nav ul li {
          display: inline-block; }
          .footer .add-nav .add-nav-holder .nav ul li a {
            font-size: 14px;
            line-height: 16px;
            font-weight: 300; }
            .footer .add-nav .add-nav-holder .nav ul li a:hover {
              text-decoration: underline; }
        .footer .add-nav .add-nav-holder .nav ul li + li {
          margin-left: 4%; }
  @media screen and (max-width: 1200px) {
    .footer .footer-holder {
      padding: 30px 20px; } }
  @media screen and (max-width: 1140px) {
    .footer .add-nav .add-nav-holder .copyright {
      font-size: 13px; }
    .footer .add-nav .add-nav-holder .apps .title {
      font-size: 13px; }
    .footer .add-nav .add-nav-holder .apps a {
      width: 44px;
      height: 44px;
      margin-left: 6px; }
    .footer .add-nav .add-nav-holder .nav ul li a {
      font-size: 13px; }
    .footer .add-nav .add-nav-holder .nav ul li + li {
      margin-left: 1%; } }
  @media screen and (max-width: 980px) {
    .footer .footer-holder {
      display: none; }
    .footer .add-nav {
      background: rgba(0, 0, 0, 0.3); }
      .footer .add-nav .add-nav-holder {
        padding: 0; }
        .footer .add-nav .add-nav-holder .copyright {
          display: none; }
        .footer .add-nav .add-nav-holder .apps .title, .footer .add-nav .add-nav-holder .apps .ios, .footer .add-nav .add-nav-holder .apps .android {
          display: none; }
        .footer .add-nav .add-nav-holder .apps .ios-mobile, .footer .add-nav .add-nav-holder .apps .android-mobile {
          display: block; }
        .footer .add-nav .add-nav-holder .nav {
          display: none; } }

.taxi-city .search-holder {
  padding: 39px 0 40px 10px;
  display: flex;
  justify-content: space-between; }
  .taxi-city .search-holder .title-box {
    position: relative;
    z-index: 100; }
    .taxi-city .search-holder .title-box h1 {
      font-size: 28px;
      font-weight: 300;
      margin: 0;
      color: #000;
      display: inline-block;
      position: relative;
      z-index: 101; }
      .taxi-city .search-holder .title-box h1 a {
        display: inline-block;
        color: #40c4ff;
        font-weight: 400;
        border-bottom: 1px dashed #40c4ff;
        position: relative; }
        .taxi-city .search-holder .title-box h1 a:before {
          content: '';
          width: 10px;
          height: 10px;
          display: block;
          border: 5px solid transparent;
          border-top-color: #616161;
          position: absolute;
          right: -20px;
          top: 16px; }
    .taxi-city .search-holder .title-box.open-cities h1 a:before {
      top: 11px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid #616161; }
    .taxi-city .search-holder .title-box .dropdown-cities {
      position: absolute;
      left: -23px;
      top: -16px;
      width: 700px;
      height: auto;
      visibility: hidden;
      opacity: 0;
      z-index: -1;
      background: #fff;
      transition: 0.3s;
      padding: 100px 0 40px 42px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      border-radius: 5px; }
      .taxi-city .search-holder .title-box .dropdown-cities:before {
        content: '';
        position: absolute;
        top: 65px;
        left: 50%;
        transform: translateX(-50%);
        width: 93%;
        height: 1px;
        border-bottom: 1px dashed #e0e0e0; }
      .taxi-city .search-holder .title-box .dropdown-cities ul {
        columns: 3; }
        .taxi-city .search-holder .title-box .dropdown-cities ul li {
          padding: 8px 0;
          vertical-align: top;
          display: inline-block;
          width: 100%; }
          .taxi-city .search-holder .title-box .dropdown-cities ul li a {
            color: #000;
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            padding-left: 25px;
            position: relative; }
            .taxi-city .search-holder .title-box .dropdown-cities ul li a:before {
              content: '';
              position: absolute;
              display: block;
              width: 10px;
              height: 16px;
              left: 0;
              top: 1px; }
            .taxi-city .search-holder .title-box .dropdown-cities ul li a:hover, .taxi-city .search-holder .title-box .dropdown-cities ul li a.active {
              color: #40c4ff; }
              .taxi-city .search-holder .title-box .dropdown-cities ul li a:hover:before, .taxi-city .search-holder .title-box .dropdown-cities ul li a.active:before {
                background: url(/dependencies/images/icon41.png) no-repeat; }
      .taxi-city .search-holder .title-box .dropdown-cities.open {
        visibility: visible;
        opacity: 1;
        z-index: 100; }
  .taxi-city .search-holder .search-input {
    width: 35%;
    height: 40px;
    position: relative; }
    .taxi-city .search-holder .search-input .close-search {
      display: none;
      width: 25px;
      height: 25px;
      background: transparent;
      position: absolute;
      top: 7px;
      right: 15px;
      z-index: 150; }
      .taxi-city .search-holder .search-input .close-search:before, .taxi-city .search-holder .search-input .close-search:after {
        content: '';
        display: block;
        left: 12px;
        top: 8px;
        position: absolute;
        height: 10px;
        width: 2px;
        transform: rotate(-45deg);
        background: #000;
        z-index: 15; }
      .taxi-city .search-holder .search-input .close-search:after {
        transform: rotate(45deg); }
    .taxi-city .search-holder .search-input input[type="text"] {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 30px;
      padding: 0 35px 0 50px;
      color: #616161;
      font-size: 14px;
      background: #f0f0f0 url(/dependencies/images/icon07.svg) no-repeat 5% 50%;
      z-index: 10;
      position: relative; }

.taxi-city .choose-taxi {
  display: flex;
  margin-bottom: 35px;
  width: 100%; }
  .taxi-city .choose-taxi .column {
    width: 25%;
    padding-top: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .taxi-city .choose-taxi .column .title {
      display: block;
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 22px;
      font-weight: 400;
      color: #fff;
      padding: 0 2vh; }
    .taxi-city .choose-taxi .column .contacts .name {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #fff;
      font-weight: 400;
      margin-bottom: 5px;
      padding: 0 2vh; }
    .taxi-city .choose-taxi .column .contacts .phone, .taxi-city .choose-taxi .column .contacts .callback {
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      font-weight: 300;
      display: block;
      padding: 0 2vh; }
    .taxi-city .choose-taxi .column .contacts .callback {
      margin-bottom: 15px; }
    .taxi-city .choose-taxi .column .more-info {
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: #000;
      padding-left: 2vh;
      background: rgba(255, 255, 255, 0.5);
      position: relative;
      bottom: 0; }
      .taxi-city .choose-taxi .column .more-info:before {
        content: '';
        display: block;
        width: 8px;
        height: 10px;
        position: absolute;
        top: 20px;
        right: 20px;
        background: url(/dependencies/images/icon08.png) no-repeat 5% 50%; }
      .taxi-city .choose-taxi .column .more-info:hover {
        background: rgba(255, 255, 255, 0.8); }
    .taxi-city .choose-taxi .column:first-child {
      background: #ffab40; }
    .taxi-city .choose-taxi .column:nth-child(2) {
      background: #ff5252; }
    .taxi-city .choose-taxi .column:nth-child(3) {
      background: #40c4ff; }
    .taxi-city .choose-taxi .column:last-child {
      background: #889da8; }

.taxi-city .filter {
  margin-bottom: 24px;
  vertical-align: top;
  display: flex;
  padding-left: 10px;
  justify-content: space-between; }
  .taxi-city .filter .filter-icons {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px; }
    .taxi-city .filter .filter-icons .title {
      display: inline-block;
      font-size: 16px;
      color: #000;
      line-height: 18px;
      margin: 0 35px 13px 0;
      font-weight: 300;
      flex-grow: 1; }
    .taxi-city .filter .filter-icons .clear {
      display: inline-block;
      flex-grow: 1;
      font-size: 12px;
      line-height: 14px;
      color: #40c4ff;
      font-weight: 400;
      padding-left: 10px;
      position: relative; }
      .taxi-city .filter .filter-icons .clear:before, .taxi-city .filter .filter-icons .clear:after {
        display: block;
        content: '';
        height: 10px;
        width: 2px;
        background: #40c4ff;
        position: absolute;
        left: 0;
        top: 2px;
        transform: rotate(-45deg); }
      .taxi-city .filter .filter-icons .clear:after {
        transform: rotate(45deg); }
    .taxi-city .filter .filter-icons ul li {
      display: inline-block; }
      .taxi-city .filter .filter-icons ul li a {
        display: block;
        transition: all 0s ease-in-out 0s;
        width: 38px;
        height: 38px;
        position: relative;
        border-radius: 50%;
        background: #f0f0f0 url(/dependencies/images/sprite.png) 50% 50%; }
        .taxi-city .filter .filter-icons ul li a:hover, .taxi-city .filter .filter-icons ul li a.active {
          background: #80d8ff url(/dependencies/images/sprite.png); }
        .taxi-city .filter .filter-icons ul li a.evacuator {
          background-position: -2px -5px; }
          .taxi-city .filter .filter-icons ul li a.evacuator:hover, .taxi-city .filter .filter-icons ul li a.evacuator.active {
            background-position: -2px 42px; }
        .taxi-city .filter .filter-icons ul li a.truck {
          background-position: -40px -5px; }
          .taxi-city .filter .filter-icons ul li a.truck:hover, .taxi-city .filter .filter-icons ul li a.truck.active {
            background-position: -40px 43px; }
        .taxi-city .filter .filter-icons ul li a.car {
          background-position: -82px -5px; }
          .taxi-city .filter .filter-icons ul li a.car:hover, .taxi-city .filter .filter-icons ul li a.car.active {
            background-position: -83px 42px; }
        .taxi-city .filter .filter-icons ul li a.forhour {
          background-position: -127px -5px; }
          .taxi-city .filter .filter-icons ul li a.forhour:hover, .taxi-city .filter .filter-icons ul li a.forhour.active {
            background-position: -127px 43px; }
        .taxi-city .filter .filter-icons ul li a.transfer {
          background-position: -171px -6px; }
          .taxi-city .filter .filter-icons ul li a.transfer:hover, .taxi-city .filter .filter-icons ul li a.transfer.active {
            background-position: -171px 42px; }
        .taxi-city .filter .filter-icons ul li a.nanny {
          background-position: -215px -6px; }
          .taxi-city .filter .filter-icons ul li a.nanny:hover, .taxi-city .filter .filter-icons ul li a.nanny.active {
            background-position: -215px 41px; }
        .taxi-city .filter .filter-icons ul li a.no-smoke-car {
          background-position: -261px -4px; }
          .taxi-city .filter .filter-icons ul li a.no-smoke-car:hover, .taxi-city .filter .filter-icons ul li a.no-smoke-car.active {
            background-position: -261px 42px; }
        .taxi-city .filter .filter-icons ul li a.smoke-car {
          background-position: -308px -4px; }
          .taxi-city .filter .filter-icons ul li a.smoke-car:hover, .taxi-city .filter .filter-icons ul li a.smoke-car.active {
            background-position: -308px 45px; }
        .taxi-city .filter .filter-icons ul li a.cond {
          background-position: -356px -4px; }
          .taxi-city .filter .filter-icons ul li a.cond:hover, .taxi-city .filter .filter-icons ul li a.cond.active {
            background-position: -356px 43px; }
        .taxi-city .filter .filter-icons ul li a.zoo {
          background-position: -400px -4px; }
          .taxi-city .filter .filter-icons ul li a.zoo:hover, .taxi-city .filter .filter-icons ul li a.zoo.active {
            background-position: -401px -37px; }
        .taxi-city .filter .filter-icons ul li a.curier {
          background-position: -446px -4px; }
          .taxi-city .filter .filter-icons ul li a.curier:hover, .taxi-city .filter .filter-icons ul li a.curier.active {
            background-position: -446px 42px; }
        .taxi-city .filter .filter-icons ul li a.no-alco {
          background-position: -490px -4px; }
          .taxi-city .filter .filter-icons ul li a.no-alco:hover, .taxi-city .filter .filter-icons ul li a.no-alco.active {
            background-position: -490px 42px; }
        .taxi-city .filter .filter-icons ul li a.celebrate {
          background: #f0f0f0 url(/dependencies/images/icon09.png) no-repeat 50% 50%; }
          .taxi-city .filter .filter-icons ul li a.celebrate:hover, .taxi-city .filter .filter-icons ul li a.celebrate.active {
            background: #80d8ff url(/dependencies/images/icon10.png) no-repeat 50% 50%; }
        .taxi-city .filter .filter-icons ul li a.woman {
          background-position: -536px -2px; }
          .taxi-city .filter .filter-icons ul li a.woman:hover, .taxi-city .filter .filter-icons ul li a.woman.active {
            background-position: -536px -35px; }
        .taxi-city .filter .filter-icons ul li a.english-man {
          background-position: 40px -4px; }
          .taxi-city .filter .filter-icons ul li a.english-man:hover, .taxi-city .filter .filter-icons ul li a.english-man.active {
            background-position: 40px -38px; }
        .taxi-city .filter .filter-icons ul li a .service-title {
          opacity: 0;
          z-index: -1;
          visibility: hidden;
          position: absolute;
          top: 55px;
          left: 50%;
          transform: translateX(-50%);
          padding: 10px 20px;
          height: 40px;
          background: #212121;
          display: flex;
          color: #fff;
          font-size: 14px;
          line-height: 16px;
          justify-content: center;
          align-items: center;
          font-weight: 300;
          border-radius: 5px;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
          transition: opacity 0.3s ease-in-out 0s; }
          .taxi-city .filter .filter-icons ul li a .service-title:before {
            content: '';
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 20px;
            height: 20px;
            border: 10px solid transparent;
            border-bottom-color: #212121; }
          .taxi-city .filter .filter-icons ul li a .service-title > span {
            font-size: 14px;
            color: #80d8ff;
            padding-left: 5px;
            font-weight: 300; }
          .taxi-city .filter .filter-icons ul li a .service-title.ie-service-title {
            min-width: 260px; }
      .taxi-city .filter .filter-icons ul li a:hover {
        white-space: nowrap; }
        .taxi-city .filter .filter-icons ul li a:hover .service-title {
          opacity: 1;
          z-index: 150;
          visibility: visible; }
  .taxi-city .filter .service-holder {
    display: none; }
  .taxi-city .filter .sort {
    vertical-align: middle;
    display: inline-block;
    width: 25%; }
    .taxi-city .filter .sort .sort-title {
      display: block;
      margin-bottom: 13px;
      padding-left: 25px;
      font-size: 16px;
      color: #000;
      line-height: 18px;
      font-weight: 300;
      text-align: left; }
    .taxi-city .filter .sort .sort-list {
      position: relative; }
      .taxi-city .filter .sort .sort-list .active-list {
        display: block;
        height: 40px;
        padding-left: 20px;
        width: 100%;
        background: #f0f0f0;
        border-radius: 50px; }
        .taxi-city .filter .sort .sort-list .active-list:before {
          content: '';
          position: absolute;
          right: 24px;
          top: 17px;
          height: 10px;
          width: 10px;
          background: transparent;
          border: 5px solid transparent;
          border-top-color: #444; }
        .taxi-city .filter .sort .sort-list .active-list span {
          line-height: 40px;
          font-size: 14px;
          font-weight: 500;
          color: #444; }
      .taxi-city .filter .sort .sort-list ul {
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
        background: #fff;
        height: auto;
        border-radius: 20px;
        padding: 5px 0;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        transition: all 0.1s ease-in-out 0s; }
        .taxi-city .filter .sort .sort-list ul:before {
          content: '';
          position: absolute;
          right: 24px;
          top: 16px;
          height: 10px;
          width: 10px;
          background: transparent;
          border: 5px solid transparent;
          border-bottom-color: #444; }
        .taxi-city .filter .sort .sort-list ul li {
          padding-left: 20px; }
          .taxi-city .filter .sort .sort-list ul li a {
            color: #444;
            font-size: 14px;
            line-height: 40px;
            font-weight: 500; }
            .taxi-city .filter .sort .sort-list ul li a:hover, .taxi-city .filter .sort .sort-list ul li a.active {
              color: #40c4ff; }
        .taxi-city .filter .sort .sort-list ul.sort-list-active {
          visibility: visible;
          z-index: 50;
          opacity: 1; }

.taxi-city .list-taxi {
  width: 100%; }
  .taxi-city .list-taxi .titles-box {
    width: 100%;
    height: 49px;
    border-bottom: 1px solid #d6e6ed;
    background: #e6f7ff;
    padding-left: 10px;
    vertical-align: middle; }
    .taxi-city .list-taxi .titles-box:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%; }
    .taxi-city .list-taxi .titles-box .name, .taxi-city .list-taxi .titles-box .rate, .taxi-city .list-taxi .titles-box .services, .taxi-city .list-taxi .titles-box .rating {
      display: inline-block;
      vertical-align: middle;
      font-size: 13px;
      font-weight: 300;
      line-height: 16px;
      color: #607d8b; }
      .taxi-city .list-taxi .titles-box .name.name, .taxi-city .list-taxi .titles-box .rate.name, .taxi-city .list-taxi .titles-box .services.name, .taxi-city .list-taxi .titles-box .rating.name {
        width: 36%; }
      .taxi-city .list-taxi .titles-box .name.rate, .taxi-city .list-taxi .titles-box .rate.rate, .taxi-city .list-taxi .titles-box .services.rate, .taxi-city .list-taxi .titles-box .rating.rate {
        width: 20%; }
      .taxi-city .list-taxi .titles-box .name.services, .taxi-city .list-taxi .titles-box .rate.services, .taxi-city .list-taxi .titles-box .services.services, .taxi-city .list-taxi .titles-box .rating.services {
        width: 29%; }
      .taxi-city .list-taxi .titles-box .name.rating, .taxi-city .list-taxi .titles-box .rate.rating, .taxi-city .list-taxi .titles-box .services.rating, .taxi-city .list-taxi .titles-box .rating.rating {
        width: 8%; }
  .taxi-city .list-taxi .not-found {
    width: 100%;
    text-align: center;
    padding: 30px 0; }
    .taxi-city .list-taxi .not-found span {
      display: block;
      font-size: 16px;
      line-height: 18px;
      color: #000;
      margin-bottom: 10px; }
    .taxi-city .list-taxi .not-found span:nth-child(2) {
      margin-bottom: 30px; }
    .taxi-city .list-taxi .not-found .btn {
      display: inline-block;
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      background: #40c4ff;
      text-transform: uppercase;
      border-radius: 10px;
      margin: 0 auto;
      transition: all 0.3s ease-in-out 0s; }
      .taxi-city .list-taxi .not-found .btn:hover {
        opacity: 0.8; }
  .taxi-city .list-taxi .list {
    margin-bottom: 25px; }
    .taxi-city .list-taxi .list .row {
      padding: 16px 0 16px 0;
      border-bottom: 1px dashed #e0e0e0;
      vertical-align: middle; }
      .taxi-city .list-taxi .list .row:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle; }
      .taxi-city .list-taxi .list .row .name {
        display: inline-block;
        vertical-align: middle;
        width: 36%;
        padding-left: 8px;
        position: relative; }
        .taxi-city .list-taxi .list .row .name .title {
          display: block;
          font-size: 16px;
          line-height: 28px;
          font-weight: 500;
          color: #000; }
        .taxi-city .list-taxi .list .row .name .single-phone {
          display: inline-block;
          margin-right: 3px;
          color: #616161;
          font-weight: 300;
          font-size: 14px;
          line-height: 16px; }
        @media screen and (min-width: 980px) {
          .taxi-city .list-taxi .list .row .name .additional-mobile-phones {
            display: none; } }
        .taxi-city .list-taxi .list .row .name .more-phone {
          display: inline-block;
          font-weight: 700;
          color: #9c9c9c;
          vertical-align: middle;
          font-size: 24px;
          letter-spacing: -1.5px;
          line-height: 20px;
          position: relative;
          bottom: 2px;
          margin-right: 50%;
          cursor: pointer; }
        .taxi-city .list-taxi .list .row .name .dropdown-phones {
          background: #fff;
          transition: 0.3s;
          position: absolute;
          left: -50px;
          top: 35px;
          padding: 20px;
          width: 300px;
          height: auto;
          visibility: hidden;
          z-index: -1;
          opacity: 0;
          border-radius: 8px;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); }
          .taxi-city .list-taxi .list .row .name .dropdown-phones a {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 14px;
            line-height: 33px;
            color: #000;
            font-weight: 300; }
            .taxi-city .list-taxi .list .row .name .dropdown-phones a .usually {
              font-weight: 400;
              color: #616161;
              margin-right: 25px; }
            .taxi-city .list-taxi .list .row .name .dropdown-phones a .operator {
              width: 40%;
              font-weight: 400;
              color: #616161;
              font-size: 14px;
              margin-left: 25px;
              padding-left: 30px; }
              .taxi-city .list-taxi .list .row .name .dropdown-phones a .operator.kievstar {
                background: url(/dependencies/images/icon42.svg) no-repeat 0 50%; }
              .taxi-city .list-taxi .list .row .name .dropdown-phones a .operator.mts {
                background: url(/dependencies/images/icon43.svg) no-repeat 0 50%; }
              .taxi-city .list-taxi .list .row .name .dropdown-phones a .operator.life {
                background: url(/dependencies/images/icon44.svg) no-repeat 0 50%; }
              .taxi-city .list-taxi .list .row .name .dropdown-phones a .operator.city {
                background: url(/dependencies/images/icon45.svg) no-repeat 0 26%; }
          .taxi-city .list-taxi .list .row .name .dropdown-phones.open {
            visibility: visible;
            z-index: 100;
            opacity: 1; }
      .taxi-city .list-taxi .list .row .rate {
        display: inline-block;
        vertical-align: middle;
        width: 19.8%; }
        .taxi-city .list-taxi .list .row .rate .string {
          font-size: 14px;
          line-height: 24px;
          color: #616161;
          font-weight: 400; }
          .taxi-city .list-taxi .list .row .rate .string .value {
            font-size: 16px;
            font-weight: 500;
            color: #000; }
          .taxi-city .list-taxi .list .row .rate .string .need-mg {
            display: block; }
      .taxi-city .list-taxi .list .row .services {
        display: inline-block;
        vertical-align: middle;
        width: 26%; }
        .taxi-city .list-taxi .list .row .services ul {
          display: inline-block;
          margin-right: 3px;
          vertical-align: middle; }
          .taxi-city .list-taxi .list .row .services ul li {
            display: inline-block; }
            .taxi-city .list-taxi .list .row .services ul li span {
              display: block;
              transition: all 0s ease-in-out 0s;
              width: 28px;
              height: 28px;
              vertical-align: middle;
              border-radius: 50%;
              background: #f0f0f0 url(/dependencies/images/sprite-small.png) repeat-x 50% 50%; }
              .taxi-city .list-taxi .list .row .services ul li span.evacuator {
                background-position: -8px 4px; }
              .taxi-city .list-taxi .list .row .services ul li span.truck {
                background-position: -37px 4px; }
              .taxi-city .list-taxi .list .row .services ul li span.car {
                background-position: -70px 3px; }
              .taxi-city .list-taxi .list .row .services ul li span.forhour {
                background-position: -104px 3px; }
              .taxi-city .list-taxi .list .row .services ul li span.transfer {
                background-position: -138px 3px; }
              .taxi-city .list-taxi .list .row .services ul li span.nanny {
                background-position: -171px 3px; }
              .taxi-city .list-taxi .list .row .services ul li span.no-smoke-car {
                background-position: -205px 5px; }
              .taxi-city .list-taxi .list .row .services ul li span.smoke-car {
                background-position: -240px 4px; }
              .taxi-city .list-taxi .list .row .services ul li span.cond {
                background-position: -278px 4px; }
              .taxi-city .list-taxi .list .row .services ul li span.zoo {
                background-position: -312px 4px; }
              .taxi-city .list-taxi .list .row .services ul li span.curier {
                background-position: -347px 4px; }
              .taxi-city .list-taxi .list .row .services ul li span.no-alco {
                background-position: -871px 4px; }
              .taxi-city .list-taxi .list .row .services ul li span.celebrate {
                background: #f0f0f0 url(/dependencies/images/icon09.png) no-repeat 56% 45%;
                background-size: 60%; }
              .taxi-city .list-taxi .list .row .services ul li span.woman {
                background-position: 76px 5px; }
              .taxi-city .list-taxi .list .row .services ul li span.english-man {
                background-position: 37px 3px; }
        .taxi-city .list-taxi .list .row .services .more-icons {
          display: inline-block;
          font-weight: 700;
          color: #9c9c9c;
          vertical-align: middle;
          font-size: 24px;
          letter-spacing: -1.5px;
          cursor: pointer;
          position: relative; }
          .taxi-city .list-taxi .list .row .services .more-icons .dropdown-icons {
            position: absolute;
            transition: 0.3s;
            content: '';
            display: block;
            width: 186px;
            padding: 16px 8px 12px 26px;
            height: auto;
            top: 40px;
            left: -82px;
            background: #fff;
            visibility: hidden;
            opacity: 0;
            z-index: -1;
            border-radius: 8px;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); }
            .taxi-city .list-taxi .list .row .services .more-icons .dropdown-icons:before {
              content: '';
              position: absolute;
              top: -19px;
              left: 52%;
              transform: translate(-50%);
              background: transparent;
              width: 50px;
              height: 20px;
              background: url(/dependencies/images/icon46.png) no-repeat 10% 50%; }
            .taxi-city .list-taxi .list .row .services .more-icons .dropdown-icons.open {
              visibility: visible;
              opacity: 1;
              z-index: 100; }
      .taxi-city .list-taxi .list .row .rating {
        display: inline-block;
        width: 16%;
        padding-left: 22px;
        direction: ltr;
        text-align: left;
        vertical-align: middle; }
        .taxi-city .list-taxi .list .row .rating .star-input {
          display: none; }
        .taxi-city .list-taxi .list .row .rating .star-rating-wrapper {
          display: inline-block;
          direction: ltr; }
          .taxi-city .list-taxi .list .row .rating .star-rating-wrapper span {
            width: 12px !important;
            height: 12px !important;
            margin-right: -2px; }
        .taxi-city .list-taxi .list .row .rating .starlabel:before {
          display: inline-block;
          vertical-align: middle;
          content: '';
          width: 10px;
          height: 10px;
          background: url(/dependencies/images/star.svg) no-repeat; }
        .taxi-city .list-taxi .list .row .rating .starlabel:hover:before,
        .taxi-city .list-taxi .list .row .rating .starlabel:hover ~ .starlabel:before {
          background: url(/dependencies/images/star-fill.svg) no-repeat; }
        .taxi-city .list-taxi .list .row .rating .starlabel:hover:before,
        .taxi-city .list-taxi .list .row .rating .starlabel:hover ~ .starlabel:before,
        .taxi-city .list-taxi .list .row .rating .star-input:checked ~ .starlabel:before {
          background: url(/dependencies/images/star-fill.svg) no-repeat; }
        .taxi-city .list-taxi .list .row .rating .frame {
          display: inline-block;
          padding-left: 8px;
          direction: ltr;
          vertical-align: middle; }
          .taxi-city .list-taxi .list .row .rating .frame img {
            vertical-align: middle;
            width: 10px;
            height: 10px; }
          .taxi-city .list-taxi .list .row .rating .frame span {
            font-size: 10px;
            color: #9c9c9c; }
      .taxi-city .list-taxi .list .row .more-information {
        display: none; }

.taxi-city .txt-holder {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 30px;
  font-weight: 300;
  color: #000; }
  .taxi-city .txt-holder p {
    margin: 0; }
    .taxi-city .txt-holder p a {
      color: #40c4ff; }

@media screen and (max-width: 1200px) {
  .taxi-city .search-holder {
    padding: 4vh 10px 4vh 10px; }
  .taxi-city .list-taxi, .taxi-city .txt-holder {
    padding: 0 10px; }
  .taxi-city .filter {
    padding: 0 10px; } }

@media screen and (max-width: 980px) {
  .taxi-city {
    padding: 0; }
    .taxi-city .search-holder {
      display: none; }
    .taxi-city .choose-taxi {
      margin: 0;
      align-content: stretch;
      flex-wrap: wrap; }
      .taxi-city .choose-taxi .column {
        width: 50%; }
    .taxi-city .filter {
      padding: 0;
      margin: 0;
      flex-direction: column; }
      .taxi-city .filter .sort-title {
        display: none; }
      .taxi-city .filter .filter-icons {
        display: none; }
      .taxi-city .filter .service-holder {
        display: block;
        background: #f0f0f0;
        height: 50px;
        position: relative;
        border-bottom: 1px solid #9c9c9c;
        z-index: 10; }
        .taxi-city .filter .service-holder .filter-active {
          display: block;
          line-height: 50px;
          color: #444;
          font-size: 14px;
          padding-left: 18px;
          font-weight: 500; }
          .taxi-city .filter .service-holder .filter-active:before {
            content: '';
            position: absolute;
            top: 20px;
            right: 24px;
            height: 10px;
            width: 10px;
            background: transparent;
            border: 5px solid transparent;
            border-top-color: #000; }
        .taxi-city .filter .service-holder .change-list {
          background: #fff; }
          .taxi-city .filter .service-holder .change-list:before {
            top: 17px;
            border-top-color: transparent;
            border-bottom-color: #000; }
        .taxi-city .filter .service-holder .clear-filter {
          display: block;
          width: 20px;
          height: 20px;
          background: transparent;
          position: absolute;
          right: 50px;
          top: 14px;
          opacity: 0;
          z-index: -1; }
          .taxi-city .filter .service-holder .clear-filter:before, .taxi-city .filter .service-holder .clear-filter:after {
            content: '';
            display: block;
            height: 20px;
            width: 2px;
            top: 0;
            left: 0;
            background: #40c4ff;
            transform: translateX(9px) rotate(-45deg); }
          .taxi-city .filter .service-holder .clear-filter:after {
            transform: translate(9px, -20px) rotate(45deg); }
          .taxi-city .filter .service-holder .clear-filter.active {
            z-index: 20;
            opacity: 1; }
        .taxi-city .filter .service-holder .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools.mCS-minimal-dark {
          opacity: 1;
          filter: "alpha(opacity=100)";
          -ms-filter: "alpha(opacity=100)"; }
        .taxi-city .filter .service-holder ul {
          margin: 0;
          list-style: none;
          padding: 0 20px 0 5px;
          position: absolute !important;
          display: block;
          width: 100%;
          background: #fff;
          top: 49px;
          left: 0;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
          z-index: -1;
          opacity: 0;
          visibility: hidden;
          height: 260px;
          transition: all 0.1s ease-in-out 0s; }
          .taxi-city .filter .service-holder ul li:first-child {
            margin-top: 15px; }
          .taxi-city .filter .service-holder ul li:last-child {
            margin-bottom: 15px; }
          .taxi-city .filter .service-holder ul li a {
            padding-left: 10px;
            line-height: 30px;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            vertical-align: middle; }
            .taxi-city .filter .service-holder ul li a.active {
              color: #40c4ff; }
            .taxi-city .filter .service-holder ul li a:before {
              content: '';
              display: inline-block;
              width: 38px;
              height: 38px;
              border-radius: 50%;
              background: #f0f0f0 url(/dependencies/images/sprite.png) 50% 50%;
              vertical-align: middle;
              margin-right: 10px; }
            .taxi-city .filter .service-holder ul li a.active:before {
              background: #80d8ff url(/dependencies/images/sprite.png) 50% 50%; }
            .taxi-city .filter .service-holder ul li a.evacuator:before {
              background-position: -2px -5px; }
            .taxi-city .filter .service-holder ul li a.evacuator.active:before {
              background-position: -2px 42px; }
            .taxi-city .filter .service-holder ul li a.truck:before {
              background-position: -39px -5px; }
            .taxi-city .filter .service-holder ul li a.truck.active:before {
              background-position: -39px 43px; }
            .taxi-city .filter .service-holder ul li a.car:before {
              background-position: -82px -5px; }
            .taxi-city .filter .service-holder ul li a.car.active:before {
              background-position: -83px 42px; }
            .taxi-city .filter .service-holder ul li a.forhour:before {
              background-position: -127px -5px; }
            .taxi-city .filter .service-holder ul li a.forhour.active:before {
              background-position: -127px 43px; }
            .taxi-city .filter .service-holder ul li a.transfer:before {
              background-position: -171px -6px; }
            .taxi-city .filter .service-holder ul li a.transfer.active:before {
              background-position: -171px 42px; }
            .taxi-city .filter .service-holder ul li a.nanny:before {
              background-position: -215px -6px; }
            .taxi-city .filter .service-holder ul li a.nanny.active:before {
              background-position: -215px 41px; }
            .taxi-city .filter .service-holder ul li a.no-smoke-car:before {
              background-position: -261px -4px; }
            .taxi-city .filter .service-holder ul li a.no-smoke-car.active:before {
              background-position: -261px 42px; }
            .taxi-city .filter .service-holder ul li a.smoke-car:before {
              background-position: -308px -4px; }
            .taxi-city .filter .service-holder ul li a.smoke-car.active:before {
              background-position: -308px 45px; }
            .taxi-city .filter .service-holder ul li a.cond:before {
              background-position: -356px -4px; }
            .taxi-city .filter .service-holder ul li a.cond.active:before {
              background-position: -356px 43px; }
            .taxi-city .filter .service-holder ul li a.zoo:before {
              background-position: -400px -4px; }
            .taxi-city .filter .service-holder ul li a.zoo.active:before {
              background-position: -401px -37px; }
            .taxi-city .filter .service-holder ul li a.curier:before {
              background-position: -446px -4px; }
            .taxi-city .filter .service-holder ul li a.curier.active:before {
              background-position: -446px 42px; }
            .taxi-city .filter .service-holder ul li a.no-alco:before {
              background-position: -490px -4px; }
            .taxi-city .filter .service-holder ul li a.no-alco.active:before {
              background-position: -490px 42px; }
            .taxi-city .filter .service-holder ul li a.celebrate:before {
              background: #f0f0f0 url(/dependencies/images/icon09.png) no-repeat 9px 6px; }
            .taxi-city .filter .service-holder ul li a.celebrate.active:before {
              background: #80d8ff url(/dependencies/images/icon10.png) no-repeat 9px 7px; }
            .taxi-city .filter .service-holder ul li a.woman:before {
              background-position: -536px -2px; }
            .taxi-city .filter .service-holder ul li a.woman.active:before {
              background-position: -536px -36px; }
            .taxi-city .filter .service-holder ul li a.english-man:before {
              background-position: 40px -5px; }
            .taxi-city .filter .service-holder ul li a.english-man.active:before {
              background-position: 40px -38px; }
          .taxi-city .filter .service-holder ul li + li {
            margin-top: 10px; }
          .taxi-city .filter .service-holder ul.filter-list-active {
            z-index: 60;
            visibility: visible;
            opacity: 1; }
      .taxi-city .filter .sort {
        z-index: 5;
        display: block;
        width: 100%;
        height: 50px;
        background: #f0f0f0;
        border-bottom: 1px solid #9c9c9c;
        position: relative; }
        .taxi-city .filter .sort .sort-title {
          display: none; }
        .taxi-city .filter .sort .sort-list .active-list {
          line-height: 51px;
          height: 49px;
          padding-left: 103px;
          background: transparent;
          border-radius: 0; }
          .taxi-city .filter .sort .sort-list .active-list span {
            color: #000;
            line-height: 14px;
            font-size: 14px; }
          .taxi-city .filter .sort .sort-list .active-list:before {
            top: 22px;
            border-top-color: #000; }
          .taxi-city .filter .sort .sort-list .active-list:after {
            content: "Сортировка:";
            display: block;
            font-size: 14px;
            font-weight: 500;
            position: absolute;
            left: 19px;
            top: 1px;
            color: #444; }
        .taxi-city .filter .sort .sort-list ul {
          border-radius: 0 0 0 0;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
          top: 49px; }
          .taxi-city .filter .sort .sort-list ul:before {
            top: 17px;
            display: none; }
          .taxi-city .filter .sort .sort-list ul li a {
            display: block;
            line-height: 43px; }
        .taxi-city .filter .sort .sort-list.open-sort {
          background: #fff; }
          .taxi-city .filter .sort .sort-list.open-sort .active-list {
            background: #fff;
            border-radius: 0; }
            .taxi-city .filter .sort .sort-list.open-sort .active-list:before {
              top: 17px;
              border-top-color: #fff;
              border-bottom-color: #000; }
    .taxi-city .list-taxi {
      padding: 0; }
      .taxi-city .list-taxi .titles-box {
        display: none; }
      .taxi-city .list-taxi .list .row {
        padding: 8px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: wrap;
        position: relative; }
        .taxi-city .list-taxi .list .row .name {
          width: 50%;
          margin-bottom: 20px;
          padding-left: 0; }
          .taxi-city .list-taxi .list .row .name .single-phone {
            display: block;
            line-height: 22px; }
          .taxi-city .list-taxi .list .row .name .more-phone {
            display: none; }
          .taxi-city .list-taxi .list .row .name .title {
            line-height: 20px;
            margin-bottom: 3px; }
        .taxi-city .list-taxi .list .row .rate {
          width: 49%;
          text-align: right;
          padding-right: 0; }
          .taxi-city .list-taxi .list .row .rate .string {
            padding-top: 15px;
            display: block; }
        .taxi-city .list-taxi .list .row .services {
          width: 100%;
          height: 31px; }
          .taxi-city .list-taxi .list .row .services .more-icons {
            display: none; }
        .taxi-city .list-taxi .list .row .rating {
          width: 96px;
          padding: 0;
          position: absolute;
          top: 12px;
          right: 11px; }
          .taxi-city .list-taxi .list .row .rating .frame {
            display: none; }
          .taxi-city .list-taxi .list .row .rating .star-rating-wrapper span {
            height: 15px !important;
            width: 15px !important;
            margin: 0 !important; }
        .taxi-city .list-taxi .list .row .more-information {
          position: absolute;
          display: block;
          right: 17px;
          bottom: 16px;
          width: 20px;
          height: 20px; }
          .taxi-city .list-taxi .list .row .more-information:after, .taxi-city .list-taxi .list .row .more-information:before {
            content: '';
            position: absolute;
            height: 15px;
            width: 2px;
            background: #616161;
            right: 5px;
            bottom: -2px;
            transform: rotate(50deg); }
          .taxi-city .list-taxi .list .row .more-information:before {
            content: '';
            right: 5px;
            bottom: 7px;
            transform: rotate(-50deg); }
    .taxi-city .txt-holder {
      display: none; } }

@media screen and (max-width: 768px) {
  .taxi-city .choose-taxi .column .title {
    font-size: 16px;
    margin-bottom: 8px; }
  .taxi-city .choose-taxi .column .contacts .name {
    font-size: 11px;
    margin: 0; } }

@media screen and (max-width: 480px) {
  .taxi-city .filter .sort .sort-title-mobile {
    display: none; } }

.taxi-page .breadcrumbs {
  padding: 39px 0 40px 0; }
  .taxi-page .breadcrumbs a {
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    font-weight: 400;
    margin: 0 10px 0 10px;
    color: #616161; }
  .taxi-page .breadcrumbs .frame {
    display: inline-block;
    vertical-align: bottom; }
    .taxi-page .breadcrumbs .frame img {
      vertical-align: middle; }

.taxi-page .title-box {
  vertical-align: top;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }
  .taxi-page .title-box .back-link {
    display: none; }
  .taxi-page .title-box .frame-taxi {
    display: inline-block;
    width: 21.3%; }
  .taxi-page .title-box .rating {
    display: inline-block;
    width: 65%;
    direction: ltr;
    text-align: left;
    vertical-align: middle; }
    .taxi-page .title-box .rating .name {
      display: block;
      font-size: 28px;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 30px; }
    .taxi-page .title-box .rating .star-input {
      display: none; }
    .taxi-page .title-box .rating .star-rating-wrapper {
      display: inline-block;
      direction: ltr;
      margin-right: 15px; }
      .taxi-page .title-box .rating .star-rating-wrapper > span {
        width: 20px !important;
        height: 20px !important;
        margin-right: 7px; }
    .taxi-page .title-box .rating .starlabel:before {
      display: inline-block;
      vertical-align: middle;
      content: '';
      width: 20px;
      height: 20px;
      background: url(/dependencies/images/star.svg) no-repeat; }
    .taxi-page .title-box .rating .starlabel:hover:before,
    .taxi-page .title-box .rating .starlabel:hover ~ .starlabel:before {
      background: url(/dependencies/images/star-fill.svg) no-repeat; }
    .taxi-page .title-box .rating .starlabel:hover:before,
    .taxi-page .title-box .rating .starlabel:hover ~ .starlabel:before,
    .taxi-page .title-box .rating .star-input:checked ~ .starlabel:before {
      background: url(/dependencies/images/star-fill.svg) no-repeat; }
    .taxi-page .title-box .rating .frame {
      display: inline-block; }
      .taxi-page .title-box .rating .frame span {
        font-size: 14px;
        color: #9c9c9c;
        line-height: 14px;
        vertical-align: middle; }
      .taxi-page .title-box .rating .frame img {
        width: 20px;
        height: 16px;
        vertical-align: middle; }
  .taxi-page .title-box .soc-net {
    display: inline-block;
    width: 25%;
    text-align: right; }
    .taxi-page .title-box .soc-net span {
      display: block;
      font-weight: 300;
      margin-bottom: 26px;
      line-height: 0; }
    .taxi-page .title-box .soc-net .frame {
      display: inline-block; }

.taxi-page .phones .block {
  border-bottom: 1px dashed #e0e0e0;
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .taxi-page .phones .block h2 {
    display: inline-block;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    padding: 0 32px 5px 10px;
    border-bottom: 3px solid #607d8b; }
  .taxi-page .phones .block .info-date {
    font-size: 14px;
    font-weight: 300;
    color: #616161;
    display: inline-block;
    text-align: right; }
    .taxi-page .phones .block .info-date .data {
      color: #000;
      font-size: 16px; }

.taxi-page .phones .contact {
  padding: 45px 0; }
  .taxi-page .phones .contact .row {
    padding: 0 0 15px 0; }
    .taxi-page .phones .contact .row .wr-phone {
      width: 365px;
      display: flex;
      padding-left: 10px;
      justify-content: space-between; }
      .taxi-page .phones .contact .row .wr-phone .phones {
        display: inline-block;
        font-size: 16px;
        line-height: 18px;
        color: #000;
        margin-right: 65px;
        font-weight: 300; }
        .taxi-page .phones .contact .row .wr-phone .phones.city {
          margin-right: 100px; }
      .taxi-page .phones .contact .row .wr-phone .frame {
        width: 40%;
        display: inline-block; }
        .taxi-page .phones .contact .row .wr-phone .frame .operator {
          color: #9c9c9c;
          font-size: 15px;
          line-height: 18px;
          font-weight: 400;
          padding-left: 36px;
          vertical-align: bottom;
          background: url(/dependencies/images/icon42.svg) no-repeat 0 50%; }
          .taxi-page .phones .contact .row .wr-phone .frame .operator.kievstar {
            background: url(/dependencies/images/icon42.svg) no-repeat 0 50%; }
          .taxi-page .phones .contact .row .wr-phone .frame .operator.life {
            background: url(/dependencies/images/icon44.svg) no-repeat 0 50%; }
          .taxi-page .phones .contact .row .wr-phone .frame .operator.city {
            background: url(/dependencies/images/icon45.svg) no-repeat 0 50%; }
          .taxi-page .phones .contact .row .wr-phone .frame .operator.mts {
            background: url(/dependencies/images/icon43.svg) no-repeat 0 50%; }

.taxi-page .rate .block {
  border-bottom: 1px dashed #e0e0e0;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between; }
  .taxi-page .rate .block h2 {
    display: inline-block;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    padding: 0 50px 5px 10px;
    border-bottom: 3px solid #607d8b; }

.taxi-page .rate .time {
  height: 49px;
  line-height: 46px;
  width: 100%;
  background: #e6f7ff;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 35px; }
  .taxi-page .rate .time .title {
    width: 45%;
    display: inline-block; }
  .taxi-page .rate .time .day, .taxi-page .rate .time .night {
    width: 24%;
    display: inline-block;
    vertical-align: middle; }
    .taxi-page .rate .time .day span, .taxi-page .rate .time .night span {
      color: #607d8b;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400; }

.taxi-page .rate .row {
  margin-bottom: 15px;
  padding: 20px 0 10px 0; }
  .taxi-page .rate .row .title {
    position: relative;
    margin-bottom: 20px; }
    .taxi-page .rate .row .title:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: transparent;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10; }
    .taxi-page .rate .row .title .frame {
      vertical-align: middle;
      background: #fff;
      width: 16%;
      z-index: 15;
      left: 10px;
      position: relative; }
      .taxi-page .rate .row .title .frame img {
        vertical-align: middle; }
      .taxi-page .rate .row .title .frame span {
        vertical-align: middle;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #ff8a80;
        margin-left: 5px; }
  .taxi-page .rate .row .descr {
    margin-bottom: 25px; }
    .taxi-page .rate .row .descr .info-row {
      margin-bottom: 15px;
      display: flex;
      padding-left: 10px;
      justify-content: space-between; }
      .taxi-page .rate .row .descr .info-row .name {
        display: inline-block;
        width: 49%; }
        .taxi-page .rate .row .descr .info-row .name span {
          display: block;
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          color: #000; }
      .taxi-page .rate .row .descr .info-row .day {
        display: flex;
        justify-content: center;
        width: 49%; }
        .taxi-page .rate .row .descr .info-row .day span {
          display: flex;
          justify-content: space-between;
          width: 70px;
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          color: #000; }
          .taxi-page .rate .row .descr .info-row .day span b {
            text-align: right;
            display: block;
            width: 40px; }
      .taxi-page .rate .row .descr .info-row .night {
        display: inline-block;
        width: 24%; }
        .taxi-page .rate .row .descr .info-row .night span {
          display: flex;
          justify-content: space-between;
          width: 70px;
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          color: #000; }
          .taxi-page .rate .row .descr .info-row .night span b {
            text-align: right;
            display: block;
            width: 40px; }
  .taxi-page .rate .row.standart > .title > .frame > span {
    color: #40c4ff; }

.taxi-page .services .block {
  border-bottom: 1px dashed #e0e0e0;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .taxi-page .services .block h2 {
    display: inline-block;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    padding: 0 50px 5px 10px;
    border-bottom: 3px solid #607d8b; }

.taxi-page .services .service-icons {
  padding: 25px 9px; }
  .taxi-page .services .service-icons ul {
    columns: 4; }
    .taxi-page .services .service-icons ul li {
      display: inline-block;
      margin: 0 0 10px 0;
      width: 100%; }
      .taxi-page .services .service-icons ul li .icon {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .taxi-page .services .service-icons ul li .icon .frame {
          display: block;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          vertical-align: middle;
          text-align: center;
          margin-right: 8px;
          background: #f0f0f0 url(/dependencies/images/sprite.png) 50% 50%; }
          .taxi-page .services .service-icons ul li .icon .frame.evacuator {
            background-position: -2px -5px; }
          .taxi-page .services .service-icons ul li .icon .frame.truck {
            background-position: -40px -5px; }
          .taxi-page .services .service-icons ul li .icon .frame.car {
            background-position: -82px -5px; }
          .taxi-page .services .service-icons ul li .icon .frame.forhour {
            background-position: -128px -5px; }
          .taxi-page .services .service-icons ul li .icon .frame.transfer {
            background-position: -171px -6px; }
          .taxi-page .services .service-icons ul li .icon .frame.nanny {
            background-position: -215px -6px; }
          .taxi-page .services .service-icons ul li .icon .frame.no-smoke-car {
            background-position: -261px -4px; }
          .taxi-page .services .service-icons ul li .icon .frame.smoke-car {
            background-position: -308px -4px; }
          .taxi-page .services .service-icons ul li .icon .frame.cond {
            background-position: -356px -4px; }
          .taxi-page .services .service-icons ul li .icon .frame.zoo {
            background-position: -400px -4px; }
          .taxi-page .services .service-icons ul li .icon .frame.curier {
            background-position: -446px -4px; }
          .taxi-page .services .service-icons ul li .icon .frame.no-alco {
            background-position: -489px -4px; }
          .taxi-page .services .service-icons ul li .icon .frame.celebrate {
            background: #f0f0f0 url(/dependencies/images/icon09.png) no-repeat 50% 50%; }
          .taxi-page .services .service-icons ul li .icon .frame.woman {
            background-position: -536px -2px; }
          .taxi-page .services .service-icons ul li .icon .frame.english-man {
            background-position: 40px -4px; }
        .taxi-page .services .service-icons ul li .icon .name {
          display: block;
          flex: 1;
          font-size: 14px;
          line-height: 14px;
          color: #000;
          font-weight: 400;
          vertical-align: middle; }

.taxi-page .reviews {
  padding-bottom: 30px; }
  .taxi-page .reviews .block {
    border-bottom: 1px dashed #e0e0e0;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 36px; }
    .taxi-page .reviews .block h2 {
      display: inline-block;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0;
      padding: 0 50px 5px 10px;
      border-bottom: 3px solid #607d8b; }
  .taxi-page .reviews .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-left: 10px; }
    .taxi-page .reviews .title span:first-child {
      font-size: 16px;
      line-height: 18px;
      color: #000;
      font-weight: 400; }
    .taxi-page .reviews .title span:last-child {
      font-size: 14px;
      line-height: 14px;
      padding-right: 10px;
      color: #9c9c9c; }
      .taxi-page .reviews .title span:last-child b {
        color: #000;
        font-size: 16px; }
  .taxi-page .reviews textarea {
    width: 100%;
    background: #f0f0f0;
    border: none;
    border-radius: 20px;
    height: 120px;
    max-height: 215px;
    padding: 15px;
    max-width: 100%;
    margin-bottom: 20px; }
  .taxi-page .reviews .soc-net {
    text-align: center;
    margin-bottom: 20px; }
    .taxi-page .reviews .soc-net span {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400; }
    .taxi-page .reviews .soc-net ul li {
      display: inline-block; }
  .taxi-page .reviews .rating-com {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
    padding-left: 10px; }
    .taxi-page .reviews .rating-com .star-rating-wrapper {
      display: inline-block;
      direction: rtl; }
      .taxi-page .reviews .rating-com .star-rating-wrapper .star-input {
        display: none; }
      .taxi-page .reviews .rating-com .star-rating-wrapper .starlabel {
        cursor: pointer; }
      .taxi-page .reviews .rating-com .star-rating-wrapper .starlabel:before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 20px;
        height: 20px;
        background: url(/dependencies/images/star.svg) no-repeat; }
      .taxi-page .reviews .rating-com .star-rating-wrapper .starlabel:hover:before,
      .taxi-page .reviews .rating-com .star-rating-wrapper .starlabel:hover ~ .starlabel:before,
      .taxi-page .reviews .rating-com .star-rating-wrapper .star-input:checked ~ .starlabel:before {
        background: url(/dependencies/images/star-fill.svg) no-repeat; }
  .taxi-page .reviews .comments {
    display: block;
    width: 100%; }
    .taxi-page .reviews .comments > div:first-child > .row > .comment {
      border-top: 1px dashed #c9c9c9; }
      @media screen and (max-width: 980px) {
        .taxi-page .reviews .comments > div:first-child > .row > .comment {
          border-top: none; } }
    .taxi-page .reviews .comments .row {
      display: flex;
      justify-content: space-between; }
      .taxi-page .reviews .comments .row .frame {
        display: inline-block;
        width: 40px;
        height: 40px;
        vertical-align: top;
        margin-top: 10px; }
      .taxi-page .reviews .comments .row .comment {
        display: inline-block;
        border-bottom: 1px dashed #c9c9c9;
        width: 94%;
        padding: 17px 22px 25px 1px; }
        .taxi-page .reviews .comments .row .comment .top {
          display: flex;
          justify-content: space-between; }
          .taxi-page .reviews .comments .row .comment .top .name {
            display: inline-block;
            font-size: 16px;
            line-height: 18px;
            color: #000;
            margin-bottom: 10px;
            font-weight: 700; }
          .taxi-page .reviews .comments .row .comment .top .date {
            display: inline-block;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 10px;
            color: #616161;
            margin-right: 10px; }
          .taxi-page .reviews .comments .row .comment .top .rate {
            text-align: right; }
            .taxi-page .reviews .comments .row .comment .top .rate .rating {
              display: inline-block;
              width: 68px;
              margin-bottom: 3px;
              direction: ltr;
              vertical-align: middle; }
              .taxi-page .reviews .comments .row .comment .top .rate .rating .star-input {
                display: none; }
              .taxi-page .reviews .comments .row .comment .top .rate .rating .star-rating-wrapper {
                display: inline-block;
                direction: ltr; }
                .taxi-page .reviews .comments .row .comment .top .rate .rating .star-rating-wrapper .star-rating-wrapper span {
                  height: 10px !important;
                  width: 10px !important;
                  margin-right: -1px; }
              .taxi-page .reviews .comments .row .comment .top .rate .rating .starlabel:before {
                display: inline-block;
                vertical-align: middle;
                content: '';
                width: 10px;
                height: 10px;
                background: url(/dependencies/images/star.svg) no-repeat; }
              .taxi-page .reviews .comments .row .comment .top .rate .rating .starlabel:hover:before,
              .taxi-page .reviews .comments .row .comment .top .rate .rating .starlabel:hover ~ .starlabel:before {
                background: url(/dependencies/images/star-fill.svg) no-repeat; }
              .taxi-page .reviews .comments .row .comment .top .rate .rating .starlabel:hover:before,
              .taxi-page .reviews .comments .row .comment .top .rate .rating .starlabel:hover ~ .starlabel:before,
              .taxi-page .reviews .comments .row .comment .top .rate .rating .star-input:checked ~ .starlabel:before {
                background: url(/dependencies/images/star-fill.svg) no-repeat; }
              .taxi-page .reviews .comments .row .comment .top .rate .rating .frame {
                display: inline-block;
                padding-left: 10px;
                direction: ltr;
                vertical-align: middle; }
                .taxi-page .reviews .comments .row .comment .top .rate .rating .frame img {
                  vertical-align: middle; }
                .taxi-page .reviews .comments .row .comment .top .rate .rating .frame span {
                  font-size: 10px;
                  color: #9c9c9c; }
        .taxi-page .reviews .comments .row .comment .txt-holder {
          font-size: 14px;
          line-height: 16px;
          color: #616161;
          font-weight: 300; }
          .taxi-page .reviews .comments .row .comment .txt-holder p {
            margin: 0; }

@media screen and (max-width: 1200px) {
  .taxi-page {
    padding: 0 10px; } }

@media screen and (max-width: 980px) {
  .taxi-page {
    padding: 0; }
    .taxi-page .breadcrumbs {
      display: none; }
    .taxi-page .title-box {
      padding-top: 30px;
      justify-content: flex-start; }
      .taxi-page .title-box .back-link {
        display: inline-block;
        width: 30px;
        height: 60px;
        margin-right: 40px;
        position: relative;
        transition: 0.3s; }
        .taxi-page .title-box .back-link:before, .taxi-page .title-box .back-link:after {
          content: '';
          display: block;
          position: absolute;
          width: 2px;
          height: 14px;
          top: 20px;
          left: 40px;
          transform: rotate(45deg);
          background: #616161;
          transition: 0.3s; }
        .taxi-page .title-box .back-link:after {
          transform: rotate(-45deg);
          top: 29px; }
      .taxi-page .title-box .frame-taxi {
        width: auto;
        margin-right: 30px; }
      .taxi-page .title-box .rating {
        width: auto; }
      .taxi-page .title-box .soc-net {
        display: none; }
    .taxi-page .phones .block h2 {
      border: none;
      padding-bottom: 0;
      color: #607d8b; }
    .taxi-page .phones .block .info-date {
      display: none; }
    .taxi-page .phones .contact .row .wr-phone {
      width: 350px; }
    .taxi-page .rate .block {
      margin: 0;
      border-bottom: 1px dashed #c9c9c9; }
      .taxi-page .rate .block h2 {
        border: none;
        padding-bottom: 0;
        margin: 0;
        color: #607d8b; }
    .taxi-page .rate .time {
      line-height: 16px;
      margin-bottom: 15px;
      display: flex;
      align-items: center; }
      .taxi-page .rate .time .day span, .taxi-page .rate .time .night span {
        font-size: 14px; }
        .taxi-page .rate .time .day span b, .taxi-page .rate .time .night span b {
          display: block; }
    .taxi-page .rate .row .title {
      margin-bottom: 15px; }
      .taxi-page .rate .row .title .frame {
        width: 150px;
        margin-bottom: 10px; }
    .taxi-page .rate .row .descr .info-row {
      display: flex;
      align-items: flex-start; }
    .taxi-page .services .block {
      padding-bottom: 0;
      margin: 0;
      border-bottom: 1px dashed #c9c9c9; }
      .taxi-page .services .block h2 {
        border: none;
        padding-bottom: 0;
        margin: 0;
        color: #607d8b; }
    .taxi-page .services .service-icons {
      padding: 20px 10px; }
      .taxi-page .services .service-icons ul {
        columns: 2; }
    .taxi-page .reviews {
      padding-bottom: 16px; }
      .taxi-page .reviews .block {
        margin: 0;
        border-bottom: 1px dashed #c9c9c9; }
        .taxi-page .reviews .block h2 {
          border: none;
          padding-bottom: 0;
          margin: 0;
          color: #607d8b; }
      .taxi-page .reviews .title {
        display: none; }
      .taxi-page .reviews textarea {
        display: none; }
      .taxi-page .reviews .soc-net {
        display: none; }
      .taxi-page .reviews .comments {
        margin-bottom: 15px; }
        .taxi-page .reviews .comments .row .comment {
          border-top: none;
          width: 95%;
          padding: 15px 0 15px 10px; }
          .taxi-page .reviews .comments .row .comment .top {
            display: block; }
            .taxi-page .reviews .comments .row .comment .top .name {
              width: 100%;
              display: block;
              margin-bottom: 0; }
            .taxi-page .reviews .comments .row .comment .top .rate {
              text-align: left; }
      .taxi-page .reviews .rating-com {
        display: none; }
    .taxi-page .btn-review {
      display: block;
      width: 300px;
      height: 60px;
      line-height: 60px;
      text-transform: uppercase;
      background: #40c4ff;
      text-align: center;
      color: #fff;
      margin: 0 auto;
      border-radius: 10px;
      transition: all 0.3s ease-in-out 0s; }
      .taxi-page .btn-review:hover {
        opacity: 0.8; }
    .taxi-page .list-pagination {
      display: none; } }

@media screen and (max-width: 480px) {
  .taxi-page .title-box .back-link {
    margin-right: 5px; }
    .taxi-page .title-box .back-link:before, .taxi-page .title-box .back-link:after {
      left: 15px; }
  .taxi-page .title-box .frame-taxi {
    width: 100px;
    margin-right: 10px; }
  .taxi-page .title-box .rating {
    margin-right: 0; }
    .taxi-page .title-box .rating .name {
      font-size: 18px;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 5px; }
    .taxi-page .title-box .rating .starlabel:before {
      width: 15px;
      height: 15px; }
    .taxi-page .title-box .rating .star-rating-wrapper {
      margin-right: 5px; }
      .taxi-page .title-box .rating .star-rating-wrapper > span {
        width: 18px !important;
        height: 18px !important;
        margin-right: 2px; }
        .taxi-page .title-box .rating .star-rating-wrapper > span:last-child {
          margin-right: 0; }
  .taxi-page .phones .block h2 {
    padding-left: 20px; }
  .taxi-page .phones .block .info-date {
    display: none; }
  .taxi-page .phones .contact {
    padding: 20px; }
    .taxi-page .phones .contact .row .wr-phone {
      width: 100%;
      padding-left: 0; }
      .taxi-page .phones .contact .row .wr-phone .phones {
        font-size: 14px;
        margin-right: 30px; }
        .taxi-page .phones .contact .row .wr-phone .phones.city {
          margin-right: 55px; }
      .taxi-page .phones .contact .row .wr-phone .frame span {
        font-size: 14px; }
    .taxi-page .phones .contact .row:last-child {
      padding-bottom: 0; }
  .taxi-page .rate .block h2 {
    padding-left: 20px; }
  .taxi-page .rate .time .title {
    width: 40%; }
  .taxi-page .rate .time .day {
    width: 30%;
    text-align: center; }
    .taxi-page .rate .time .day span {
      font-size: 12px; }
      .taxi-page .rate .time .day span b {
        font-size: 13px; }
  .taxi-page .rate .time .night {
    width: 30%;
    text-align: center; }
    .taxi-page .rate .time .night span {
      font-size: 12px; }
      .taxi-page .rate .time .night span b {
        font-size: 13px; }
  .taxi-page .rate .row {
    padding: 20px 20px;
    margin: 0; }
    .taxi-page .rate .row .title .frame {
      width: 135px;
      left: 0; }
    .taxi-page .rate .row .descr {
      margin: 0; }
      .taxi-page .rate .row .descr .info-row {
        padding-left: 0; }
        .taxi-page .rate .row .descr .info-row .name {
          width: 70%; }
          .taxi-page .rate .row .descr .info-row .name span {
            font-size: 14px; }
        .taxi-page .rate .row .descr .info-row .day {
          width: 30%; }
          .taxi-page .rate .row .descr .info-row .day span {
            font-size: 14px; }
            .taxi-page .rate .row .descr .info-row .day span b {
              width: 45px; }
        .taxi-page .rate .row .descr .info-row .night {
          width: 30%; }
          .taxi-page .rate .row .descr .info-row .night span {
            font-size: 14px; }
            .taxi-page .rate .row .descr .info-row .night span b {
              width: 45px; }
  .taxi-page .services .block h2 {
    padding-left: 20px; }
  .taxi-page .services .service-icons {
    padding: 20px 20px; }
    .taxi-page .services .service-icons ul {
      columns: 1; }
  .taxi-page .reviews .block h2 {
    padding-left: 20px; }
  .taxi-page .reviews .comments .row {
    border-bottom: 1px dashed #c9c9c9; }
    .taxi-page .reviews .comments .row .frame {
      margin-left: 20px; }
    .taxi-page .reviews .comments .row .comment {
      border: none;
      padding-right: 20px; }
      .taxi-page .reviews .comments .row .comment .top .name {
        margin-bottom: 3px; }
      .taxi-page .reviews .comments .row .comment .top .rate {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .taxi-page .reviews .comments .row .comment .top .rate .date {
          margin-bottom: 0; }
        .taxi-page .reviews .comments .row .comment .top .rate .rating {
          margin-bottom: 5px;
          width: 82px; }
          .taxi-page .reviews .comments .row .comment .top .rate .rating .star-rating-wrapper .star-rating-wrapper span {
            width: 12px !important;
            height: 12px !important;
            margin: 0; } }

.callback-page {
  width: 93%; }
  .callback-page h1 {
    padding-left: 20px;
    font-size: 28px;
    line-height: 30px;
    font-weight: 300;
    color: #000;
    margin: 0 0 40px 0;
    padding-top: 39px; }
  .callback-page .row {
    padding-left: 20px;
    margin-bottom: 20px; }
    .callback-page .row .frame {
      display: none; }
      .callback-page .row .frame img {
        vertical-align: middle; }
      .callback-page .row .frame span {
        font-size: 16px;
        color: #ff5252;
        line-height: 18px;
        font-weight: 300; }
    .callback-page .row input[type="text"] {
      width: 380px;
      background: #f0f0f0;
      height: 40px;
      margin: 0 20px 5px 0;
      font-size: 16px;
      color: #000;
      font-weight: 400;
      border: none;
      border-radius: 50px;
      padding: 0 15px; }
      .callback-page .row input[type="text"].invalid {
        border: 1px solid #ff8a80; }
      .callback-page .row input[type="text"].invalid + .frame {
        display: inline-block; }
    .callback-page .row label {
      display: block;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 18px;
      color: #000;
      font-weight: 400; }
      .callback-page .row label .need {
        font-size: 12px;
        color: #ff8a80;
        line-height: 14px;
        font-weight: 300; }
      .callback-page .row label .value {
        font-size: 16px;
        color: #ff8a80;
        line-height: 18px;
        font-weight: 500; }
    .callback-page .row .theme-list {
      display: block;
      width: 380px;
      position: relative; }
      .callback-page .row .theme-list .active-theme {
        display: block;
        background: #f0f0f0;
        border-radius: 50px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-weight: 400;
        padding: 0 15px;
        color: #000; }
        .callback-page .row .theme-list .active-theme:before {
          content: '';
          position: absolute;
          right: 24px;
          top: 18px;
          width: 10px;
          height: 10px;
          background: transparent;
          border: 5px solid transparent;
          border-top-color: #000; }
      .callback-page .row .theme-list ul {
        z-index: -1;
        visibility: visible;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        background: #fff;
        width: 100%;
        border-radius: 20px;
        padding: 8px 0;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); }
        .callback-page .row .theme-list ul:before {
          content: '';
          position: absolute;
          right: 24px;
          top: 12px;
          width: 10px;
          height: 10px;
          background: transparent;
          border: 5px solid transparent;
          border-bottom-color: #000; }
        .callback-page .row .theme-list ul li {
          display: block; }
          .callback-page .row .theme-list ul li a {
            display: block;
            color: #000;
            line-height: 40px;
            padding: 0 15px;
            font-size: 16px;
            font-weight: 400; }
            .callback-page .row .theme-list ul li a:hover, .callback-page .row .theme-list ul li a.active {
              color: #40c4ff; }
        .callback-page .row .theme-list ul.theme-list-active {
          z-index: 50;
          visibility: visible;
          opacity: 1; }
    .callback-page .row textarea {
      height: 180px;
      max-height: 280px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      width: 100%;
      background: #f0f0f0;
      border: none;
      padding: 15px;
      border-radius: 20px;
      resize: none; }
  .callback-page .send-btn {
    display: block;
    margin: 0 20px 30px 20px;
    transition: all 0.3s ease-in-out 0s;
    width: 160px;
    height: 40px;
    line-height: 41px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    background: #40c4ff;
    border-radius: 8px; }
    .callback-page .send-btn:hover {
      background: rgba(64, 196, 255, 0.8); }
  .callback-page .txt-holder {
    padding-left: 20px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 300;
    margin-bottom: 20px; }
    .callback-page .txt-holder p {
      margin: 0; }
  @media screen and (max-width: 1200px) {
    .callback-page {
      padding: 0 10px;
      width: 100%; } }
  @media screen and (max-width: 980px) {
    .callback-page .row {
      padding-left: 0; }
    .callback-page .send-btn {
      margin-left: 0; }
    .callback-page .txt-holder {
      padding: 0; } }
  @media screen and (max-width: 768px) {
    .callback-page h1 {
      padding: 30px 0 0 0;
      font-size: 22px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 480px) {
    .callback-page .row {
      padding-left: 0; }
      .callback-page .row input[type="text"],
      .callback-page .row .theme-list,
      .callback-page .row textarea {
        width: 100%; }
    .callback-page .send-btn {
      margin-left: 0; }
    .callback-page .txt-holder {
      padding-left: 0; } }

.review-page .content {
  padding: 0 10px; }
  .review-page .content .review {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    position: relative; }
    .review-page .content .review .back-link {
      display: inline-block;
      width: 30px;
      height: 60px;
      margin-right: 10px;
      position: relative;
      transition: 0.3s;
      top: -1px; }
      .review-page .content .review .back-link:before, .review-page .content .review .back-link:after {
        content: '';
        display: block;
        position: absolute;
        width: 2px;
        height: 14px;
        top: 20px;
        left: 15px;
        transform: rotate(45deg);
        background: #616161;
        transition: 0.3s; }
      .review-page .content .review .back-link:after {
        transform: rotate(-45deg);
        top: 29px; }
      .review-page .content .review .back-link:hover:before, .review-page .content .review .back-link:hover:after {
        left: 10px; }
  .review-page .content textarea {
    width: 100%;
    display: block;
    background: #f0f0f0;
    min-height: 200px;
    max-height: 300px;
    border: none;
    margin-bottom: 12px;
    border-radius: 25px;
    padding: 20px;
    font-size: 16px; }
  .review-page .content .rating-com {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px; }
    .review-page .content .rating-com .star-rating-wrapper {
      display: inline-block;
      direction: rtl; }
      .review-page .content .rating-com .star-rating-wrapper .star-input {
        display: none; }
      .review-page .content .rating-com .star-rating-wrapper .starlabel {
        cursor: pointer; }
      .review-page .content .rating-com .star-rating-wrapper .starlabel:before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 20px;
        height: 20px;
        background: url(/dependencies/images/star.svg) no-repeat; }
      .review-page .content .rating-com .star-rating-wrapper .starlabel:hover:before,
      .review-page .content .rating-com .star-rating-wrapper .starlabel:hover ~ .starlabel:before,
      .review-page .content .rating-com .star-rating-wrapper .star-input:checked ~ .starlabel:before {
        background: url(/dependencies/images/star-fill.svg) no-repeat; }
  .review-page .content .title {
    display: block;
    text-align: center;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 18px;
    color: #000;
    font-weight: 500; }
  .review-page .content .soc-net li {
    margin-bottom: 10px;
    vertical-align: middle; }
    .review-page .content .soc-net li a {
      display: block;
      width: 100%;
      height: 80px;
      vertical-align: middle;
      text-align: center;
      border-radius: 14px;
      background: #3b5998;
      transition: all 0.3s ease-in-out 0s; }
      .review-page .content .soc-net li a:hover {
        opacity: 0.8; }
      .review-page .content .soc-net li a:before {
        display: inline-block;
        content: '';
        height: 100%;
        vertical-align: middle; }
      .review-page .content .soc-net li a img {
        vertical-align: middle !important;
        width: 15px;
        height: 30px; }
      .review-page .content .soc-net li a span {
        font-size: 20px;
        color: #fff;
        font-weight: 400;
        line-height: 22px;
        text-transform: uppercase;
        vertical-align: middle;
        padding-left: 45px;
        position: relative; }
      .review-page .content .soc-net li a.fb span:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 31px;
        display: inline-block;
        height: 36px;
        background: url(/dependencies/images/icon62.svg) no-repeat 50% 71%;
        background-size: 50%;
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 4px; }
      .review-page .content .soc-net li a.gp span:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 30px;
        display: inline-block;
        height: 35px;
        background: url(/dependencies/images/icon63.svg) no-repeat 50% 71%;
        background-size: 100%;
        vertical-align: middle;
        margin-right: 10px; }
      .review-page .content .soc-net li a.vk span:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: inline-block;
        width: 30px;
        height: 19px;
        background: url(/dependencies/images/icon64.svg) no-repeat 50% 5%;
        background-size: 100%;
        vertical-align: middle;
        margin-right: 10px; }
      .review-page .content .soc-net li a.tw span:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 30px;
        height: 25px;
        background: url(/dependencies/images/icon67.svg) no-repeat 50% 5%;
        background-size: 100%;
        vertical-align: middle;
        margin-right: 10px; }
    .review-page .content .soc-net li:nth-child(2) a {
      background: #dd4b39; }
    .review-page .content .soc-net li:nth-child(3) a {
      background: #4f86c0; }

.about-page .top .frame {
  padding: 39px 0 45px 10px; }

.about-page .descr .block {
  border-bottom: 1px dashed #e0e0e0;
  padding-bottom: 5px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .about-page .descr .block h2 {
    display: inline-block;
    text-align: left;
    font-size: 28px;
    font-weight: 400;
    margin: 0;
    color: #607d8b;
    padding-left: 10px; }

.about-page .descr .title {
  padding-left: 10px;
  margin-bottom: 60px; }
  .about-page .descr .title > span {
    font-size: 16px;
    line-height: 18px;
    color: #000;
    font-weight: 400; }
  .about-page .descr .title ul {
    padding-left: 20px;
    list-style-image: url(/dependencies/images/icon57.png);
    vertical-align: middle; }
    .about-page .descr .title ul li {
      width: 100%;
      vertical-align: middle;
      font-size: 16px;
      line-height: 18px;
      font-weight: 300;
      margin-bottom: 10px; }
      .about-page .descr .title ul li:before {
        display: inline-block;
        content: '';
        vertical-align: middle;
        height: 100%; }

.about-page .cities {
  margin-bottom: 60px; }
  .about-page .cities .block {
    border-bottom: 1px dashed #e0e0e0;
    padding-bottom: 5px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .about-page .cities .block h2 {
      display: inline-block;
      text-align: left;
      font-size: 28px;
      font-weight: 400;
      margin: 0;
      color: #607d8b;
      padding-left: 10px; }
  .about-page .cities > span {
    display: block;
    font-size: 16px;
    line-height: 18px;
    color: #000;
    font-weight: 400;
    margin-bottom: 25px;
    padding-left: 10px; }
  .about-page .cities ul {
    vertical-align: middle;
    padding-left: 10px;
    columns: 4; }
    .about-page .cities ul li {
      width: 100%;
      vertical-align: middle;
      font-size: 16px;
      line-height: 18px;
      font-weight: 300;
      margin-bottom: 15px;
      display: inline-block; }
      .about-page .cities ul li:before {
        display: inline-block;
        content: '';
        vertical-align: middle;
        height: 100%; }

.about-page .add-option {
  margin-bottom: 60px; }
  .about-page .add-option .block {
    border-bottom: 1px dashed #e0e0e0;
    padding-bottom: 6px;
    margin-bottom: 23px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .about-page .add-option .block h2 {
      display: inline-block;
      text-align: left;
      font-size: 28px;
      font-weight: 400;
      margin: 0;
      color: #607d8b;
      padding-left: 10px; }
  .about-page .add-option > span {
    padding-left: 10px;
    display: block;
    font-size: 19px;
    line-height: 18px;
    color: #000;
    font-weight: 400;
    margin-bottom: 22px;
    letter-spacing: -0.3px; }
  .about-page .add-option ul {
    vertical-align: middle;
    padding-left: 10px; }
    .about-page .add-option ul li {
      width: 100%;
      vertical-align: top;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      padding-left: 67px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative; }
      .about-page .add-option ul li .frame {
        text-align: center;
        position: absolute;
        left: 0;
        top: 4px;
        width: 38px;
        margin-right: 15px;
        vertical-align: top;
        height: 38px;
        border-radius: 50%;
        background: #f2f2f2 url(/dependencies/images/sprite.png) repeat-x -3px -5px; }
        .about-page .add-option ul li .frame.evacuator {
          background-position: -2px -4px; }
        .about-page .add-option ul li .frame.truck {
          background-position: -39px -4px; }
        .about-page .add-option ul li .frame.car {
          background-position: -82px -4px; }
        .about-page .add-option ul li .frame.forhour {
          background-position: -127px -4px; }
        .about-page .add-option ul li .frame.transfer {
          background-position: -171px -5px; }
        .about-page .add-option ul li .frame.nanny {
          background-position: -215px -5px; }
        .about-page .add-option ul li .frame.no-smoke-car {
          background-position: 365px -5px; }
        .about-page .add-option ul li .frame.smoke-car {
          background-position: 320px -3px; }
        .about-page .add-option ul li .frame.cond {
          background-position: 271px -4px; }
        .about-page .add-option ul li .frame.zoo {
          background-position: 227px -3px; }
        .about-page .add-option ul li .frame.curier {
          background-position: 181px -4px; }
        .about-page .add-option ul li .frame.no-alco {
          background-position: 137px -4px; }
        .about-page .add-option ul li .frame.celebrate {
          background: #f2f2f2 url(/dependencies/images/icon09.png) no-repeat 56% 44%; }
        .about-page .add-option ul li .frame.woman {
          background-position: 91px -3px; }
        .about-page .add-option ul li .frame.english-man {
          background-position: 40px -4px; }
      .about-page .add-option ul li span {
        display: inline-block;
        text-align: left;
        width: 95%;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        padding: 12px 0 12px 0; }
        .about-page .add-option ul li span:before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle; }
        .about-page .add-option ul li span .name-serv {
          display: inline;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px; }
          .about-page .add-option ul li span .name-serv:before {
            display: none !important; }

.about-page .contact-us {
  margin-bottom: 60px; }
  .about-page .contact-us .block {
    border-bottom: 1px dashed #e0e0e0;
    padding-bottom: 5px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .about-page .contact-us .block h2 {
      display: inline-block;
      text-align: left;
      font-size: 28px;
      font-weight: 400;
      margin: 0;
      color: #607d8b;
      padding-left: 10px; }
  .about-page .contact-us > span {
    display: block;
    font-size: 16px;
    line-height: 18px;
    color: #000;
    font-weight: 300;
    margin-bottom: 25px;
    padding-left: 10px; }
    .about-page .contact-us > span a {
      color: #40c4ff;
      font-size: 16px;
      line-height: 18px; }
  .about-page .contact-us .contact {
    padding-left: 10px; }
    .about-page .contact-us .contact .title {
      display: block;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      margin-bottom: 5px; }
    .about-page .contact-us .contact .phones {
      margin-bottom: 15px; }
      .about-page .contact-us .contact .phones li {
        display: block; }
        .about-page .contact-us .contact .phones li .phone {
          display: inline-block;
          font-size: 16px;
          line-height: 20px;
          font-weight: 300;
          color: #000; }
        .about-page .contact-us .contact .phones li .operator {
          display: inline-block;
          font-size: 16px;
          line-height: 18px;
          font-weight: 300;
          color: #616161;
          margin-left: 15px; }
    .about-page .contact-us .contact .mail {
      display: block;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      margin-bottom: 15px; }
      .about-page .contact-us .contact .mail a {
        font-size: 16px;
        line-height: 18px;
        font-weight: 300;
        color: #000; }
    .about-page .contact-us .contact .soc-net .title {
      display: block;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      margin-bottom: 15px; }
    .about-page .contact-us .contact .soc-net ul li {
      display: inline-block; }

@media screen and (max-width: 1200px) {
  .about-page {
    padding: 0 10px; } }

@media screen and (max-width: 640px) {
  .about-page .add-option .block h2, .about-page .descr .block h2, .about-page .cities .block h2, .about-page .contact-us .block h2 {
    font-size: 22px; }
  .about-page .cities ul {
    columns: 2; }
  .about-page .add-option ul li {
    padding-left: 56px;
    margin-bottom: 17px; } }

@media screen and (max-width: 480px) {
  .about-page .cities ul {
    columns: 1; } }

.news-page {
  padding: 39px 10px; }
  .news-page h2 {
    font-size: 28px;
    line-height: 30px;
    font-weight: 300;
    margin: 0 0 40px 10px; }
  .news-page .article-big {
    padding-bottom: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #e0e0e0; }
    .news-page .article-big .frame {
      position: relative;
      vertical-align: middle;
      width: 320px;
      height: 320px;
      margin-left: 10px; }
      .news-page .article-big .frame .type {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 12px;
        color: #fff;
        width: 100%;
        height: 45px;
        font-weight: 300;
        padding-left: 20px;
        text-transform: uppercase;
        background: rgba(0, 0, 0, 0.5);
        line-height: 45px; }
      .news-page .article-big .frame .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        right: -15px;
        bottom: 35px;
        width: 85px;
        height: 85px;
        background: #ff5252;
        font-size: 11px;
        color: #fff;
        text-align: center;
        vertical-align: middle; }
        .news-page .article-big .frame .date:before {
          content: '';
          position: absolute;
          right: 5px;
          bottom: -10px;
          width: 20px;
          height: 20px;
          background: transparent;
          border: 10px solid transparent;
          border-right-color: #662121;
          transform: rotate(45deg); }
        .news-page .article-big .frame .date .value {
          font-size: 36px;
          line-height: 54px;
          color: #fff;
          font-weight: 500;
          text-transform: uppercase;
          vertical-align: middle; }
    .news-page .article-big .txt-holder {
      width: 57%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 8px; }
      .news-page .article-big .txt-holder .title {
        display: block;
        font-size: 20px;
        margin-bottom: 40px;
        line-height: 33px;
        text-transform: uppercase;
        color: #000;
        font-weight: 500; }
        .news-page .article-big .txt-holder .title:hover {
          text-decoration: underline; }
      .news-page .article-big .txt-holder .descr {
        display: block;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        color: #616161;
        margin-bottom: 20px; }
      .news-page .article-big .txt-holder .more-info {
        display: flex;
        justify-content: space-between; }
        .news-page .article-big .txt-holder .more-info .date {
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          color: #616161;
          padding-left: 25px;
          position: relative; }
          .news-page .article-big .txt-holder .more-info .date:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: -1px;
            width: 15px;
            height: 14px;
            background: url(/dependencies/images/icon58.svg) no-repeat 50% 50%; }
        .news-page .article-big .txt-holder .more-info .read {
          display: block;
          font-size: 16px;
          line-height: 18px;
          color: #40c4ff;
          font-weight: 400;
          text-transform: uppercase;
          position: relative;
          padding-right: 17px; }
          .news-page .article-big .txt-holder .more-info .read:hover {
            text-decoration: underline; }
          .news-page .article-big .txt-holder .more-info .read:before {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 2px;
            width: 7px;
            height: 11px;
            background: url(/dependencies/images/icon59.png) no-repeat 50% 50%; }
  .news-page .article-small {
    padding-bottom: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #e0e0e0; }
    .news-page .article-small .frame {
      position: relative;
      vertical-align: middle;
      width: 160px;
      height: 160px;
      margin-left: 10px; }
      .news-page .article-small .frame .type {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 12px;
        color: #fff;
        width: 100%;
        height: 40px;
        font-weight: 300;
        padding-left: 20px;
        text-transform: uppercase;
        background: rgba(0, 0, 0, 0.5);
        line-height: 40px; }
      .news-page .article-small .frame .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        right: -15px;
        bottom: 35px;
        width: 60px;
        height: 35px;
        background: #ff5252;
        color: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 35px; }
        .news-page .article-small .frame .date:before {
          content: '';
          position: absolute;
          right: 5px;
          bottom: -10px;
          width: 20px;
          height: 20px;
          background: transparent;
          border: 10px solid transparent;
          border-right-color: #662121;
          transform: rotate(45deg); }
    .news-page .article-small .txt-holder {
      width: 76%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 8px; }
      .news-page .article-small .txt-holder .title {
        display: block;
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 33px;
        text-transform: uppercase;
        color: #000;
        font-weight: 500; }
        .news-page .article-small .txt-holder .title:hover {
          text-decoration: underline; }
      .news-page .article-small .txt-holder .descr {
        display: block;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        color: #616161;
        margin-bottom: 20px; }
      .news-page .article-small .txt-holder .more-info {
        display: flex;
        justify-content: space-between; }
        .news-page .article-small .txt-holder .more-info .date {
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          color: #616161;
          padding-left: 25px;
          position: relative; }
          .news-page .article-small .txt-holder .more-info .date:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: -1px;
            width: 15px;
            height: 14px;
            background: url(/dependencies/images/icon58.svg) no-repeat 50% 50%; }
        .news-page .article-small .txt-holder .more-info .read {
          display: block;
          font-size: 16px;
          line-height: 18px;
          color: #40c4ff;
          font-weight: 400;
          text-transform: uppercase;
          position: relative;
          padding-right: 17px; }
          .news-page .article-small .txt-holder .more-info .read:hover {
            text-decoration: underline; }
          .news-page .article-small .txt-holder .more-info .read:before {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 3px;
            width: 7px;
            height: 11px;
            background: url(/dependencies/images/icon59.png) no-repeat 50% 50%; }
  @media screen and (max-width: 1200px) {
    .news-page {
      padding: 30px 20px; }
      .news-page .article-big .txt-holder {
        width: 63%; }
      .news-page .article-small .txt-holder {
        width: 79%; } }
  @media screen and (max-width: 980px) {
    .news-page h2 {
      margin-left: 0; }
    .news-page .article-big {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between; }
      .news-page .article-big .frame {
        display: block;
        margin-bottom: 30px;
        height: 320px;
        margin-left: 0; }
      .news-page .article-big .txt-holder {
        display: block;
        width: 100%;
        padding-right: 0; }
        .news-page .article-big .txt-holder .title {
          text-align: center;
          margin-bottom: 15px; }
    .news-page .article-small {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between; }
      .news-page .article-small .frame {
        display: block;
        margin-bottom: 30px;
        height: 160px;
        margin-left: 0; }
      .news-page .article-small .txt-holder {
        display: block;
        width: 100%;
        padding-right: 0; }
        .news-page .article-small .txt-holder .title {
          text-align: center;
          margin-bottom: 15px; }
    .news-page .list-pagination {
      display: none; } }
  @media screen and (max-width: 640px) {
    .news-page .article-big .frame {
      display: block;
      margin-bottom: 30px;
      width: 280px;
      height: 280px; }
    .news-page .article-big .txt-holder {
      display: block;
      width: 100%; }
    .news-page .article-small .frame {
      display: block;
      margin-bottom: 30px; }
    .news-page .article-small .txt-holder {
      display: block;
      width: 100%; } }
  @media screen and (max-width: 360px) {
    .news-page .article-big .frame {
      height: 300px; } }

.newsone-page {
  padding: 39px 10px; }
  .newsone-page .title {
    margin-bottom: 13px; }
    .newsone-page .title .name {
      width: 100%;
      display: block;
      font-size: 28px;
      color: #000;
      line-height: 39px;
      font-weight: 300;
      margin-bottom: 35px;
      text-transform: uppercase; }
    .newsone-page .title .date {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: #616161;
      padding-left: 25px;
      position: relative;
      margin-right: 60px; }
      .newsone-page .title .date:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 1px;
        width: 15px;
        height: 14px;
        background: url(/dependencies/images/icon58.svg) no-repeat 50% 50%; }
    .newsone-page .title .tags {
      font-size: 12px;
      line-height: 14px;
      color: #616161;
      text-transform: uppercase; }
      .newsone-page .title .tags .tag {
        color: #40c4ff; }
  .newsone-page .banner {
    margin-bottom: 40px; }
  .newsone-page .txt-holder {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 22px; }
    .newsone-page .txt-holder strong {
      font-weight: 400; }
  .newsone-page .source {
    display: block;
    margin-bottom: 20px; }
    .newsone-page .source span {
      font-size: 16px;
      line-height: 18px;
      color: #000;
      font-weight: 400; }
      .newsone-page .source span a {
        color: #40c4ff; }
  .newsone-page .soc-net {
    margin-bottom: 15px;
    margin-top: 15px; }
    .newsone-page .soc-net li {
      display: inline-block; }
  .newsone-page .news-all {
    text-align: right; }
    .newsone-page .news-all a {
      display: inline-block;
      font-size: 16px;
      line-height: 18px;
      color: #40c4ff;
      text-align: left;
      font-weight: 400;
      text-transform: uppercase;
      position: relative;
      padding-left: 25px; }
      .newsone-page .news-all a:hover {
        text-decoration: underline; }
      .newsone-page .news-all a:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 14px;
        background: url(/dependencies/images/icon59.png) no-repeat;
        transform: rotate(180deg); }
  @media screen and (max-width: 1200px) {
    .newsone-page {
      padding: 30px 20px; } }
  @media screen and (max-width: 480px) {
    .newsone-page .title .name {
      font-size: 18px;
      line-height: 24px; }
    .newsone-page .title .date {
      display: block;
      margin-bottom: 10px; }
    .newsone-page .title .tags {
      display: block; } }

.partners-page {
  width: 94%;
  padding: 39px 0; }
  .partners-page .title {
    font-size: 28px;
    line-height: 30px;
    color: #000;
    font-weight: 300;
    margin: 0 0 40px 0;
    padding-left: 10px; }
  .partners-page .promo {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px; }
    .partners-page .promo .frame span {
      display: block;
      font-size: 16px;
      width: 180px;
      text-align: center;
      color: #607d8b;
      margin: 30px auto;
      text-transform: uppercase;
      font-weight: 400; }
  .partners-page .txt-holder {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    font-weight: 300;
    margin-bottom: 50px;
    padding-left: 10px; }
    .partners-page .txt-holder p {
      margin: 0; }
  .partners-page .row {
    padding: 30px 0;
    width: 98%;
    border-bottom: 1px solid #e0e0e0; }
    .partners-page .row .title {
      display: block;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      margin-bottom: 45px;
      font-weight: 300;
      padding-left: 10px; }
    .partners-page .row .btn {
      display: block;
      width: 220px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      background: #40c4ff;
      border-radius: 8px;
      font-weight: 400;
      margin-left: 10px; }
      .partners-page .row .btn:hover {
        background: rgba(64, 196, 255, 0.8); }
  @media screen and (max-width: 1200px) {
    .partners-page {
      padding: 30px 20px;
      width: 100%; }
      .partners-page .promo {
        padding: 0; }
      .partners-page .row {
        width: 100%; } }
  @media screen and (max-width: 640px) {
    .partners-page .title {
      padding: 0;
      font-size: 22px;
      line-height: 24px;
      margin-bottom: 20px; }
    .partners-page .promo {
      flex-direction: column; }
      .partners-page .promo .frame {
        width: 100%; }
    .partners-page .txt-holder {
      margin-bottom: 20px; }
    .partners-page .row .btn {
      margin: 0 auto; } }

.page-404 .wrapper .main .content {
  width: 100%; }
  .page-404 .wrapper .main .content .error-page {
    width: 100%; }

.page-404 .wrapper .main .promotion {
  display: none; }

.error-page {
  width: 94%;
  padding: 39px 0;
  text-align: center; }
  .error-page .title {
    display: block;
    font-size: 235px;
    font-weight: 400;
    line-height: 236px;
    color: #000; }
  .error-page .descr {
    display: block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    color: #000;
    margin-bottom: 20px; }
    .error-page .descr > span {
      display: block; }
  .error-page .back-link {
    display: block; }
    .error-page .back-link > span {
      position: relative;
      font-size: 16px;
      line-height: 18px;
      color: #40c4ff;
      font-weight: 400;
      padding-left: 17px; }
      .error-page .back-link > span:before {
        content: '';
        display: block;
        position: absolute;
        transition: all 0.3s ease-in-out 0s;
        left: 0;
        top: 4px;
        width: 7px;
        transform: rotate(180deg);
        height: 11px;
        background: url(/dependencies/images/icon59.png) 50% 50% no-repeat; }
    .error-page .back-link:hover span:before {
      left: -4px; }
  @media screen and (max-width: 1200px) {
    .error-page {
      padding: 30px 20px;
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .error-page .title {
      font-size: 100px;
      line-height: 100px; } }
